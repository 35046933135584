<template>
    <v-card>
        <v-toolbar elevation="0">
            <v-toolbar-title>
                Ticket Resync Options
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="3">
                    <label>Date Constraints</label>
                    <v-text-field label="Start Date" outlined dense hide-details class="mb-2"></v-text-field>
                    <v-text-field label="End Date" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-radio-group class="mt-0" v-model="sourceOfData" label="Source of Tickets">
                        <v-radio label="Zoho" key="Zoho" value="zoho"></v-radio>
                        <v-radio label="Trevor" key="Trevor" value="trevor"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="3">
                    <label>
                        Found <TrevNumberTicker :number="12"></TrevNumberTicker> tickets to resync.
                    </label>
                    <br>
                    <v-btn color="primary" @click="runResync">
                        Run Resync
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider v-show="isRunning"></v-divider>
        <v-card-text v-show="isRunning">

        </v-card-text>
    </v-card>
</template>

<script>
import TrevNumberTicker from '../ui/trev-number-ticker.vue';

export default {
    components: { TrevNumberTicker },
    methods: {
        async runResync() {
            this.isRunning = true;

            if(this.sourceOfData === 'zoho'){
                await this.runZohoFirst();
            }else if(this.sourceOfData === 'trevor'){
                await this.runDBFirst();
            }
        },
        async runZohoFirst(){

        },
        async runDBFirst(){

        }
    },
    data() {
        return {
            isRunning: false,
            sourceOfData: ''
        }
    },
}
</script>

<style>

</style>