<template>
  <v-row>
    <v-col>
      <v-simple-table>
        <tbody>
          <tr v-for="link in links" :key="link.id">
            <td>
              {{ link.name }}
            </td>
            <td>
              <v-btn
                :href="
                  'https://eu.ninjarmm.com/#/customerDashboard/' +
                  link.ninjaId +
                  '/overview'
                "
                target="_blank"
              >
                <v-icon class="mr-2"> mdi-open-in-new </v-icon>
                Open In Ninja
              </v-btn>
              <v-btn
                color="red"
                class="ml-2"
                @click="showDeleteNinjaLink(link)"
              >
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Remove Link
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
    <v-col>
      <v-autocomplete
        placeholder="NinjaRMM"
        prepend-icon="mdi-database-search"
        v-model="chosenLink"
        return-object
        :loading="isLoading"
        :search-input.sync="search"
        :items="items"
        item-text="name"
        item-value="id"
      >
      </v-autocomplete>
      <v-btn @click="addNinjaLink">
        <v-icon class="mr-2"> mdi-save </v-icon>
        Save Ninja Company Link
      </v-btn>
      <!-- <v-btn color="info" class="ml-2" @click="showNinjaCreate = true">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create in Ninja
      </v-btn> -->
    </v-col>
    <v-dialog max-width="60vh" v-model="showNinjaDelete">
      <v-card>
        <v-card-title> Delete Ninja Link? </v-card-title>
        <v-card-text>
          Are you sure you want to remove the Ninja link to
          <span class="info--text"> {{ linkToDelete.name }} </span>?
        </v-card-text>
        <v-card-actions>
          <v-btn color="danger" @click="deleteNinjaLink">
            <v-icon class="mr-2"> mdi-trash </v-icon>
            Yes, Delete It
          </v-btn>
          <v-btn text @click="cancelDeleteNinjaLink"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import trevCoolTable from "../../../../../components/tables/trev-cool-table.vue";
export default {
  components: { trevCoolTable },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  watch: {
    search() {
      this.$courier.RMM.Organisations.getData(1, 5, this.search).then(
        (data) => {
          this.items = data;
        }
      );
    },
  },
  mounted() {
    this.getLinks();
  },
  methods: {
    async getLinks() {
      this.$courier.Companies.withId(this.companyId)
        .NinjaLinks.getData(1, 200, "")
        .then((data) => {
          this.links = data.payload;
        });

      this.$courier.RMM.Organisations.getData(1, 5, "").then((data) => {
        this.items = data;
      });
    },
    showDeleteNinjaLink(link) {
      this.linkToDelete = link;
      this.showNinjaDelete = true;
    },
    addNinjaLink() {
      this.$courier.Companies.withId(this.companyId)
        .NinjaLinks.add({
          ninjaId: this.chosenLink.id,
          name: this.chosenLink.name,
          parentCompanyId: this.companyId,
        })
        .then(() => {
          this.chosenLink = {};
          this.getLinks();
          this.$emit("updated-ninja");
        });
    },
    deleteNinjaLink() {
      this.$courier.NinjaLinks.removeById(this.linkToDelete.id).then(() => {
        this.cancelDeleteNinjaLink();
        this.getLinks();
        this.$emit("updated-ninja");
      });
    },
    cancelDeleteNinjaLink() {
      this.linkToDelete = {};
      this.showNinjaDelete = false;
    },
  },
  data() {
    return {
      showNinjaDelete: false,
      showNinjaCreate: false,
      chosenLink: {},
      linkToDelete: {},
      items: [],
      isLoading: false,
      search: "",
      links: [],
      headers: [
        {
          text: "Name",
          value: "NinjaName",
        },
      ],
    };
  },
};
</script>

<style>
</style>