<template>
  <v-row>
    <v-col>
      <v-simple-table>
        <tbody>
          <tr v-for="paxLink in paxLinks" :key="paxLink.id">
            <td>
              {{ paxLink.pax8Name }}
            </td>
            <td>
              <v-btn
                :href="
                  'https://app.pax8.com/#/companies/' +
                  paxLink.pax8Id +
                  '?activeTab=Details'
                "
                target="_blank"
              >
                <v-icon class="mr-2"> mdi-open-in-new </v-icon>
                Open In Pax8
              </v-btn>
              <v-btn
                color="red"
                class="ml-2"
                @click="showDeletePaxLink(paxLink)"
              >
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Remove Link
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog max-width="60vh" v-model="isShowDeletePaxLink">
        <v-card>
          <v-card-title> Delete Pax8 Link? </v-card-title>
          <v-card-text>
            Are you sure you want to remove the pax8 link to
            <span class="info--text"> {{ selectedPaxLink.pax8Name }} </span>?
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" @click="deletePaxLink">
              <v-icon class="mr-2"> mdi-trash </v-icon>
              Yes, Delete It
            </v-btn>
            <v-btn text @click="cancelDeletePaxLink"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col>
      <trev-pax-company-search
        v-model="foundPaxCompany"
      ></trev-pax-company-search>
      <v-btn @click="addPaxLink">
        <v-icon class="mr-2"> mdi-save </v-icon>
        Save Pax8 Company Link
      </v-btn>
      <v-btn color="info" class="ml-2" @click="showPaxCreate = true">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create in Pax8
      </v-btn>
    </v-col>
    <v-dialog v-model="showPaxCreate" max-width="800px">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2"> mdi-asterisk </v-icon>
          New Company In Pax8
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-alert v-if="newCompanyIsError" type="error">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h6 class="mt-2">Basic Details</h6>
              <v-text-field
                label="Name *"
                v-model="newPaxCompany.name"
              ></v-text-field>
              <v-text-field
                label="Phone *"
                v-model="newPaxCompany.phone"
              ></v-text-field>
              <v-combobox
                :items="domains"
                v-model="selectedWebsite"
                item-value="id"
                item-text="domain"
                label="Website"
              >
              </v-combobox>
            </v-col>
            <v-col>
              <h6 class="mt-2">Address Details</h6>
              <v-text-field
                label="Street *"
                v-model="newPaxCompany.address.street"
              ></v-text-field>
              <v-text-field
                label="City *"
                v-model="newPaxCompany.address.city"
              ></v-text-field>
              <v-text-field
                label="State or Province *"
                v-model="newPaxCompany.address.stateOrProvince"
              ></v-text-field>
              <v-text-field
                label="Post Code *"
                v-model="newPaxCompany.address.postalCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="info" @click="createCompanyInPax">
            <v-icon class="mr-2"> mdi-plus </v-icon>
            Create Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import trevPaxCompanySearch from "../../../../../components/forms/trev-pax-company-search.vue";

export default {
  components: { trevPaxCompanySearch },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.getCompanyPaxLinks();

    this.newPaxCompany.phone = this.company.phone;
    this.newPaxCompany.address.street = this.company.firstLine;
    this.newPaxCompany.address.city = this.company.town;
    this.newPaxCompany.address.country = "United Kingdom";
    this.newPaxCompany.address.stateOrProvince = this.company.county;
    this.newPaxCompany.address.postalCode = this.company.postCode;

    this.getDomains();
  },
  methods: {
    async getDomains() {
      this.$courier.Companies.withId(this.company.id)
        .Domains.getData(1, 100, "")
        .then((companyDomains) => {
          this.domains = companyDomains.payload;
        });
    },
    async createCompanyInPax() {
      if (this.selectedWebsite.domain) {
        this.newPaxCompany.website = this.selectedWebsite.domain;
      } else {
        this.newPaxCompany.website = this.selectedWebsite;
      }

      this.$courier.PaxExternal.add(this.newPaxCompany)
        .then((pCompany) => {
          this.foundPaxCompany = pCompany;
          this.addPaxLink();
        })
        .catch((error) => {
          this.newCompanyIsError = true;
          this.errorMessage = error.toString();
        });
    },
    async getCompanyPaxLinks() {
      return this.$courier.Companies.withId(this.company.id)
        .PaxLinks.getData(1, 100, "")
        .then((paxLinks) => {
          this.paxLinks = paxLinks.payload;
        });
    },
    addPaxLink() {
      this.$courier.Companies.withId(this.company.id)
        .PaxLinks.add({
          pax8Id: this.foundPaxCompany.id,
          pax8Name: this.foundPaxCompany.name,
          parentCompanyId: this.company.id,
        })
        .then(() => {
          this.foundPaxCompany = {};

          this.getCompanyPaxLinks();

          this.$emit("updated-pax");
        });
    },
    deletePaxLink() {
      this.$courier.PaxLinks.removeById(this.selectedPaxLink.id).then(() => {
        this.cancelDeletePaxLink();
        this.getCompanyPaxLinks();
        this.$emit("updated-pax");
      });
    },
    cancelDeletePaxLink() {
      this.selectedPaxLink = {};
      this.isShowDeletePaxLink = false;
    },
    showDeletePaxLink(pLink) {
      this.selectedPaxLink = pLink;
      this.isShowDeletePaxLink = true;
    },
  },
  data() {
    return {
      changePax: false,
      paxLinks: [],
      foundPaxCompany: {},
      isShowDeletePaxLink: false,
      domains: [],
      selectedPaxLink: {},
      showPaxCreate: false,
      newPaxCompany: {
        name: this.company.name,
        phone: "",
        website: "",
        address: {
          street: "",
          city: "",
          postalCode: "",
          country: "GB",
          stateOrProvince: "",
        },
        billOnBehalfOfEnabled: false,
        selfServiceAllowed: false,
        orderApprovalRequired: false,
      },
      newCompanyIsError: false,
      errorMessage: "",
      selectedWebsite: null,
    };
  },
};
</script>

<style>
</style>