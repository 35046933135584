<template>
  <div>
    <trev-simple-table
      title="Select a Product"
      :isLoading="isLoading"
      :headers="getHeaders"
      :totalRecordCount="totalCount"
      :items="allProducts"
      @searching="searchProducts"
      @pagination-changed="paginationChanged"
      @item-selected="selectedItem"
      itemKey="subId"
      :hasLoaded="!isLoading"
      :pageSize="10"
      :showSelect="selectMultiple"
      :singleSelect="!selectMultiple"
      :defaultSelectedRows="defaultSelectedProducts"
    >
      <template v-slot:item.type="{ item }">
        <v-chip v-if="item.type == 'custom'" color="info"> Custom </v-chip>
        <v-chip v-else-if="item.type == 'Existing'" color="primary">
          Existing
        </v-chip>
        <v-chip v-else color="success"> Global </v-chip>
      </template>
      <template v-slot:item.price="{ item }"> £{{ item.price }} </template>
      <template v-slot:item.skuNumber="{ item }">
        <v-chip label>
          {{ item.skuNumber }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" small @click="selectProduct(item)">
          <v-icon> mdi-plus </v-icon>
          Select
        </v-btn>
      </template>
    </trev-simple-table>
  </div>
</template>

<script>
import trevSimpleTable from "../tables/trev-simple-table.vue";
export default {
  props: {
    showExistingProducts: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: String,
      default: "",
    },
    selectMultiple: {
      type: Boolean,
      default: false,
    },
    defaultSelectedProducts: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    trevSimpleTable,
  },
  computed: {
    getHeaders() {
      if (this.selectMultiple) {
        return this.headers.filter((x) => x.value != "actions");
      } else {
        return this.headers;
      }
    },
    allProducts() {
      if (!this.isLoading) {
        return this.products.sort((a, b) => (a.type > b.type ? 1 : -1));
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    selectedItem(item) {
      if (this.selectedProducts.some((x) => x == item)) {
        this.selectedProducts = this.selectedProducts.filter((x) => x != item);
      } else {
        this.selectedProducts.push(item);
      }

      this.$emit("selected-product", this.selectedProducts);
    },
    selectProduct(selectedProduct) {
      this.$emit("selected-product", selectedProduct);
    },
    async getProducts() {
      this.products = [];
      this.isLoading = true;
      var loading1 = true;
      var loading2 = true;
      this.totalCount = 0;

      this.$courier.Companies.withId(this.companyId)
        .ProductSkeletons.getData(
          this.paginationObject.page,
          this.paginationObject.itemsPerPage,
          this.search
        )
        .then((x) => {
          this.totalCount = this.totalCount + x.totalCount;

          x.payload.forEach((item) => {
            item.type = "custom";
            item.subId = this.globalIdCount;
            this.products.push(item);

            this.globalIdCount++;
          });
          loading1 = false;
          if (!loading1 && !loading2) {
            this.isLoading = false;
          }
        });

      this.$courier.ProductSkeletons.getData(
        this.paginationObject.page,
        this.paginationObject.itemsPerPage,
        this.search
      ).then((x) => {
        this.totalCount = this.totalCount + x.totalCount;
        loading2 = false;
        x.payload.forEach((item) => {
          item.type = "global";
          item.subId = this.globalIdCount;
          this.products.push(item);

          this.globalIdCount++;
        });

        if (!loading1 && !loading2) {
          this.isLoading = false;
        }
      });            

      if(this.showExistingProducts)
      {
        this.$courier.Companies.withId(this.companyId).Products.getData(this.paginationObject.page, this.paginationObject.itemsPerPage, this.search).then(prods => {
        if(prods && prods.payload){
          prods.payload.forEach(prod => {
            prod.type = 'Existing'
            prod.subId = this.globalIdCount;
            this.globalIdCount++;
            this.products.push(prod);
            this.totalCount ++;
          });          
        }
      });
      }
      
    },
    searchProducts(searchVal) {
      this.search = searchVal;
      this.timesPaginationUpdated = 0;
      this.getProducts();
    },
    paginationChanged(pObject) {
      this.timesPaginationUpdated++;
      if (this.timesPaginationUpdated > 1) {
        this.paginationObject.page = pObject.page;
        this.timesPaginationUpdated = 0;
        if (!this.isLoading) {
          this.getProducts();
        }
      }
    },
  },
  data: () => ({
    ownedProducts: [],
    selectedProducts: [],
    timesPaginationUpdated: 0,
    globalIdCount: 0,
    search: "",
    isLoading: true,
    products: [],
    totalCount: 0,
    paginationObject: {
      itemsPerPage: 5,
      page: 1,
    },
    headers: [
      {
        text: "Type",
        value: "type",
      },
      {
        text: "SKU",
        value: "skuNumber",
      },
      {
        text: "Division",
        value: "division",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Price",
        value: "price",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>

<style></style>
