<template>
    <v-container fluid>
        <v-row v-if="companyId || isGlobal">
            <v-col class="pa-0">
                <trev-cool-table @loaded="loadedData" :headers="getHeaders" @add-clicked="addJob" ref="tbl"
                    :additionalQueryStrings="additionalQueryStrings" :showAddLinkAsButton="$store.getters.hasScope('Job.Modify') && !isGlobal"
                    :courier="jobCourier">
                    <template slot="title">
                        <span class="mr-6"><v-icon class="mr-2">mdi-rocket</v-icon>Jobs</span>
                        <template v-if="!hideStatus">
                            Status:
                        </template>
                        <span class="primary--text" v-if="!hideStatus">
                            {{ (selectedStatus ? selectedStatus : 'All') }}
                        </span>
                        <v-menu v-if="!hideStatus">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-on="on" v-bind="attrs">
                                    <v-icon>
                                        mdi-chevron-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="selectedStatus = ''">
                                    <v-list-item-title>
                                        All
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="selectedStatus = 'Open'">
                                    <v-list-item-title>
                                        Open
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="selectedStatus = 'In Progress'">
                                    <v-list-item-title>
                                        In Progress
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="selectedStatus = 'On Hold'">
                                    <v-list-item-title>
                                        On Hold
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="selectedStatus = 'Complete'">
                                    <v-list-item-title>
                                        Complete
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <template v-if="!hideInvoiceStatus">
                            Invoice Status:
                            <span class="primary--text">
                                {{ (invoiceStatusMap.find(x => x.key == selectedInvoiceStatus).text) }}
                            </span>
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-on="on" v-bind="attrs">
                                        <v-icon>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="selectedInvoiceStatus = '2'">
                                        <v-list-item-title>
                                            All
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="selectedInvoiceStatus = '1'">
                                        <v-list-item-title>
                                            Sent
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="selectedInvoiceStatus = '0'">
                                        <v-list-item-title>
                                            Not Sent
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </template>
                    <template v-slot:item.dateAdded="{item}">
                        <v-chip>
                            {{ new Date(item.dateAdded).toLocaleDateString() }}
                        </v-chip>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-chip :color="getStatusColour(item)">{{ item.status }}</v-chip>
                    </template>
                    <template v-slot:item.poNumber="{ item }">
                        <v-chip color="info" v-if="item.poNumber">
                            <v-icon class="mr-2">
                                mdi-pound
                            </v-icon>
                            {{ item.poNumber }}</v-chip>
                    </template>
                    <template v-slot:item.invoiceSent="{ item }">
                        <v-icon v-if="item.invoiceSent" class="success--text">
                            mdi-check-circle
                        </v-icon>
                        <v-icon v-else class="danger--text">
                            mdi-close-circle-outline
                        </v-icon>
                    </template>
                    <template v-slot:item.desc="{ item }">
                        <v-btn small icon @click="showDescription(item)">
                            <v-icon>mdi-message-text-outline</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.contactId="{ item }">
                        <v-btn small v-if="item.contactId" :to="{
                            name: 'Contact View',
                            params: {
                                contactId: item.contactId
                            }
                        }">
                            <v-icon class="mr-2">
                                mdi-account-star-outline
                            </v-icon>
                            {{ getContactName(item.contactId) }}
                        </v-btn>
                    </template>
                    <template v-slot:item.coffeeCupUserId="{ item }">
                        <v-btn small v-if="item.coffeeCupUserId && getUser(item.coffeeCupUserId)">
                            <v-icon class="mr-2">
                                mdi-account-circle
                            </v-icon>
                            {{
                                getUserName(item.coffeeCupUserId)
                            }}
                        </v-btn>
                    </template>
                    <template v-slot:item.parentCompanyId="{ item }">
                        <v-btn small>
                            {{ getCompanyName(item.parentCompanyId) }}
                        </v-btn>
                    </template>
                    <template v-slot:item.price="{ item }">
                        <v-chip color="success">
                            <v-icon class="mr-2">
                                mdi-currency-gbp
                            </v-icon>
                            {{ item.price }}
                        </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <span>
                                View Job
                            </span>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" color="primary" small
                                    :to="{ name: 'Job View', params: { jobId: item.id } }" class="mr-2">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip top v-if="$store.getters.hasScope('Job.Delete')">
                            <span>
                                Delete Job
                            </span>
                            <template v-slot:activator="{on,attrs}">
                                <v-btn v-on="on" v-bind="attrs" color="danger" small class="mr-2" @click="deleteJob(item)">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <template v-if="!hideSendToXero">
                            <v-tooltip top>
                                <span>Send to Xero</span>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-on="on" v-bind="attrs" color="primary" small @click="showSendInvoice(item)"
                                        class="mr-2">
                                        <v-icon>
                                            mdi-email-fast-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </template>
                        <template v-if="!hideToggleInvoiceStatus">
                            <v-tooltip top>
                                <span>Set invoice as {{ item.invoiceSent ? 'not sent' : 'sent' }}</span>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :color="(item.invoiceSent ? 'danger' : 'success')" v-on="on" v-bind="attrs"
                                    @click="setInvoiceAsSent(item)"
                                        small>
                                        <v-icon>
                                            {{
                                                item.invoiceSent ? 'mdi-email-remove-outline' : 'mdi-email-check-outline'
                                            }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </template>
                    </template>
                </trev-cool-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="pa-0">
                <v-skeleton-loader types="table"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-dialog v-model="showingDescription" max-width="60vh" v-if="showingDescription">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <v-icon class="mr-2">mdi-rocket</v-icon>
                        {{ showingJob.title }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-btn class="mr-2" v-if="showingJob.coffeeContactId" :to="{
                        name: 'Contact View',
                        params: {
                            contactId: showingJob.coffeeContactId
                        }
                    }">
                        <v-icon class="mr-2">
                            mdi-account-circle
                        </v-icon>
                        {{
                            getContactName(showingJob.coffeeContactId)
                        }}
                    </v-btn>
                    <v-btn class="mr-2" v-if="showingJob.coffeeContactId" :to="{
                        name: 'Contact View',
                        params: {
                            contactId: showingJob.contactId
                        }
                    }">
                        <v-icon class="mr-2">
                            mdi-account-star-outline
                        </v-icon>
                        {{
                            getContactName(showingJob.contactId)
                        }}
                    </v-btn>
                    <v-chip color="info" class="mr-2" v-if="showingJob.poNumber">
                        <v-icon class="mr-2">mdi-pound</v-icon>
                        {{ showingJob.poNumber }}
                    </v-chip>
                    <v-chip color="success">
                        <v-icon class="mr-2">
                            mdi-currency-gbp
                        </v-icon>
                        {{ showingJob.price }}
                    </v-chip>
                </v-card-text>
                <v-divider class="pa-0 ma-0"></v-divider>
                <v-card-text class="pa-6" v-html="showingJob.description">
                </v-card-text>
            </v-card></v-dialog>
        <v-dialog v-model="showSendToXero" max-width="100vh" @click:outside="cancelSendToXero">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Invoice for Job: <span class="success--text">{{ selectedJob.title }}</span>
                    </v-toolbar-title>
                    <v-spacer>
                    </v-spacer>
                    <v-btn color="info" @click="confirmSendToXero" :disabled="hasSentToXero">
                        <v-icon class="mr-2">
                            mdi-email-fast-outline
                        </v-icon>
                        Send to Xero
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4" v-show="!hasSentToXero">
                    <trev-invoice-view :showFormattedDates="true" :canEdit="false" :companyId="selectedInvoiceCompanyId"
                        :invoice="selectedInvoice"></trev-invoice-view>
                </v-card-text>
                <v-card-text class="pt-4" v-show="hasSentToXero">
                    <v-icon class="success--text mr-2">
                        mdi-check-circle-outline
                    </v-icon>
                    Successfully sent to Xero
                </v-card-text>
            </v-card>
        </v-dialog>
        <TrevConfirmDeleteDialog :show="isShowingDeleteJob" :itemToDelete="jobToDelete.title"
        @delete="confirmDeleteJob" @cancel="cancelDeleteJob" >
        </TrevConfirmDeleteDialog>
    </v-container>
</template>

<script>
import trevCoolTable from '../tables/trev-cool-table.vue'
import * as jobUtils from '../../utilities/Object Utilities/Jobs'
import TrevInvoiceView from '../invoices/trev-invoice-view.vue';
import TrevConfirmDeleteDialog from '../base/dialog/trev-confirm-delete-dialog.vue';
export default {
    components: { trevCoolTable, TrevInvoiceView, TrevConfirmDeleteDialog },
    props: {
        companyId: {
            type: String,
            default: ''
        },
        isGlobal: {
            type: Boolean,
            default: false,
        },
        defaultStatus: {
            type: String,
            default: 'open'
        },
        //0 is no invoice sent, 1 is invoice sent, 2 is any
        defaultInvoiceSentStatus: {
            type: String,
            default: '2'
        },
        hideInvoiceStatus: {
            type: Boolean,
            default: false
        },
        hideStatus: {
            type: Boolean,
            default: false
        },
        hideSendToXero: {
            type: Boolean,
            default: true
        },
        hideToggleInvoiceStatus: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        getHeaders() {
            var heds = this.headers;

            if (!this.isGlobal) {
                heds = heds.filter(x => x.value != 'parentCompanyId');
            }

            return heds;
        },
        additionalQueryStrings() {
            var invoiceSent = null;

            if (this.selectedInvoiceStatus == '0') {
                invoiceSent = false;
            } else if (this.selectedInvoiceStatus == '1') {
                invoiceSent = true;
            }

            var qStrings = [{
                key: 'statusFilter',
                value: this.selectedStatus
            }];


            if (invoiceSent != null) {
                qStrings.push({
                    key: 'invoiceSent',
                    value: invoiceSent
                });
            }

            return qStrings;
        },
        jobCourier() {
            if (this.isGlobal) {
                return this.$courier.Jobs;
            } else {
                return this.$courier.Companies.withId(this.companyId).Jobs;
            }
        }
    },
    created() {
        this.selectedStatus = this.defaultStatus;
        this.selectedInvoiceStatus = this.defaultInvoiceSentStatus;
    },
    watch: {
        selectedInvoiceStatus(newVal, oldVal) {
            this.$nextTick(() => {
                this.$refs.tbl.getData();
            });
        },
        selectedStatus(newValue, oldValue) {
            this.$nextTick(() => {
                this.$refs.tbl.getData();
            });
        }
    },
    methods: {
        cancelDeleteJob(){
            this.jobToDelete = {};
            this.isShowingDeleteJob = false;
        },
        confirmDeleteJob(){
            this.$courier.Jobs.removeById(this.jobToDelete.id).then(() => {
                this.$refs.tbl.getData();

                this.jobToDelete = {};
                this.isShowingDeleteJob = false;
            });
        },
        setInvoiceAsSent(job){
            //get the job and update it

            this.$courier.Jobs.getById(job.id).then(job => {
                job.invoiceSent = !job.invoiceSent;

                this.$courier.Jobs.updateById(job.id, job).then(() => {
                    this.$refs.tbl.getData();
                });
            })
        },
        async confirmSendToXero() {
            var invoice = await this.$courier.XeroInvoiceExternal.add(this.selectedInvoice, false, [{
                key: 'tenantId',
                value: this.selectedInvoice.tenantId
            }]);

            var job = await this.$courier.Jobs.getById(this.selectedJob.id);

            job.xeroInvoiceId = invoice.id;
            job.invoiceSent = true;

            //now update the job

            this.$courier.Jobs.updateById(this.selectedJob.id, job).then(() => {
                //job is now updated, reset all and display done message

                this.cancelSendToXero(true);
                this.hasSentToXero = true;
                this.$refs.tbl.getData();
            })
        },
        async showSendInvoice(job) {
            //construct the invoice
            var invoice = await jobUtils.convertJobToInvoice(this.$courier, job);
            console.log(invoice);
            this.selectedInvoice = invoice;
            this.selectedInvoiceCompanyId = job.parentCompanyId;
            this.selectedJob = job;
            this.showSendToXero = true;
        },
        cancelSendToXero(keepModalOpen) {
            this.selectedInvoice = {};
            this.selectedInvoiceCompanyId = '';
            this.selectedJob = {};
            this.hasSentToXero = false;
            if (!keepModalOpen) {
                this.showSendToXero = false;
            }
        },
        getStatusColour(status) {
            return jobUtils.getStatusColour(status.status);
        },
        getShortDesc(item) {
            if (item && item.description) {
                return item.description.substring(0, 20) + '...'
            } else {
                return '';
            }
        },
        getCompanyName(companyId) {
            var comp = this.companiesInTable.find(x => x.id == companyId);

            if (comp) {
                return comp.name;
            } else {
                return '';
            }
        },
        getContactName(contactId) {
            var c = this.getContact(contactId);

            var name = '';

            if (c && c.firstName) {
                name += c.firstName + ' ';
            }

            if (c && c.lastName) {
                name += c.lastName;
            }

            return name;
        },
        getUserName(userId) {
            var user = this.getUser(userId);

            var name = '';

            if (user && user.firstName) {
                name += user.firstName + ' ';
            }

            if (user && user.lastName) {
                name += user.lastName;
            }

            return name;
        },
        loadedData(data) {
            this.contactsInTable = [];

            for (const row of data) {
                if (row.contactId && !this.contactsInTable.some(x => x.id == row.contactId)) {
                    this.$courier.Contacts.getById(row.contactId).then(contact => {
                        this.contactsInTable.push(contact);
                    });
                }

                if (row.coffeeCupUserId && !this.usersInTable.some(x => x.id == row.coffeeCupUserId)) {
                    this.$courier.SystemUsers.getById(row.coffeeCupUserId).then(user => {
                        this.usersInTable.push(user);
                    });
                }

                if (this.isGlobal && !this.companiesInTable.some(x => x.id == row.parentCompanyId)) {
                    this.$courier.Companies.getById(row.parentCompanyId).then(company => {
                        this.companiesInTable.push(company);
                    });
                }
            }
        },
        getCompany(companyId) {
            return this.companiesInTable.find(x => x.id == companyId);
        },
        getUser(userId) {
            return this.usersInTable.find(x => x.id == userId);
        },
        getContact(contactId) {
            return this.contactsInTable.find(x => x.id == contactId);
        },
        showDescription(item) {
            this.showingJob = item;
            this.showingDescription = true;
        },
        addJob() {
            this.$router.push({
                name: 'Add Job',
                params: {
                    companyId: this.companyId
                }
            });
        },
        deleteJob(job){
            this.jobToDelete = job;
            this.isShowingDeleteJob = true;
        }
    },
    data() {
        return {
            isShowingDeleteJob: false,
            jobToDelete: {},
            selectedInvoiceCompanyId: '',
            selectedInvoice: {},
            selectedJob: {},
            showSendToXero: false,
            usersInTable: [],
            hasSentToXero: false,
            contactsInTable: [],
            companiesInTable: [],
            showingJob: {},
            showingDescription: false,
            selectedInvoiceStatus: null,
            selectedStatus: 'open',
            invoiceStatusMap: [
                {
                    key: '0',
                    text: 'Not Sent'
                },
                {
                    key: '1',
                    text: 'Sent'
                },
                {
                    key: '2',
                    text: 'All'
                }
            ],
            headers: [
                {
                    text: 'Date Added',
                    value: 'dateAdded'
                },
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'PO Number',
                    value: 'poNumber'
                },
                {
                    text: 'Invoice Sent',
                    value: 'invoiceSent'
                },
                {
                    text: 'Title',
                    value: 'title'
                }, {
                    text: 'Description',
                    value: 'desc'
                }, {
                    text: 'Contact',
                    value: 'contactId'
                }, {
                    text: 'Project Manager',
                    value: 'coffeeCupUserId'
                },
                {
                    text: 'Company',
                    value: 'parentCompanyId'
                },
                {
                    text: 'Price',
                    value: 'price'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                }]
        }
    },
}
</script>

<style>

</style>