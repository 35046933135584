<template>
  <base-card v-if="productSkeleton.hasRules">
    <v-app-bar flat color="rgba(0, 0, 0, 0)">
      <v-toolbar-title class="text-h6 white--text pl-0">
        Price Testing
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch label="Options" v-model="showOptions" class="mt-6"></v-switch>
    </v-app-bar>
    <v-slide-y-transition>
      <v-card-text v-if="showOptions">
        <v-row>
          <v-col cols="2">
            <v-text-field type="number" label="Min" v-model="minimumUnitCount2">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field type="number" label="Max" v-model="maximumUnitCount2">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field type="number" label="Increment" v-model="increment2">
            </v-text-field>
          </v-col>
          <v-col cols="2">
              <v-btn @click="calculate">
                  Calculate
              </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-slide-y-transition>
    <v-list-item v-for="index in rows" :key="index">
      <v-list-item-title>
        <span class="float-left">
          {{ index }} {{ productSkeleton.unitCharged }}s will be charged {{getProductPrice(index)}}
        </span>
      </v-list-item-title>
    </v-list-item>
  </base-card>
</template>

<script>
export default {
  components: {},
  computed: {
    rows() {
      var array = [];
      var reachedMax = false;
      while (!reachedMax) {
        if (array && array[array.length - 1] + this.increment >= this.maximumUnitCount) {
          reachedMax = true;
        } else {
          array.push((this.minimumUnitCount += this.increment));
        }
      }

      return array;
    }
  },
  props: {
    productSkeleton: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
      getProductPrice(numberOfUnits){
          var ruleApplied = {};

          this.productSkeleton.priceRules.forEach(rule => {
              if(rule.operator == 'greaterthan' && rule.sourceProperty == 'self' && numberOfUnits > rule.targetVariableOrProperty){
                  ruleApplied = rule;
              }
          });

          var price = this.productSkeleton.price;

          if(ruleApplied.pricePerUnit){
              
              price = ruleApplied.pricePerUnit;

              console.log(numberOfUnits + ' ' + price + ' - ' + Number(price) * Number(numberOfUnits));
          }            

          return (Number(price) * Number(numberOfUnits)).toString();
      },
      calculate(){
          this.minimumUnitCount = this.minimumUnitCount2;
          this.maximumUnitCount = this.maximumUnitCount2;
          this.increment = this.increment2;

          console.log(this.rows);
      }
  },
  data: () => ({
    showOptions: false,
    minimumUnitCount: 1,
    maximumUnitCount: 50,
    increment: 5,
    minimumUnitCount2: 1,
    maximumUnitCount2: 50,
    increment2: 5
  })
};
</script>

<style></style>
