<template>
  <div>
    <v-list dense>
      <template v-for="(trigger, index) in triggers">
        <v-divider :key="trigger.id + 'divider'" v-if="index > 0"></v-divider>
        <v-list-item :key="trigger.id + 'lItem'">
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col class="pb-0 pt-0">
                  <v-chip
                    color="success"
                    v-if="trigger.parentCompanyId"
                    class="mr-4"
                  >
                    Custom
                  </v-chip>
                  <v-chip class="mr-4" color="info" v-else> Global </v-chip>
                  {{ trigger.name }} -
                  <span class="grey--text">{{ trigger.description }} </span>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text>
            <span class="float-right">
              <v-btn
                class="mr-2"
                color="danger"
                small
                @click="showDelete(trigger)"
              >
                <v-icon class="mr-2">mdi-delete</v-icon>
                Remove
              </v-btn>
              <v-btn
                class="mr-2"
                color="warning"
                small
                @click="editTrigger(trigger.id)"
              >
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Edit
              </v-btn>
              <v-btn color="info" small @click="preExecuteLink(trigger)">
                <v-icon class="mr-2"> mdi-reload </v-icon>
                Execute
              </v-btn>
            </span>
          </v-list-item-action-text>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item class="mt-3">
        <v-list-item-content>
          <v-list-item-title>
            <v-btn
              color="primary"
              class="float-right"
              @click="showAddTrigger = true"
            >
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Add New Trigger
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="showAddTrigger" width="60vh">
      <v-card>
        <v-toolbar elevation="1">
          <v-toolbar-title>
            {{ isEdit ? "Edit" : "Add" }} Trigger
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="cancelSaveTrigger"> Close </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-alert color="info" class="mt-4">
            <v-icon class="mr-2"> mdi-alert-circle-outline </v-icon>
            Use [CompanyName] to inject company name
          </v-alert>
          <v-form>
            <v-text-field label="Name" v-model="newTrigger.name"></v-text-field>
            <v-textarea
              label="Description"
              v-model="newTrigger.description"
            ></v-textarea>
            <v-textarea label="URL" v-model="newTrigger.url"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveTriggerForCompany">
            <v-icon class="mr-2"> mdi-floppy </v-icon>
            Save
          </v-btn>
          <v-btn color="success" @click="saveTriggerGlobally">
            <v-icon class="mr-2"> mdi-floppy </v-icon>
            Save across all companies
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="cancelSaveTrigger">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <trev-confirm-delete-dialog
      :itemToDelete="triggerToDelete.name"
      :maxWidth="'700px'"
      :show="isDelete"
      @delete="deleteTrigger"
      @cancel="cancelDelete"
    >
    </trev-confirm-delete-dialog>
    <v-dialog v-model="showExecuting" width="60vh"  @click:outside="cancelExecute">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>
            Running Trigger: {{ executingTrigger.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            class="mt-6"
            indeterminate
            color="cyan"
            v-if="isExecuting"
          ></v-progress-linear>
          <v-alert
            color="success"
            v-if="isFinishedExecuting && executionError == ''"
            class="mt-6"
            style="white-space: prewrap"
          >
            Successfully Ran Trigger (Took {{ executionTimeToComplete }}ms)
            <div v-if="executionResponse">
              <br />
              <br />
              {{ executionResponse }}
            </div>
            <div v-else>No Response</div>
          </v-alert>
          <v-alert
            color="danger"
            v-if="isFinishedExecuting && executionError != ''"
            class="mt-6"
          >
            Failed Running Trigger
            <div v-if="executionError">
              <br />
              <br />
              {{ executionError }}
            </div>
            <div v-else>No Error Specified</div>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowingAdditionalModal" max-width="60vh" @click:outside="cancelExecute">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-asterisk </v-icon>
            Additional Querystrings
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="executeAdditionalLink" :disabled="additionalQueryStrings.some(x => !x.value)">
            <v-icon class="mr-2"> mdi-reload </v-icon>
                Execute
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row v-for="(item, index) in additionalQueryStrings" :key="index">
            <v-col>
              <v-text-field
                :label="item.key"
                v-model="item.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TrevConfirmDeleteDialog from "../../../../../components/base/dialog/trev-confirm-delete-dialog.vue";
import trevCoolList from "../../../../../components/tables/trev-cool-list.vue";
export default {
  components: { trevCoolList, TrevConfirmDeleteDialog },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    triggerAdditionalQueryStrings() {
      if (this.selectedTrigger.url) {
        //get all the strings

        var url = this.selectedTrigger.url;
        url = url.replaceAll("[CompanyName]", "").replaceAll("[CompanyId]", "");

        var matches = url.match(new RegExp("[[a-zA-Z]+]"));

        var results = [];

        for (const match of matches) {
          results.push(match);
        }
        return results;
      } else {
        return [];
      }
    },
  },
  created() {
    //get company triggers
    this.getTriggers();
  },
  methods: {
    async getTriggers() {
      try {
        var response = await this.$courier.Companies.withId(
          this.companyId
        ).Triggers.getData(1, this.pageSize, "");
        this.totalCount = response.totalCount;
        this.triggers = response.payload;
      } catch (exception) {
        console.log("Error getting Triggers");
      }

      try {
        var response = await this.$courier.Triggers.getData(1, 100, "");

        for (const trig of response.payload) {
          this.triggers.push(trig);
        }
      } catch (exception) {
        console.log("Erorr getting global triggers");
      }
    },
    saveTriggerForCompany() {
      this.newTrigger.parentCompanyId = this.companyId;
      this.saveTrigger();
    },
    saveTriggerGlobally() {
      delete this.newTrigger.parentCompanyId;
      this.saveTrigger();
    },
    async deleteTrigger() {
      await this.$courier.Triggers.removeById(this.triggerToDelete.id);
      this.getTriggers();
      this.cancelDelete();
    },
    cancelDelete() {
      this.triggerToDelete = {};
      this.isDelete = false;
    },
    async showDelete(trigger) {
      this.isDelete = true;
      this.triggerToDelete = trigger;
    },
    async saveTrigger() {
      try {
        if (!this.isEdit) {
          var resp = await this.$courier.Triggers.add(this.newTrigger);
        } else {
          await this.$courier.Triggers.updateById(
            this.newTrigger.id,
            this.newTrigger
          );
        }
        this.newTrigger = {};
        this.showAddTrigger = false;
        this.getTriggers();
      } catch (exception) {
        console.log("Problem saving trigger");
      }
    },
    async preExecuteLink(trigger) {
      this.selectedTrigger = trigger;
      var url = trigger.url
        .replaceAll("[companyName]", "")
        .replaceAll("[companyName]", "");

      if (url.indexOf("[") > -1 && url.indexOf("]") > -1) {
        for (const qString of this.triggerAdditionalQueryStrings) {
          this.additionalQueryStrings.push({ key: qString, value: "" });
        }

        this.isShowingAdditionalModal = true;
      } else {
        this.executeLink(trigger);
      }
    },
    executeAdditionalLink(){
      this.isShowingAdditionalModal = false;
      
      for(const qPair of this.additionalQueryStrings){
        this.selectedTrigger.url.replaceAll(qPair.label, qPair.value);
      }

      this.executeLink(this.selectedTrigger);
    },
    async executeLink(trigger) {
      this.showExecuting = true;
      this.executingTrigger = trigger;
      this.isExecuting = true;
      this.isFinishedExecuting = false;
      this.executionError = "";
      this.executionResponse = "";

      setTimeout(async () => {
        var comp = await this.$courier.Companies.getById(this.companyId);

        try {
          this.isExecuting = true;
          var jsonResp = await this.$courier.Triggers.RunTrigger(
            trigger.id,
            comp.name,
            comp.id,
            this.additionalQueryStrings
          );
          this.isExecuting = false;
          this.executionResponse = jsonResp.response;
          this.executionTimeToComplete = jsonResp.timeToComplete;
          this.executionURL = jsonResp.URL;
          this.isFinishedExecuting = true;
          this.selectedTrigger = {};
          this.additionalQueryStrings = [];
        } catch (exception) {
          this.isExecuting = false;
          this.selectedTrigger = {};
          this.isFinishedExecuting = true;
          this.executionError = exception;
        }
      }, 1000);
    },
    cancelExecute(){
      this.additionalQueryStrings = [];
      this.selectedTrigger ={};
      this.isShowingAdditionalModal = false;
      this.executionTimeToComplete = 0;
      this.executionURL = '';
      this.executionError = '';
      this.executionResponse = '';
      this.executingTrigger = {};
      this.showExecuting = false;
      this.isFinishedExecuting = false;
    },
    cancelSaveTrigger() {
      this.showAddTrigger = false;
      this.newTrigger = {};
      this.isEdit = false;
    },
    async editTrigger(triggerId) {
      this.isEdit = true;
      this.showAddTrigger = true;
      this.newTrigger = await this.$courier.Triggers.getById(triggerId);
    },
  },
  data() {
    return {
      isShowingAdditionalModal: false,
      additionalQueryStrings: [],
      selectedTrigger: {},
      executionTimeToComplete: 0,
      executionURL: "",
      executionError: "",
      executionResponse: "",
      executingTrigger: {},
      showExecuting: false,
      isExecuting: false,
      isFinishedExecuting: false,
      triggers: [],
      pageSize: 15,
      showAddTrigger: false,
      showEditTrigger: false,
      isEdit: false,
      isDelete: false,
      triggerToDelete: {},
      newTrigger: {
        name: "",
        description: "",
        url: "",
        parentCompanyId: "",
      },
    };
  },
};
</script>

<style>
</style>