<template>
  <div>
    <trev-cool-table
      :isDense="false"
      :cardTextClasses="'pt-1 pb-1 pr-1 pl-1'"
      :courier="$courier.Companies.withId(companyId).ZohoTickets"
      title="Zoho Tickets"
      :tableId="'zoho-ticket-table'"
      :headers="heds"
      :dateStart="startDateFromCustom.toISOString('en-GB')"
      :dateEnd="endDateFromCustom.toISOString('en-GB')"
      :hideCSV="true"
      ref="ticketTable"
      :additionalQueryStrings="getAdditionalQueryStrings"
      :getDataOnDateChange="true"
    >
      <template slot="title">
        Zoho Tickets
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="ticketDateFilter = 'This'">
                <v-list-item-title> This Month </v-list-item-title>
              </v-list-item>
              <v-list-item @click="ticketDateFilter = 'Last'">
                <v-list-item-title> Last Month </v-list-item-title>
              </v-list-item>
              <v-list-item @click="ticketDateFilter = 'All'">
                <v-list-item-title> All </v-list-item-title>
              </v-list-item>
              <v-list-item @click="ticketDateFilter = 'custom'">
                <v-list-item-title> Custom </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <span class="mr-1 primary--text capWord"> {{ ticketDateFilter }}</span>
        <span
          class="primary--text mr-1 capWord"
          v-if="ticketDateFilter != 'custom'"
          >month</span
        >
        <span v-else class="primary--text">
          Dates from
          <span v-if="customDates.length >= 2">
            <span class="success--text">{{
              startDateFromCustom.toLocaleDateString("en-GB")
            }}</span>
            to
            <span class="success--text">{{
              endDateFromCustom.toLocaleDateString("en-GB")
            }}</span>
          </span>
        </span>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="ml-4">
              <v-icon>mdi-list-status</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="statusFilter = 'All'">
                <v-list-item-title> All </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(status, index) in allStatuses"
                :key="index"
                @click="statusFilter = status"
              >
                <v-list-item-title>
                  {{ status }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <span class="mr-1 primary--text capWord"> {{ statusFilter }}</span>
      </template>
      <template slot="tablefunctions">
        <v-list-item @click="showExportTickets">
          <v-list-item-content> Export Tickets </v-list-item-content>
          <v-list-item-action>
            <v-icon> mdi-database-export-outline </v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="showExportTickets">
          <v-list-item-content> Reprocess Tickets </v-list-item-content>
          <v-list-item-action>
            <v-icon> mdi-autorenew </v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-slot:item.dateAdded="{ item }">
        {{ item.dateAdded.toString().replace("T", " ") }}
      </template>
      <template v-slot:item.timeEntrySum="{ item }">
        {{ item.timeEntrySum }} Minutes
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label :color="getColour(item.status)" small>
          {{ item.status }}</v-chip
        >
      </template>
      <template v-slot:item.email="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              :href="'mailto:' + item.email"
              v-on="on"
              v-bind="attrs"
            >
              {{
                item.email && item.email.includes("@")
                  ? item.email.substring(0, item.email.indexOf("@"))
                  : item.email
              }}
            </v-btn>
          </template>
          <span>
            {{ item.email }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.subject="{ item }">
        <span class="grabby" @click="showSubjectDialogPressed(item)">{{
          getShortenedString(item.subject)
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mr-2"
              :href="item.ticketLink"
              target="_blank"
              small
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small> mdi-page-next-outline </v-icon>
            </v-btn>
          </template>
          <span>Open In Zoho</span>
        </v-tooltip>
        <v-tooltip top v-if="$store.getters.hasScope('Ticket.Modify')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn  color="info" small v-on="on" v-bind="attrs" @click="confirmRefreshTicket(item.id)">
              <v-icon small> mdi-refresh </v-icon>
            </v-btn>
          </template>
          <span> Refresh Ticket </span>
        </v-tooltip>
      </template>
      <template v-slot:item.timeEntrySum="{ item }">
        <v-chip color="info"> {{ item.timeEntrySum }} Mins </v-chip>
      </template>
      <template v-slot:item.threadCount="{ item }">
        <v-chip color="purple" @click="showTicketThreads(item)">
          {{ item.threadCount }}
        </v-chip>
      </template>
      <template v-slot:item.attachedToProductSku="{ item }">
        <span
          v-if="
            !item.hasBeenScannedToProduct &&
            item.category !== 'No Time Required'
          "
        >
          <span class="info--text"> Unprocessed </span>
        </span>
        <span v-else-if="item.category && item.category === 'No Time Required'">
          <span class="success--text"> No Time Required </span>
        </span>
        <span
          v-else-if="
            item.timeEntryCount === 0 && item.category !== 'No Time Required'
          "
        >
          <span class="warning--text"> No Time Added </span>
        </span>
        <span v-else-if="item.attachedToProductSku === ''">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="danger--text" v-on="on" v-bind="attrs">
                Unsupported
              </span>
            </template>
            <span> No Product Associated </span>
          </v-tooltip>
        </span>
        <span
          v-else-if="
            item.hasBeenScannedToProduct &&
            item.attachedToProductSku != '' &&
            item.minutesToChargeFor > 0
          "
        >
          <span class="danger--text"> Overage </span>
        </span>
        <span
          v-else-if="
            item.hasBeenScannedToProduct && item.attachedToProductSku != ''
          "
        >
          <span class="success--text">{{ item.attachedToProductSku }}</span>
        </span>
      </template>
    </trev-cool-table>
    <v-dialog
      v-model="showCustomMonthDialog"
      max-width="340"
    >
      <v-card>
        <v-card-title> Select Dates </v-card-title>
        <v-card-title v-if="customDates.length >= 2">
          {{ startDateFromCustom.toLocaleDateString("en-GB") }}
        </v-card-title>
        <v-card-subtitle v-if="customDates.length >= 2"> From </v-card-subtitle>
        <v-card-title v-if="customDates.length >= 2">
          {{ endDateFromCustom.toLocaleDateString("en-GB") }}
        </v-card-title>
        <v-card-subtitle v-if="customDates.length >= 2"> To </v-card-subtitle>
        <v-card-text>
          <v-date-picker v-model="customDates" range> </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showThreadDialog" max-width="600px">
      <v-card>
        <v-card-title>
          #{{ selectedTicket.ticketNumber }} - Threads
        </v-card-title>
        <v-card-text
          v-for="(thread, index) in selectedTicket.threads"
          :key="thread.id"
        >
          <div v-html="thread.content"></div>
          <hr class="mt-4 mb-4" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSubjectDialog"
      max-width="300px"
      @click:outside="showSubjectDialogClosed"
    >
      <v-card>
        <v-card-title>
          Ticket #{{ selectedTicket.ticketNumber }} Subject
        </v-card-title>
        <v-card-text>
          {{ selectedTicket.subject }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <trev-ticket-pdf-export-dialog
      :companyId="companyId"
      :endDate="endDateFromCustom"
      :startDate="startDateFromCustom"
      :isShow.sync="showExportDialog"
    ></trev-ticket-pdf-export-dialog>
    <v-dialog v-model="isShowingRefreshTicket" max-width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Refetch Ticket Information </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row v-if="isLoadingRefreshTicket">
              <v-col>
                <v-progress-linear
                  v-show="isLoadingRefreshTicket"
                  indeterminate
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-center"> 
                <span class="success--text">Finished updating ticket <v-icon class="success--text ml-2">mdi-check-circle-outline</v-icon></span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../tables/trev-cool-table.vue";
import * as genUtilities from "../../utilities/GeneralUtilities";
import TrevTicketPdfExportDialog from "../reports/export/trev-ticket-pdf-export-dialog.vue";

export default {
  components: { trevCoolTable, TrevTicketPdfExportDialog },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    startDateFromCustom() {
      var date = new Date();

      if (
        this.ticketDateFilter == "custom" &&
        this.customDates &&
        this.customDates.length >= 2
      ) {
        var min = genUtilities.getMinDateFromArray(this.customDates);
        return min;
      } else if (this.ticketDateFilter == "This") {
        return new Date(date.getFullYear(), date.getMonth(), 1);
      } else if (this.ticketDateFilter == "Last") {
        return new Date(date.getFullYear(), date.getMonth() - 1, 1);
      } else if (this.ticketDateFilter == "All") {
        return new Date(1900, 1, 1);
      } else {
        return new Date(1900, 1, 1);
      }
    },
    endDateFromCustom() {
      var date = new Date();

      if (
        this.ticketDateFilter == "custom" &&
        this.customDates &&
        this.customDates.length >= 2
      ) {
        var max = genUtilities.getMaxDateFromArray(this.customDates);
        return max;
      } else if (this.ticketDateFilter == "This") {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      } else if (this.ticketDateFilter == "Last") {
        return new Date(date.getFullYear(), date.getMonth(), 0);
      } else if (this.ticketDateFilter == "All") {
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
      } else {
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
      }
    },
  },
  watch: {
    ticketDateFilter() {
      if (this.ticketDateFilter == "custom") {
        this.showCustomMonthDialog = true;
      } else {
        this.customDates = [];
        this.showCustomMonthDialog = false;
        this.$refs.ticketTable.getData();
      }
    },
    statusFilter() {
      this.getAdditionalQueryStrings = [
        { key: "status", value: this.statusFilter },
      ];
      
      this.$nextTick(() => {
        this.$refs.ticketTable.getData();
      });
    },
  },
  created() {
    this.$courier.Companies.withId(this.companyId)
      .ZohoTickets.GetTicketStatuses()
      .then((x) => {
        this.allStatuses = x;
      });
  },
  methods: {
    confirmRefreshTicket(ticketId){
      this.isShowingRefreshTicket = true;
      this.isLoadingRefreshTicket = true;
      this.refreshTicket(ticketId).then(() => {
        this.isLoadingRefreshTicket = false;

      })
    },
    async refreshTicket(ticketId) {
      return this.$courier.ZohoTickets.ReProcessTicket(ticketId).then(() => {
        this.$refs.ticketTable.getData();
      });
    },
    showSubjectDialogClosed() {
      this.showSubjectDialog = false;
      this.selectedTicket = {};
    },
    getShortenedString(input, length = 45) {
      return genUtilities.concatenateStringByLength(input, length);
    },
    showExportTickets() {
      this.showExportDialog = true;
    },

    showSubjectDialogPressed(ticket) {
      this.showSubjectDialog = true;
      this.selectedTicket = ticket;
    },
    showTicketThreads(ticket) {
      this.selectedTicket = ticket;
      this.$courier.ZohoTickets.withId(ticket.id)
        .Threads.getData(1, 200, "")
        .then((x) => {
          this.selectedTicket.threads = x.payload;
          this.showThreadDialog = true;
        });
    },
    getColour(status) {
      if (status == "Open") {
        return "red";
      } else if (status == "Closed") {
        return "green";
      } else if (status == "Waiting to start") {
        return "orange";
      } else if (status == "On Hold") {
        return "blue";
      }
    },
  },
  data() {
    return {
      getAdditionalQueryStrings: [
        {
          key: "status",
          value: "All",
        },
      ],
      isShowingRefreshTicket: false,
      isLoadingRefreshTicket: false,
      ticketBeingRefreshed: {},
      refreshedTicket: {},
      statusFilter: "All",
      allStatuses: [],
      showExportDialog: false,
      showSubjectDialog: false,

      selectedTicket: {},
      showThreadDialog: false,
      customDates: [],
      showCustomMonthDialog: false,
      ticketDateFilter: "This",
      dateStart: "",
      dateEnd: "",
      heds: [
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Ticket #",
          value: "ticketNumber",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Subject",
          value: "subject",
        },
        {
          text: "Product",
          value: "attachedToProductSku",
        },
        {
          text: "Time Spent",
          value: "timeEntrySum",
        },
        {
          text: "Threads",
          value: "threadCount",
        },
        {
          text: "Date Added",
          value: "dateAdded",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>


<style scoped>
.capWord {
  text-transform: capitalize;
}
.grabby {
  cursor: pointer;
}
</style>