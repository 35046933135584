<template>
  <div>
    <v-row>
        <v-col>
            <h4>
                Existing Product:
                <span class="info--text">
                    {{product.name}}
                </span>
            </h4>
            <p class="muted--text">
                Description:
                <span class="info--text">
                    {{product.description}}
                </span>
            </p>
            <p class="muted--text">
                Change Quantity in final step
            </p>
        </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            
        }
    },
}
</script>

<style>

</style>