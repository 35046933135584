<template>
  <div>
    <div v-if="isUpdatingZohoDesk">
      <trev-zoho-desk-search
        v-model="foundZohoDeskCompany"
        :defaultSearch="company.name"
      >
      </trev-zoho-desk-search>

      <v-btn
        @click="saveZohoDeskLink"
        type="success"
        :disabled="Object.keys(foundZohoDeskCompany).length === 0"
      >
        <v-icon class="mr-2"> mdi-save </v-icon>
        Save Zoho Desk Link
      </v-btn>
      <v-btn color="info" class="ml-2" @click="showAddInZoho = true">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create in Zoho
      </v-btn>
      <v-btn
        @click="changeZohoDesk = false"
        color="danger"
        text
        class="ml-2"
        v-if="company.zohoDeskId"
      >
        <v-icon class="mr-2"> mdi-close </v-icon>
        Cancel
      </v-btn>
      <v-dialog v-model="showAddInZoho" max-width="400px">
        <v-card>
          <v-card-title>
            <v-icon class="mr-2"> mdi-asterisk </v-icon>
            New Company In Zoho
          </v-card-title>
          <v-card-text>
            <v-text-field label="Name" v-model="newZohoCompanyName"> </v-text-field>
            <v-btn color="info" @click="createCompanyInZoho">
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Create Company
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h4>
        {{ company.name }}
      </h4>
      <v-btn @click="changeZohoDesk = true" color="warning" small>
        <v-icon class="mr-2">mdi-pencil</v-icon>Change
      </v-btn>
    </div>
  </div>
</template>

<script>
import trevZohoDeskSearch from "../../../../../components/forms/trev-zoho-desk-search.vue";
export default {
  components: { trevZohoDeskSearch },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUpdatingZohoDesk() {
      return this.changeZohoDesk || !this.company.zohoDeskId;
    },
  },
  methods: {
    createCompanyInZoho() {
      this.$courier.ZohoDeskExternal.add({
        accountName: this.newZohoCompanyName,
      }).then((newZohoCompany) => {
        this.company.zohoDeskId = newZohoCompany.id;

        this.$courier.Companies.updateById(this.company.id, this.company).then(
          () => {
            this.changeZohoDesk = false;
            this.showAddInZoho = false;
            this.$emit("update-company", this.company);
          }
        );
      });
    },
    saveZohoDeskLink() {
      this.company.zohoDeskId = this.foundZohoDeskCompany.id;
      this.$courier.Companies.updateById(this.company.id, this.company).then(
        () => {
          this.foundZohoDeskCompany = {};
          this.changeZohoDesk = false;
          this.newZohoCompanyName = '';
          this.$emit("update-company", this.company);
        }
      );
    },
  },
  data() {
    return {
      foundZohoDeskCompany: {},
      changeZohoDesk: false,
      showAddInZoho: false,
      newZohoCompanyName: this.company.name
    };
  },
};
</script>

<style>
</style>