<template>
  <v-list>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title>
                {{product.skuNumber}}
            </v-list-item-title>
            <v-list-item-subtitle>
                SKU
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title>
                {{product.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
                Name
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title>
                {{product.description}}
            </v-list-item-title>
            <v-list-item-subtitle>
                Description
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title>
                {{product.division}}
            </v-list-item-title>
            <v-list-item-subtitle>
                Division
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title>
                {{product.supplier}}
            </v-list-item-title>
            <v-list-item-subtitle>
                Supplier
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => {}
        },
    },
}
</script>

<style>

</style>