<template>
  <v-autocomplete
    :search-input.sync="zohoCRMSearch"
    :loading="zohoCRMIsLoading"
    v-model="selectedZohoCRMCompany"
    hide-details
    :items="allFoundZohoCRMCompanies"
    hide-no-data
    item-text="account_Name"
    item-value="id"
    placeholder="Search Zoho CRM"
    prepend-icon="mdi-database-search"
    label="Zoho CRM Account"
    return-object
    class="mb-4"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    zohoCRMSearch() {
      if (this.zohoCRMIsLoading) {
        return;
      } else {
        this.searchForZohoCRMCompanies();
      }
    },
    selectedZohoCRMCompany(){
        this.$emit('input', this.selectedZohoCRMCompany);
    }
  },
  methods: {
    searchForZohoCRMCompanies() {
      this.zohoCRMIsLoading = true;

      this.$courier.Zoho.Companies.getData(1, 20, this.zohoCRMSearch).then(
        (x) => {
          this.allFoundZohoCRMCompanies = x;
          this.zohoCRMIsLoading = false;
        }
      );
    },
  },
  data() {
    return {
      zohoCRMSearch: "",
      zohoCRMIsLoading: false,
      selectedZohoCRMCompany: {},
      allFoundZohoCRMCompanies: [],
    };
  },
};
</script>

<style>
</style>