<template>
  <v-autocomplete
    :search-input.sync="search"
    :loading="isLoading"
    v-model="selectedXeroCompany"
    hide-details
    :items="companies"
    hide-no-data
    item-text="Name"
    item-value="ContactID"
    placeholder="Search Xero"
    prepend-icon="mdi-database-search"
    label="Xero"
    return-object
    class="mb-4"
  >
    <!-- <template v-slot:selection="data">
      {{data.item.Name}}
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
      </v-list-item-content>
    </template> -->
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    selectedXeroCompany() {
      this.$emit("input", this.selectedXeroCompany);
    },
    search() {
      clearTimeout(this.debounceId);

      this.debounceId = setTimeout(() => {
        this.searchForCompanies();
      }, 200);
    },
  },
  methods: {
    searchForCompanies() {
      this.companies = [];
      this.isLoading = true;
      //get all the stored org profiles and get data
      var allProfiles = this.$store.getters.getXeroProfiles.map(
        (x) => x.tenantId
      );

      if (!allProfiles) {
        this.isLoading = false;
      }

      var allPromises = [];

      for (const tenantId of allProfiles) {
        var getDataPromise = this.$courier.XeroContactExternal.getData(
          1,
          20,
          this.search,
          null,
          null,
          [{ key: "TenantId", value: tenantId }]
        ).then((data) => {
          for (const c of data.payload) {
            c.tenantId = tenantId;
            this.companies.push(c);
          }
        });

        allPromises.push(getDataPromise);
      }

      Promise.all(allPromises).then(() => {
        this.isLoading = false;
      });
    },
  },
  data() {
    return {
      debounceId: '',
      selectedXeroCompany: {},
      search: "",
      isLoading: false,
      companies: [],
    };
  },
};
</script>

<style>
</style>