var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('base-card',[_c('v-card-text',[_c('trev-simple-table',{attrs:{"pageSize":_vm.productTablePageSize,"title":"Products","totalRecordCount":_vm.products.length,"tableId":"contract-pricing-settings","isLoading":_vm.isLoading,"items":_vm.getProducts,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'custom')?_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" Custom ")]):(item.type == 'Existing')?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" Existing ")]):(item.type == 'In Contract')?_c('v-chip',{attrs:{"color":"purple"}},[_vm._v(" In Contract ")]):_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" Global ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.price)+" ")]}},{key:"item.skuNumber",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.skuNumber)+" ")])]}},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g({attrs:{"single-line":""},model:{value:(item.purchaseDate),callback:function ($$v) {_vm.$set(item, "purchaseDate", $$v)},expression:"item.purchaseDate"}},on))]}}],null,true)},[_c('span',[_c('v-date-picker',{on:{"change":function($event){return _vm.$emit('update:products', _vm.productsList)}},model:{value:(item.purchaseDate),callback:function ($$v) {_vm.$set(item, "purchaseDate", $$v)},expression:"item.purchaseDate"}})],1)])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){item.quantity--;
                  _vm.$emit('update:products', _vm.productsList);
                  _vm.compareExistingProductQuantities(item);}}},[_c('v-icon',[_vm._v(" mdi-minus ")])],1),_c('v-text-field',{staticClass:"inline",attrs:{"single-line":"","dense":""},on:{"keyup":function($event){_vm.$emit('update:products', _vm.productsList);
                  _vm.$emit('update:products', _vm.productsList);
                  _vm.compareExistingProductQuantities(item);}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){item.quantity++;
                  _vm.$emit('update:products', _vm.productsList);
                  _vm.compareExistingProductQuantities(item);}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]}},{key:"item.errors",fn:function(ref){
                  var item = ref.item;
return [(
                _vm.existingProducts.find(
                  function (x) { return x.id === item.id; }
                ) &&
                _vm.existingProducts.find(function (x) { return x.skuNumber === item.skuNumber; })
                  .isLessThanOriginal
              )?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"danger--text"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getProductQuantityDifference(item))+" Products will be excluded from this contract ")])])],1):_vm._e()]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('base-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(" Suggested Price ")])]),_c('v-card-text',[_c('span',{staticClass:"text-h4"},[_c('span',{staticClass:"success--text"},[_vm._v(" £"+_vm._s(_vm.suggestedPrice)+" ")])])])],1),_c('base-card',{staticClass:"mt-4"},[_c('v-card-title',[_c('span',{staticClass:"text-h3"},[_vm._v(" Final Price ")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-currency-gbp","outlined":"","color":"success","type":"number"},on:{"keyup":function($event){_vm.$emit('update:price', Number(_vm.innerPrice))}},model:{value:(_vm.innerPrice),callback:function ($$v) {_vm.innerPrice=$$v},expression:"innerPrice"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }