<template>
  <v-form ref="xeroCompanyForm" v-model="isValid">
    <v-select label="Tenant" v-model="comp.tenantId" :items="tenants"></v-select>
    <v-text-field
      label="First Name"
      :rules="[(x) => !!x || 'First Name is required']"
      required
      v-model="comp.firstName"
    ></v-text-field>
    <v-text-field
      label="Last Name"
      :rules="[(x) => !!x || 'Last Name is required']"
      required
      v-model="comp.lastName"
    ></v-text-field>
    <v-text-field
      label="Company Name"
      :rules="[(x) => !!x || 'Name is required']"
      required
      v-model="comp.name"
    ></v-text-field>
    <v-text-field
      label="Email Address"
      :rules="[(x) => !!x || 'Emailaddress is required']"
      required
      v-model="comp.emailAddress"
    ></v-text-field>
    <v-text-field
      label="Mobile Number"
      :rules="[(x) => !!x || 'Mobile Number is required']"
      required
      v-model="comp.mobileNumber"
    ></v-text-field>
    <v-text-field
      label="Land Line"
      :rules="[(x) => !!x || 'Land Line is required']"
      required
      v-model="comp.landLine"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    comp: {
      handler() {
        this.$emit("input", this.comp);
      },
      deep: true,
    },
  },
  created() {
    this.comp.firstName = this.value.firstName;
    this.comp.lastName = this.value.lastName;
    this.comp.name = this.value.name;
    this.comp.emailAddress = this.value.emailAddress;
    this.comp.mobileNumber = this.value.mobileNumber;
    this.comp.landLine = this.value.landLine;

    this.tenants = this.$store.getters.getXeroProfiles.map((x) => ({
      text: x.organisationName,
      value: x.tenantId,
    }));
  },
  data() {
    return {
      tenants: [],
      isValid: false,
      comp: {
        tenantId: "",
        firstName: "",
        lastName: "",
        name: "",
        emailAddress: "",
        mobileNumber: "",
        landLine: "",
      },
    };
  },
};
</script>

<style>
</style>