var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trev-cool-table',{attrs:{"headers":_vm.headers,"courier":_vm.$courier.Companies.withId(_vm.companyId).Contracts,"title":"Contracts","tableId":_vm.uniqueTableId,"showAddLinkAsButton":true},on:{"add-clicked":function($event){return _vm.$emit('add-contract')}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.effectiveDate).toISOString().split("T")[0])+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.expiryDate).toISOString().split("T")[0])+" ")]}},{key:"item.hasBreakClause",fn:function(ref){
var item = ref.item;
return [(item.hasBreakClause)?_c('span',[_vm._v(" Break Clause at "+_vm._s(item.breakClauseInDaysFromEffectiveDate)+" from "+_vm._s(new Date(item.effectiveDate).toISOString().split("T")[0])+" ")]):_c('span',[_vm._v(" No Break Clause ")])]}},{key:"item.hasAlerts",fn:function(ref){
var item = ref.item;
return [(item.hasAlerts)?_c('span',[_vm._v(" Will Alert at "+_vm._s(item.alertOffsetInDays)+" to "+_vm._s(item.alertGroup)+" ")]):_c('span',[_vm._v(" No Alerting ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.price)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text mr-2",attrs:{"color":"primary","small":"","to":{ name: 'Contract View', params: { contractId: item.id } }}},[_vm._v(" View "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-magnify ")])],1),(_vm.$store.getters.hasScope('Contact.Delete'))?_c('v-btn',{staticClass:"white--text",attrs:{"color":"danger","small":""},on:{"click":function($event){_vm.contractSelected = item;
          _vm.showConfirmDelete = true;}}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete ")],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"80vh"},model:{value:(_vm.showConfirmDelete),callback:function ($$v) {_vm.showConfirmDelete=$$v},expression:"showConfirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.contractSelected.name)+"? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v(" No, Keep "+_vm._s(_vm.contractSelected.name)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"warning"},on:{"click":_vm.deleteContractWithoutProducts}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete, Keep Products ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"danger"},on:{"click":_vm.deleteContract}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete, with Products ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }