<template>
  <div>
    <v-stepper v-model="stepperStep">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="stepperStep > 1">
          Basic Details
        </v-stepper-step>
        <v-divider> </v-divider>
        <v-stepper-step step="2" :complete="stepperStep > 2">
          Break Clause
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="stepperStep > 3">
          Alert Settings
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="stepperStep > 4">
          Select Products
        </v-stepper-step>
        <v-divider></v-divider>
        <template v-for="(prodSkel, index) in selectedSkeletons">
          <v-stepper-step
            :key="index + '-product'"
            :complete="stepperStep > 5 + index"
            :step="(5 + index).toString()"
          >
            Customise: <span class="success--text">{{ prodSkel.name }}</span>
          </v-stepper-step>
          <v-divider :key="index"></v-divider>
        </template>
        <v-stepper-step
          :step="5 + selectedSkeletons.length"
          :complete="stepperStep > 5 + selectedSkeletons.length"
        >
          Price Settings
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <h4>Basic Details</h4>
          <trev-contract-basic-details
            :contractName.sync="contract.name"
            :effectiveDate.sync="contract.effectiveDate"
            :expiryDate.sync="contract.expiryDate"
            :invoiceNumber.sync="contract.invoiceNumber"
          ></trev-contract-basic-details>
          <v-btn color="primary" class="float-right" @click="stepperStep++">
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <h4>Break Clause Settings</h4>
          <trev-contract-break-clause
            :hasBreakClause.sync="contract.hasBreakClause"
            :breakClauseInDays.sync="
              contract.breakClauseInDaysFromEffectiveDate
            "
            :effectiveDate.sync="contract.effectiveDate"
          >
          </trev-contract-break-clause>
          <v-btn color="primary" class="float-right" @click="stepperStep++">
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="3">
          <h4>Alert Settings</h4>
          <trev-product-alert-settings
            :alertGroup.sync="contract.alertGroup"
            :alertOffSetInDays.sync="contract.alertOffsetInDays"
            :hasAlert.sync="contract.hasAlerts"
          ></trev-product-alert-settings>
          <v-btn color="primary" class="float-right" @click="stepperStep++">
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="4">
          <trev-contract-product-selection
            :companyId="companyId"
            :products.sync="selectedSkeletons"
          >
          </trev-contract-product-selection>
          <v-btn
            color="primary"
            class="float-right"
            @click="selectedAllSkeletons"
          >
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
        <v-stepper-content
          :step="(5 + index).toString()"
          v-for="(prodSkel, index) in skeletonsThatAreSelectedForCustomisation"
          :key="prodSkel.id"
        >
          <trev-product-customise
            v-if="prodSkel.type != 'Existing'"
            :productSkeleton="prodSkel"
            @product-updated="customisedSkeletonUpdated($event, index)"
            @products-are-equal="
              $set(selectedSkeletons[index], 'isCustomised', false);
              $forceUpdate();
            "
            @products-are-not-equal="
              $set(selectedSkeletons[index], 'isCustomised', true);
              $forceUpdate();
            "
          >
          </trev-product-customise>
          <trev-contract-existing-product-edit :product="prodSkel" v-else>
          </trev-contract-existing-product-edit>
          <v-btn
            color="primary"
            class="float-right"
            @click="productCustomseNext(prodSkel)"
          >
            <span v-show="selectedSkeletons[index].isCustomised">
              Continue with changes
            </span>
            <span v-show="!selectedSkeletons[index].isCustomised">
              Continue without making changes
            </span>
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
        <v-stepper-content :step="5 + selectedSkeletons.length">
          <trev-contract-pricing-settings
            :products.sync="finalisedProducts"
            :price.sync="contract.price"
          >
          </trev-contract-pricing-settings>
          <v-btn color="primary" class="float-right" @click="saveContract">
            Save Contract
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import * as productUtils from "../../utilities/Object Utilities/Products";
import TrevProductAlertSettings from "../products/forms/trev-product-alert-settings.vue";
import TrevProductCustomise from "../products/trev-product-customise.vue";
import trevContractBasicDetails from "./forms/trev-contract-basic-details.vue";
import TrevContractBreakClause from "./forms/trev-contract-break-clause.vue";
import TrevContractExistingProductEdit from "./forms/trev-contract-existing-product-edit.vue";
import TrevContractPricingSettings from "./forms/trev-contract-pricing-settings.vue";
import TrevContractProductSelection from "./forms/trev-contract-product-selection.vue";
export default {
  components: {
    trevContractBasicDetails,
    TrevContractBreakClause,
    TrevProductAlertSettings,
    TrevContractProductSelection,
    TrevProductCustomise,
    TrevContractPricingSettings,
    TrevContractExistingProductEdit,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    }    
  },
  methods: {
    async saveContract() {
      if (this.contract.expiryDate === "") {
        delete this.contract.expiryDate;
      }

      if (this.contract.effectiveDate === "") {
        delete this.contract.effectiveDate;
      }

      var newContract = await this.$courier.Companies.withId(
        this.companyId
      ).Contracts.add(this.contract);

      this.finalisedProducts.forEach((x) => {
        if (x.purchaseDate === "") {
          delete x.purchaseDate;
        }
      });

      var allNewSkeletons = this.finalisedProducts.filter(
        (x) => x.type !== "Existing"
      );
      var allExistingProducts = this.finalisedProducts.filter(
        (x) => x.type === "Existing"
      );

      //loop through the products, if its an existing one, then update to have parent contract id of new one, if not existing, just add
      console.log('all existing')
      console.log(allExistingProducts);
      for (const product of allExistingProducts) {
        product.parentContractId = newContract.id;
        product.dateAdded = new Date().toISOString();
        //get the difference of quantity and set that as a duplicate product
        var newProduct = await this.$courier.Products.getById(product.id);

        //use this as a product to duplicate for new one with updated quantity
        var replicated = JSON.parse(JSON.stringify(newProduct));
        delete replicated.id;
        delete replicated.type;

        if (product.quantity < replicated.quantity) {
          //need to add seperate product with new diff quantity subtract from contract quantity

          var metaData = [];
          var priceRules = [];

          metaData = await this.$courier.Products.withId(
            product.id
          ).MetaData.getData(1, 99999, "");

          if (metaData) {
            metaData.payload.forEach((m) => {
              delete m.id;
            });
          }

          replicated.metaData = metaData.payload;

          //get the priceRules
          priceRules = await this.$courier.Products.withId(
            product.id
          ).PriceRules.getData(1, 99999, "");
          if (priceRules) {
            priceRules.payload.forEach((p) => {
              delete p.id;
            });
          }

          //this is the product which is the new one NOT in the contract

          replicated.priceRules = priceRules.payload;
          replicated.quantity = replicated.quantity - product.quantity;
          delete replicated.parentContractId;
          replicated.dateAdded = new Date().toISOString();
          replicated.totalCost = productUtils.calculatePriceFromRules(
            replicated.quantity,
            replicated.price,
            replicated.priceRules
          );

          //add the new product into system

          this.$courier.Products.add(replicated);

          product.totalCost = productUtils.calculatePriceFromRules(
            product.quantity,
            product.price,
            product.priceRules
          );

          this.$courier.Products.updateById(
            product.id,
            product
          );
        } else {
          //in this case the contract existing product has either same or greater quantity to existing
          // need to update existing product with new quantity and total cost
          // quantity is done already, just call cost update and commit

          product.totalCost = productUtils.calculatePriceFromRules(
            product.quantity,
            product.price,
            product.priceRules
          );

          this.$courier.Products.updateById(
            product.id,
            product
          );
        }
      }

      allNewSkeletons.forEach((x) => {
        this.$courier.Contracts.withId(newContract.id)
          .Products.add(x)
          .then(() => {
            //idk show done?
          });
      });

      this.contract = {
        name: "",
        invoiceNumber: "",
        effectiveDate: "",
        expiryDate: "",
        hasBreakClause: false,
        breakClauseInDaysFromEffectiveDate: 0,
        price: 0,
        hasAlerts: false,
        alertOffsetInDays: 0,
        alertGroup: "",
        products: [],
        parentCompanyId: this.companyId,
      };
      this.customisedSkeletons = [];
      this.selectedSkeletons = [];
      this.finalisedProducts = [];
      this.stepperStep = 1;
      this.$forceUpdate();

      this.$emit("added-contract", newContract);
    },
    productCustomseNext(prodSkel) {
      if (this.stepperStep - 4 >= this.selectedSkeletons.length) {
        //generate a list of products from the product skeletons inside var = customisedSkeletons;

        var products = JSON.parse(JSON.stringify(this.customisedSkeletons));

        products.forEach((product) => {
          if (product.type !== "Existing") {
            delete product.id;
          }

          if (product.metaData) {
            product.metaData.forEach((metaDataItem) => {
              delete metaDataItem.id;
            });
          }

          if (product.priceRules) {
            product.priceRules.forEach((priceRule) => {
              delete priceRule.id;
            });
          }

          product.expiryDate = this.contract.expiryDate;
          product.purchaseDate = this.contract.effectiveDate;
          // product.quantity = 1;
          // product.totalCost = 0;
          if (!product.quantity || product.quantity <= 1) {
            product.quantity = 1;
          }

          product.parentCompanyId = this.companyId;
        });

        this.finalisedProducts = products;
      }
      this.stepperStep++;
    },
    selectedAllSkeletons() {
      this.selectedSkeletons.forEach((skelly) => {
        var priceRulePromise = this.$courier.ProductSkeletons.withId(
          skelly.id
        ).PriceRules.getData(1, 999, "");
        var metaDataPromise = this.$courier.ProductSkeletons.withId(
          skelly.id
        ).MetaData.getData(1, 999, "");

        Promise.all([priceRulePromise, metaDataPromise]).then((promiseData) => {
          skelly.priceRules = promiseData[0].payload;
          skelly.metaData = promiseData[1].payload;
        });
      });

      this.customisedSkeletons = JSON.parse(
        JSON.stringify(this.selectedSkeletons)
      );

      this.stepperStep++;
    },
    customisedSkeletonUpdated(customSkelly, index) {
      this.customisedSkeletons[index] = customSkelly;
    },
  },
  computed: {
    skeletonsThatAreSelectedForCustomisation() {
      return this.selectedSkeletons;
    },
  },
  data() {
    return {
      finalisedProducts: [],
      customisedSkeletons: [],
      selectedSkeletons: [],
      stepperStep: 1,
      contract: {
        name: "",
        invoiceNumber: "",
        effectiveDate: "",
        expiryDate: "",
        hasBreakClause: false,
        breakClauseInDaysFromEffectiveDate: 0,
        price: 0,
        hasAlerts: false,
        alertOffsetInDays: 0,
        alertGroup: "",
        products: [],
        parentCompanyId: this.companyId,
      },
    };
  },
};
</script>

<style>
</style>