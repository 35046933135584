<template>
  <div>
    <trev-product-selection
      :companyId="companyId"
      @selected-product="selectedProducts"
      selectMultiple
      :defaultSelectedProducts="selectedProductsFromList"
      :showExistingProducts="true"
    >
    </trev-product-selection>
  </div>
</template>

<script>
import trevProductSelection from "../../products/trev-product-selection.vue";
export default {
  components: { trevProductSelection },
  props: {
    companyId: {
      type: String,
      default: "",
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    products(){
      this.selectedProductsFromList = this.products;
    }
  },
  methods: {
    selectedProducts(selectedProds) {
      this.selectedProductsFromList = selectedProds;
      this.selectedProductsFromList.forEach(x => {
        x.isCustomised = false;
      });
      this.$emit('update:products', this.selectedProductsFromList);
      this.$emit("selected-products", this.selectedProductsFromList);
    },
  },
  data() {
    return {
      selectedProductsFromList: this.products,
    };
  },
};
</script>

<style>
</style>