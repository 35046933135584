var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trev-cool-table',{attrs:{"title":"Product Skeletons","courier":_vm.courier,"headers":_vm.tableHeaders,"tableId":_vm.uniqueTableId,"addNewLink":_vm.companyId ? null : { name: 'Add Product Skeleton' },"showAddLinkAsButton":(_vm.companyId ? true : false) && _vm.$store.getters.hasScope('ProductSkeleton.Create'),"additionalQueryStrings":_vm.getQueryStrings,"isExpandable":true,"tableKey":"id"},on:{"add-clicked":function($event){return _vm.$emit('add-product-skeleton')},"loaded":_vm.getLinked},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(_vm.hasChildProducts(item))?_c('td',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" View Linked Products ")])])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"pt-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" SKU ")]),_c('th',[_vm._v(" Division ")]),_c('th',[_vm._v(" Name ")]),_c('th',[_vm._v(" Unit Cost ")])])]),_c('tbody',_vm._l((_vm.linkedSkelly.filter(function (x) { return x.parentProductSkeletonId == item.id; })),function(linkedProduct){return _c('tr',{key:linkedProduct.id},[_c('td',[_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" SKU:"+_vm._s(linkedProduct.skuNumber)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(linkedProduct.division)+" ")]),_c('td',[_vm._v(" "+_vm._s(linkedProduct.name)+" ")]),_c('td',[_vm._v(" £"+_vm._s(linkedProduct.price)+" ")])])}),0)]},proxy:true}],null,true)})],1)],1)],1)],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.price)+" "),(item.hasRules)?_c('v-icon',{staticClass:"pb-1 warning--text"},[_vm._v(" mdi-exclamation ")]):_vm._e()]}},{key:"item.alertGroup",fn:function(ref){
var item = ref.item;
return [_vm._v(" Alert "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.alertGroup)+" ")]),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.alertOffsetInDays))]),_vm._v(" Days Before expiry ")]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":(_vm.getLinkedCount(item) === 0)},on:{"click":function($event){return _vm.showLinks(item)}}},[_vm._v(" "+_vm._s(_vm.getLinkedCount(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v(" Options "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
            name: 'Product Skeleton View',
            params: { productSkeletonId: item.id },
          }}},[_c('v-list-item-title',{staticClass:"primary--text"},[_c('v-icon',{staticClass:"mr-2 primary--text"},[_vm._v(" mdi-magnify ")]),_vm._v(" View Product Skeleton ")],1)],1),(_vm.$store.getters.hasScope('ProductSkeleton.Modify'))?_c('v-list-item',{attrs:{"to":{
            name: 'Product Skeleton Edit',
            params: {
              productSkeletonId: item.id
            }
          }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Product Skeleton ")],1)],1):_vm._e(),(_vm.$store.getters.hasScope('ProductSkeleton.Modify'))?_c('v-list-item',{attrs:{"to":{
            name: 'Edit Global Product Skeleton',
            params: {
              productSkeletonId: item.id
            }
          }}},[_c('v-list-item-title',{staticClass:"warning--text"},[_c('v-icon',{staticClass:"mr-2 warning--text"},[_vm._v("mdi-earth-arrow-right")]),_vm._v(" Edit Across Products ")],1)],1):_vm._e(),(_vm.$store.getters.hasScope('ProductSkeleton.Delete'))?_c('v-list-item',{on:{"click":function($event){_vm.showConfirmDelete = true;
          _vm.selectedProd = item;}}},[_c('v-list-item-title',{staticClass:"danger--text"},[_c('v-icon',{staticClass:"mr-2 danger--text"},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1):_vm._e()],1)],1)]}},(!_vm.companyId)?{key:"item.company",fn:function(ref){
          var item = ref.item;
return [(item.parentCompanyId)?_c('v-chip',[_vm._v(" Custom to Company ")]):_c('v-chip',[_vm._v(" Global ")])]}}:null,{key:"item.skuNumber",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"info"}},[_vm._v(" SKU:"+_vm._s(item.skuNumber)+" ")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.showConfirmDelete),callback:function ($$v) {_vm.showConfirmDelete=$$v},expression:"showConfirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.selectedProd.name)+"? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v(" No, Keep "+_vm._s(_vm.selectedProd.name)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"danger"},on:{"click":_vm.deleteProductSkeleton}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete ")],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"60vh"},model:{value:(_vm.showingLinked),callback:function ($$v) {_vm.showingLinked=$$v},expression:"showingLinked"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(" Linked with "+_vm._s(_vm.selectedSkeleton.skuNumber)+" ")])],1),_c('v-list',_vm._l((_vm.getLinkedProductSkeletons(_vm.selectedSkeleton)),function(skelly){return _c('v-list-item',{key:skelly.id},[_c('v-list-item-title',[_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(skelly.skuNumber)+" ")]),_vm._v(" - "+_vm._s(skelly.name)+" ")],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }