<template>
  <div>
    <trev-cool-table
      :courier="$courier.Companies.withId(companyId).ChargeItems"
      :headers="headers"
      :dateStart="minDate"
      :dateEnd="maxDate"
    >
      <template slot="title">
        Charge Items
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="changeMonthFilter('this')">
                <v-list-item-title> This Month </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeMonthFilter('last')">
                <v-list-item-title> Last Month </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeMonthFilter('custom')">
                <v-list-item-title> Custom </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <span class="mr-1 primary--text capWord"> {{ monthFilter }}</span>
        <span class="primary--text mr-1 capWord" v-if="monthFilter != 'custom'"
          >month</span
        >
        <span v-else class="primary--text">
          Dates from
          <span v-if="customDates.length >= 2">
            <span class="success--text">{{
              startDate.toLocaleDateString("en-GB")
            }}</span>
            to
            <span class="success--text">{{
              endDate.toLocaleDateString("en-GB")
            }}</span>
          </span>
        </span>
      </template>
      <template v-slot:item.retailPrice="{ item }">
        £{{ item.retailPrice }}
      </template>
      <template v-slot:item.costPrice="{ item }">
        £{{ item.costPrice }}
      </template>
      <template v-slot:item.quantity="{item}">
          <v-chip>
              {{item.quantity}}
          </v-chip>
      </template>
      <template v-slot:item.dates="{item}">
          {{ new Date(item.startDate).toLocaleDateString('en-GB') }} - {{ new Date(item.endDate).toLocaleDateString('en-GB') }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="primary"
          @click="
            selectedItem = item;
            showDetail = true;
          "
        >
          <v-icon> mdi-magnify </v-icon>
          View
        </v-btn>
      </template>
    </trev-cool-table>
    <v-dialog v-model="showDetail" max-width="500">
      <v-card>
        <v-card-title>
          <v-icon> mdi-currency-gbp </v-icon>
          Charge Item Details
        </v-card-title>
        <v-list>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> SKU </v-list-item-subtitle>
              <v-list-item-title>
                {{ selectedItem.sku }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Start Date </v-list-item-subtitle>
              <v-list-item-title>
                {{ new Date(selectedItem.startDate).toLocaleDateString('en-GB') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> End Date </v-list-item-subtitle>
              <v-list-item-title>
                {{ new Date(selectedItem.endDate).toLocaleDateString('en-GB') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Name </v-list-item-subtitle>
              <v-list-item-title>
                {{ selectedItem.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Description </v-list-item-subtitle>
              <v-list-item-title style="white-space: pre-line">
                {{ selectedItem.description }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Quantity </v-list-item-subtitle>
              <v-list-item-title>
                {{ selectedItem.quantity }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Cost Price </v-list-item-subtitle>
              <v-list-item-title>
                £{{ selectedItem.costPrice }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Retail Price </v-list-item-subtitle>
              <v-list-item-title>
                £{{ selectedItem.retailPrice }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCustomMonthDialog" max-width="340">
      <v-card>
        <v-card-title> Select Dates </v-card-title>

        <v-card-title v-if="customDates.length >= 2">
          {{ startDate.toLocaleDateString("en-GB") }}
        </v-card-title>
        <v-card-subtitle v-if="customDates.length >= 2"> From </v-card-subtitle>
        <v-card-title v-if="customDates.length >= 2">
          {{ endDate.toLocaleDateString("en-GB") }}
        </v-card-title>
        <v-card-subtitle v-if="customDates.length >= 2"> To </v-card-subtitle>
        <v-card-text>
          <v-date-picker v-model="customDates" range> </v-date-picker>
        </v-card-text>
        <v-card-actions v-if="customDates.length >= 2">
          <v-btn color="success" @click="saveDate">
            <v-icon> mdi-save </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../tables/trev-cool-table.vue";
import * as genUtilities from "../../utilities/GeneralUtilities";
export default {
  components: { trevCoolTable },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    startDate() {
      if (this.customDates && this.customDates.length >= 2) {
        var min = genUtilities.getMinDateFromArray(this.customDates);
        return min;
      } else {
        return "";
      }
    },
    endDate() {
      if (this.customDates && this.customDates.length >= 2) {
        var max = genUtilities.getMaxDateFromArray(this.customDates);
        return max;
      } else {
        return "";
      }
    },
  },
  mounted() {},
  methods: {
    saveDate() {
      this.savedCustomDates = JSON.parse(JSON.stringify(this.customDates));

      var minDateFromSaved = genUtilities.getMinDateFromArray(
        this.savedCustomDates
      );
      var maxDateFromSaved = genUtilities.getMaxDateFromArray(
        this.savedCustomDates
      );

      this.$emit("datechange", [minDateFromSaved, maxDateFromSaved]);

      this.minDate = minDateFromSaved.toISOString();
      this.maxDate = maxDateFromSaved.toISOString();

      this.showCustomMonthDialog = false;
    },
    changeMonthFilter(monthType) {
      this.monthFilter = monthType;
      var today = new Date();

      if (this.monthFilter == "this") {
        this.minDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ).toISOString();
        this.maxDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).toISOString();
        this.$emit("datechange", "this");
      } else if (this.monthFilter == "last") {
        this.maxDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).toISOString();
        this.minDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        ).toISOString();
        this.$emit("datechange", "last");
      } else if (this.monthFilter == "custom") {
        this.showCustomMonthDialog = true;
      }
    },
  },
  data() {
    return {
      savedCustomDates: [],
      minDate: "",
      maxDate: "",
      monthFilter: "this",
      customDates: [],
      selectedItem: {},
      showCustomMonthDialog: false,
      showDetail: false,
      internalCourier: {},
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
            text: 'Dates',
            value: 'dates'
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Cost Price",
          value: "costPrice",
        },
        {
          text: "Retail Price",
          value: "retailPrice",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style scoped>
 .capWord{
     text-transform: capitalize;
 }
</style>