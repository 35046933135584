<template>
  <div>
    <v-row v-if="$store.getters.hasScope('Ticket.Read')">
      <v-col class="pb-0 mb-0">
        <v-card class="ma-0 mr-4">
          <v-card-text>
            <p class="ma-0 text--disabled">Tickets</p>
            <h4>
              <trev-number-ticker :number="ticketCountThisMonth"></trev-number-ticker>
            </h4>
            <div class="d-flex justify-space-between flex-wrap align-center">
              <div class="d-flex align-center">
                <div :class="
                  'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (getPercentageIncreaseTicketCountThisMonth.includes('-')
                    ? ' danger '
                    : ' success ')
                " style="height: 24px; width: 24px">
                  <v-icon :class="
                    'caption' +
                    (getPercentageIncreaseTicketCountThisMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')
                  ">
                    mdi-call-made
                  </v-icon>
                </div>
                <span :class="
                  getPercentageIncreaseTicketCountThisMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'
                ">{{ getPercentageIncreaseTicketCountThisMonth }}%</span>
              </div>
              <div>
                <p class="ma-0">This Month</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="ma-0 mr-4">
          <v-card-text>
            <p class="ma-0 text--disabled">Tickets</p>
            <h4>
              <trev-number-ticker :number="ticketCountLastMonth"></trev-number-ticker>
            </h4>
            <div class="d-flex justify-space-between flex-wrap align-center">
              <div class="d-flex align-center">
                <div :class="
                  'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (getPercentageIncreaseTicketCountLastMonth.includes('-')
                    ? ' danger '
                    : ' success ')
                " style="height: 24px; width: 24px">
                  <v-icon :class="
                    'caption' +
                    (getPercentageIncreaseTicketCountLastMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')
                  ">
                    mdi-call-made
                  </v-icon>
                </div>
                <span :class="
                  getPercentageIncreaseTicketCountLastMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'
                ">{{ getPercentageIncreaseTicketCountLastMonth }}%</span>
              </div>
              <div>
                <p class="ma-0">Last Month</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="ma-0 mr-4">
          <v-card-text>
            <p class="ma-0 text--disabled">Minutes Used</p>
            <h4>
              <trev-number-ticker :number="timeUsedThisMonth"></trev-number-ticker>
            </h4>
            <div class="d-flex justify-space-between flex-wrap align-center">
              <div class="d-flex align-center">
                <div :class="
                  'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                    ? ' danger '
                    : ' success ')
                " style="height: 24px; width: 24px">
                  <v-icon :class="
                    'caption' +
                    (getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')
                  ">
                    mdi-call-made
                  </v-icon>
                </div>
                <span :class="
                  getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'
                ">{{ getPercentageIncreaseMinutesUsedThisMonth }}%</span>
              </div>
              <div>
                <p class="ma-0">This Month</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="ma-0">
          <v-card-text>
            <p class="ma-0 text--disabled">Minutes Used</p>
            <h4>
              <trev-number-ticker :number="timeUsedLastMonth"></trev-number-ticker>
            </h4>
            <div class="d-flex justify-space-between flex-wrap align-center">
              <div class="d-flex align-center">
                <div :class="
                  'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                    ? ' danger '
                    : ' success ')
                " style="height: 24px; width: 24px">
                  <v-icon :class="
                    'caption' +
                    (getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')
                  ">
                    mdi-call-made
                  </v-icon>
                </div>
                <span :class="
                  getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'
                ">{{ getPercentageIncreaseMinutesUsedLastMonth }}%</span>
              </div>
              <div>
                <p class="ma-0">Last Month</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-for="(productUsage, index) in productTimesThisMonth" :key="index">
        <v-card class="ma-0 ml-4">
          <v-card-text>
            <p class="ma-0 text--disabled">
              Minutes from {{ productUsage.productSku }}
            </p>
            <h4>
              <trev-number-ticker :number="productUsage.timeUsedInProduct" class="mr-1"></trev-number-ticker>
              <span v-if="productUsage.productTimeTotal < 2147483647">
                /
                <trev-number-ticker :number="productUsage.productTimeTotal" class="ml-1"></trev-number-ticker>
              </span>
              <span v-else>
                /
                <v-icon> mdi-infinity </v-icon>
              </span>
            </h4>
            <div class="d-flex justify-space-between flex-wrap align-center">
              <div class="d-flex align-center">
                <div :class="
                  'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (getProductTimePercentageIncreaseThisMonth(
                    productUsage.productSku
                  ).includes('-')
                    ? ' danger '
                    : ' success ')
                " style="height: 24px; width: 24px">
                  <v-icon :class="
                    'caption' +
                    (getProductTimePercentageIncreaseThisMonth(
                      productUsage.productSku
                    ).includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')
                  ">
                    mdi-call-made
                  </v-icon>
                </div>
                <span :class="
                  getProductTimePercentageIncreaseThisMonth(
                    productUsage.productSku
                  ).includes('-')
                    ? 'red--text'
                    : 'success--text'
                ">{{
  getProductTimePercentageIncreaseThisMonth(
    productUsage.productSku
                  )
}}%</span>
              </div>
              <div>
                <p class="ma-0">This Month</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="ninjaLinks.length > 0 && $store.getters.hasScope('Ninja.Read')">
      <v-col class="pt-0 pb-0">
        <trev-company-ninja-devices-dash :companyId="$route.params.companyId">
        </trev-company-ninja-devices-dash>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <trev-cool-list v-if="$store.getters.hasScope('Contact.Read')" :items="contacts" :pageSize="10" :hideTitle="true" :isPageBtnSmall="true" :isDense="true">
          <template v-slot:repeaterItem="{ item }">
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <v-btn text small @click="showContactDetails(item)">
                  <v-icon class="mr-2"> mdi-account-circle-outline </v-icon>
                  <!-- {{
                    (item.firstName && item.firstName != "null"
                      ? item.firstName + "."
                      : "") +
                    " " +
                    (item.lastName && item.lastName != "null"
                      ? item.lastName[0]
                      : "")
                  }} -->
                  {{ item.firstName + ' ' + item.lastName }}
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" small color="amber lighten-1" class="my-0 mr-2"
                      v-show="item.isVIP"><v-icon small>mdi-crown-circle-outline</v-icon></v-chip>
                  </template>
                  <span> VIP </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" small color="green accent-3" class="ma-0 mr-2"
                      v-show="item.isApprover"><v-icon small>mdi-shield-check-outline</v-icon></v-chip>
                  </template>
                  <span> Approver </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" small color="blue-grey lighten-3" class="ma-0 mr-2"
                      v-show="item.isServiceNews"><v-icon small>mdi-newspaper-variant-outline</v-icon></v-chip>
                  </template>
                  <span> Service News </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" small color="purple accent-3" class="ma-0 mr-2"
                      v-show="item.isBilling"><v-icon small>mdi-cash-multiple</v-icon></v-chip>
                  </template>
                  <span>
                    Billing
                  </span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </trev-cool-list>
      </v-col>
      <v-col cols="8">
        <trev-cool-table v-if="$store.getters.hasScope('Job.Read') && openJobCount > 0" class="mb-4" :hideTitle="true" :classes="'pt-0 pr-0 pl-0 pb-0'"
          :cardTextClasses="'pt-0 pb-0 pr-0 pl-0'" :courier="$courier.Companies.withId(companyId).Jobs"
          :additionalQueryStrings="[{ key: 'statusFilter', value: 'Open' }]" :isDense="true" :headers="[
            { text: 'Po Number', value: 'poNumber' },
            { text: 'Title', value: 'title' },
            { text: 'Price', value: 'price' },
            { text: 'View', value: 'view' }
          ]">
          <template v-slot:item.poNumber="{ item }">
            #{{ item.poNumber }}
          </template>
          <template v-slot:item.price="{ item }">
            £{{ item.price }}
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn x-small :to="{
              name: 'Job View',
              params: {
                jobId: item.id
              }
            }">
              <v-icon small class="mr-2">mdi-magnify</v-icon>
              View
            </v-btn>
          </template>
        </trev-cool-table>
        <trev-cool-table-base v-if="$store.getters.hasScope('Product.Read')" :isDense="true" classes="mb-4" :cardTextClasses="'pt-0 pr-0 pl-0 pb-0'"
          :items="getProductsPaginated" :defaultPageSize="productPagination.pageSize" :isLoading="isLoadingProducts"
          :totalRecordCount="products.length" @pagination-updated="productPaginationUpdated" :headers="[
            {
              text: 'Sku',
              value: 'skuNumber',
            },
            {
              text: 'Name',
              value: 'name',
            },
            {
              text: 'Quantity',
              value: 'quantity',
            },
          ]" :hideTitle="true">
          <template v-slot:item.skuNumber="{ item }">
            <v-chip small label>
              {{ item.skuNumber }}
            </v-chip>
          </template>
          <template v-slot:item.quantity="{ item }">
            {{ item.quantity }}
            <small v-if="item.contractQuantity > 0" class="grey--text lightne-2">
              + {{ item.contractQuantity }} (In Contracts)
            </small>
          </template>
        </trev-cool-table-base>
        <trev-cool-table ref="ticketTable" v-if="$store.getters.hasScope('Ticket.Read')" :courier="
          $courier.Companies.withId($route.params.companyId).ZohoTickets
        " :isDense="true" :additionalQueryStrings="[{ key: 'status', value: 'Open,On Hold' }]"
          :dateStart="startDate.toISOString()" :cardTextClasses="'pt-0 pr-0 pl-0 pb-0'" :dateEnd="endDate.toISOString()"
          :headers="[
            { text: '#', value: 'ticketNumber' },
            { text: 'Status', value: 'status' },
            { text: 'Added At', value: 'dateAdded' },
            { text: 'Subject', value: 'subject' },
            { text: '', value: 'actions' },
          ]" :defaultPageSize="10" :hideTitle="true">
          <template v-slot:item.dateAdded="{ item }">
            {{ new Date(item.dateAdded).toLocaleString() }}
          </template>
          <template v-slot:item.subject="{ item }">
            <a :href="item.ticketLink" target="_blank">
              {{ item.subject }}
            </a>
          </template>
        </trev-cool-table>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowingContact" max-width="60vh">
      <trev-contact-view-card :contact="contactShowing"></trev-contact-view-card>
    </v-dialog>
  </div>
</template>

<script>
import * as utilities from "../../../../../utilities/GeneralUtilities";
import TrevCoolList from "../../../../../components/tables/trev-cool-list.vue";
import trevCoolTable from "../../../../../components/tables/trev-cool-table.vue";
import TrevNumberTicker from "../../../../../components/ui/trev-number-ticker.vue";
import TrevContactViewCard from "../../../../../components/contacts/trev-contact-view-card.vue";
import TrevCompanyNinjaDevicesDash from "./trev-company-ninja-devices-dash.vue";
import TrevCoolTableBase from "../../../../../components/tables/trev-cool-table-base.vue";
export default {
  components: {
    trevCoolTable,
    TrevCoolList,
    TrevNumberTicker,
    TrevContactViewCard,
    TrevCompanyNinjaDevicesDash,
    TrevCoolTableBase,
  },
  props: {
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    endDate: {
      type: Date,
      default: () => new Date(),
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    getProductsPaginated() {
      if (this.products && this.products.length > 0) {
        var prods = utilities.paginate(
          this.products,
          this.productPagination.pageSize,
          this.productPagination.pageNumber
        );

        return prods;
      } else {
        return [];
      }
    },
    getPercentageIncreaseTicketCountThisMonth() {
      var r = Math.round(
        utilities.getPercentageIncrease(
          this.ticketCountLastMonth,
          this.ticketCountThisMonth
        )
      ).toString();

      if (r == "NaN") {
        r = " ?? ";
      }

      return r;
    },
    getPercentageIncreaseTicketCountLastMonth() {
      var r = Math.round(
        utilities.getPercentageIncrease(
          this.ticketCount2MonthsAgo,
          this.ticketCountLastMonth
        )
      ).toString();

      if (r == "NaN") {
        r = " ?? ";
      }

      return r;
    },
    getPercentageIncreaseMinutesUsedThisMonth() {
      var r = Math.round(
        utilities.getPercentageIncrease(
          this.timeUsedLastMonth,
          this.timeUsedThisMonth
        )
      ).toString();

      if (r == "NaN") {
        r = " ?? ";
      }

      return r;
    },
    getPercentageIncreaseMinutesUsedLastMonth() {
      var r = Math.round(
        utilities.getPercentageIncrease(
          this.timeUsed2monthsAgo,
          this.timeUsedLastMonth
        )
      ).toString();

      if (r == "NaN") {
        r = " ?? ";
      }

      return r;
    },
    startOfLastMonth() {
      let today = new Date();
      return utilities.getMinMaxDatesByDate(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDay())
      ).startDate;
    },
    endOfLastMonth() {
      let today = new Date();
      return utilities.getMinMaxDatesByDate(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDay())
      ).endDate;
    },
  },
  created() {
    this.$courier.Companies.withId(this.companyId).Jobs.getData(1, 5000, '', null, null, [{ key: 'statusFilter', value: 'Open' }]).then(resp => {
      if (resp && resp.payload && resp.payload.length > 0) {
        this.openJobCount = resp.payload.length;
      }
    })

    this.$courier.Companies.withId(this.companyId)
      .GetTimeUsedByProducts(this.startDate, this.endDate)
      .then((x) => {
        this.productTimesThisMonth = x;
      });

    this.$courier.Companies.withId(this.companyId)
      .GetTimeUsedByProducts(this.startOfLastMonth, this.endOfLastMonth)
      .then((x) => {
        this.productTimesLastMonth = x;
      });

    this.$courier.Companies.withId(this.companyId)
      .GetTicketTimeUsed(
        this.startDate.toISOString(),
        this.endDate.toISOString(),
        []
      )
      .then((resp) => {
        this.timeUsedThisMonth = resp;
      });

    this.$courier.Companies.withId(this.companyId)
      .GetTicketTimeUsed(
        this.startOfLastMonth.toISOString(),
        this.endOfLastMonth.toISOString(),
        []
      )
      .then((resp) => {
        this.timeUsedLastMonth = resp;
      });

    var today = new Date();
    var minMaxDateThisMonth = utilities.getMinMaxDatesByDate(today);

    this.$courier.Companies.withId(this.companyId)
      .ZohoTickets.getData(
        1,
        1,
        "",
        minMaxDateThisMonth.startDate,
        minMaxDateThisMonth.endDate
      )
      .then((x) => {
        this.ticketCountThisMonth = x.totalCount;
      });

    var minMaxDateLastMonth = utilities.getMinMaxDatesByDate(
      new Date(today.getFullYear(), today.getMonth() - 1, 5)
    );
    this.$courier.Companies.withId(this.companyId)
      .ZohoTickets.getData(
        1,
        1,
        "",
        minMaxDateLastMonth.startDate,
        minMaxDateLastMonth.endDate
      )
      .then((x) => {
        this.ticketCountLastMonth = x.totalCount;
      });

    var minMaxDate2MonthsAgo = utilities.getMinMaxDatesByDate(
      new Date(today.getFullYear(), today.getMonth() - 2, 5)
    );

    this.$courier.Companies.withId(this.companyId)
      .ZohoTickets.getData(
        1,
        1,
        "",
        minMaxDate2MonthsAgo.startDate,
        minMaxDate2MonthsAgo.endDate
      )
      .then((x) => {
        this.ticketCount2MonthsAgo = x.totalCount;
      });

    this.$courier.Companies.withId(this.companyId)
      .GetTicketTimeUsed(
        minMaxDate2MonthsAgo.startDate.toUTCString(),
        minMaxDate2MonthsAgo.endDate.toUTCString(),
        []
      )
      .then((resp) => {
        this.timeUsed2monthsAgo = resp;
      });

    this.$courier.Companies.withId(this.companyId)
      .Contacts.getData(1, 500, "")
      .then((x) => {
        this.contacts = x.payload.map((x) => {
          x.isVIP = false;
          x.isApprover = false;
          x.isServiceNews = false;
          x.isBilling = false;
          x.isLoading = true;
          return x;
        });

        //now get the tags

        this.contacts.forEach((contact) => {
          this.$courier.Contacts.withId(contact.id)
            .Tags.getData(1, 999, "")
            .then((tags) => {
              contact.isVIP = tags.payload.some(
                (x) => x.active && x.tag === "VIP"
              );
              contact.isApprover = tags.payload.some(
                (x) => x.active && x.tag === "Approver"
              );
              contact.isServiceNews = tags.payload.some(
                (x) => x.active && x.tag === "Service News"
              );
              contact.isBilling = tags.payload.some(
                (x) => x.active && x.tag === "Billing"
              );
              contact.isLoading = false;
            });
        });
      });

    this.$courier.Companies.withId(this.companyId)
      .NinjaLinks.getData(1, 500, "")
      .then((links) => {
        this.ninjaLinks = links.payload;
      });

    this.getProducts();
  },
  methods: {
    productPaginationUpdated(pageObj) {
      this.productPagination.pageNumber = pageObj.pageNumber;
      this.productPagination.pageSize = pageObj.pageSize;
      this.productPagination.search = pageObj.search;
    },
    async getProducts() {
      var resp = await this.$courier.Companies.withId(
        this.companyId
      ).Products.getData(1, 9999, "");

      if (resp && resp.payload && resp.payload.length > 0) {
        for (const prod of resp.payload) {
          this.products.push(prod);
        }
      }

      //get the contracts in the company and then the products - add em and merge

      var contractResp = await this.$courier.Companies.withId(
        this.companyId
      ).Contracts.getData(1, 99999, "");

      if (
        contractResp &&
        contractResp.payload &&
        contractResp.payload.length > 0
      ) {
        var allPromises = [];

        for (const contract of contractResp.payload) {
          var promise = this.$courier.Contracts.withId(contract.id)
            .Products.getData(1, 99999, "")
            .then((prodResp) => {
              if (prodResp && prodResp.payload && prodResp.payload.length > 0) {
                for (const prod of prodResp.payload) {
                  if (
                    this.products.some((x) => x.skuNumber == prod.skuNumber)
                  ) {
                    //find the product, add a property of contract quantity and add to it this prod quantity

                    var foundProd = this.products.filter(
                      (x) => x.skuNumber == prod.skuNumber
                    )[0];
                    if (!foundProd.contractQuantity) {
                      foundProd.contractQuantity = 0;
                    }

                    foundProd.contractQuantity += prod.quantity;
                  } else {
                    this.products.push(prod);
                  }
                }
              }
            });

          allPromises.push(promise);
        }

        Promise.all(allPromises).then(() => {
          this.isLoadingProducts = false;
        });
      } else {
        this.isLoadingProducts = false;
      }
    },
    showContactDetails(item) {
      this.isShowingContact = true;
      this.contactShowing = item;
    },
    getContactLastName(contact) {
      var lastname = "";

      if (contact.lastName && contact.lastName != "null") {
        lastname = contact.lastName;
      }

      if (contact.lastName.includes(".") && lastname == "") {
        lastname = contact.lastName.toString().split(".")[1];
      }
    },
    getContactFirstName(contact) {
      var firstname = "";

      if (contact.firstName && contact.firstName != "null") {
        firstname = contact.firstName;
      }

      if (contact.lastName.includes(".") && firstname == "") {
        firstname = contact.lastName.toString().split(".")[0];
      }

      return firstname;
    },
    getProductTimePercentageIncreaseThisMonth(productSku) {
      var productThisMonth = this.productTimesThisMonth.filter(
        (x) => x.productSku == productSku
      )[0];
      var productLastMonth = this.productTimesLastMonth.filter(
        (x) => x.productSku == productSku
      )[0];

      if (!productThisMonth || !productLastMonth) {
        return " ?? ";
      }

      var r = Math.round(
        utilities.getPercentageIncrease(
          productLastMonth.timeUsedInProduct,
          productThisMonth.timeUsedInProduct
        )
      ).toString();

      if (r == "NaN") {
        r = " ?? ";
      }

      return r;
    },
  },
  data() {
    return {
      openJobCount: 0,
      contactShowing: {},
      isShowingContact: false,
      ticketCountThisMonth: 0,
      ticketCountLastMonth: 0,
      timeUsedThisMonth: 0,
      timeUsedLastMonth: 0,
      productTimesThisMonth: [],
      productTimesLastMonth: [],
      ticketCount2MonthsAgo: 0,
      timeUsed2monthsAgo: 0,
      contacts: [],
      ninjaLinks: [],
      products: [],
      isLoadingProducts: true,
      productPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
    };
  },
};
</script>

<style scoped>
.rotate90 {
  transform: rotate(90deg);
}
</style>