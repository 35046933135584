var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trev-cool-table',{attrs:{"headers":_vm.headers,"courier":_vm.$courier.Companies.withId(_vm.companyId).Contacts,"title":"Contacts","tableId":_vm.uniqueTableId,"showAddLinkAsButton":true},on:{"add-clicked":function($event){return _vm.$emit('add-contact')}},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags.filter(function (x) { return x.active; })),function(tag){return _c('span',{key:tag.id,staticClass:"mr-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(tag.tag === 'VIP')?_c('v-chip',_vm._g(_vm._b({attrs:{"color":"amber lighten-1","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-crown-circle-outline ")])],1):_vm._e(),(tag.tag === 'Approver')?_c('v-chip',_vm._g(_vm._b({attrs:{"color":"success","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-shield-check-outline ")])],1):(tag.tag === 'Service News')?_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","color":"blue-grey lighten-3"}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-newspaper-variant-outline")])],1):(tag.tag === 'Billing')?_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","color":"purple accent-3"}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cash-multiple")])],1):(tag.tag === 'Support User')?_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","color":"orange accent-3"}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-face-agent")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tag.tag)+" ")])])],1)})}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.firstName ? item.firstName : "") + " " + (item.lastName ? item.lastName : ""))+" ")]}},{key:"item.emailAddress",fn:function(ref){
var item = ref.item;
return [(item.emailAddress)?_c('v-btn',{staticClass:"info--text",attrs:{"text":"","color":"lighten-5 info","href":'mailto:' + item.emailAddress}},[(item.emailAddress)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-email-outline ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.shortenEmail(item.emailAddress))+" ")],1):_vm._e()]}},{key:"item.mobileNumber",fn:function(ref){
var item = ref.item;
return [(item.mobileNumber)?_c('v-btn',{staticClass:"info--text",attrs:{"text":"","color":"lighten-5 info","href":'tel:' + item.mobileNumber}},[(item.mobileNumber)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cellphone ")]):_vm._e(),_vm._v(" "+_vm._s(item.mobileNumber)+" ")],1):_vm._e()]}},{key:"item.landLine",fn:function(ref){
var item = ref.item;
return [(item.landLine)?_c('v-btn',{staticClass:"info--text",attrs:{"text":"","color":"lighten-5 info","href":'tel:' + item.landLine}},[(item.landLine)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-phone ")]):_vm._e(),_vm._v(" "+_vm._s(item.landLine)+" ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","to":{ name: 'Contact View', params: { contactId: item.id } }}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" View ")],1),(_vm.$store.getters.hasScope('Contact.Delete'))?_c('v-btn',{staticClass:"white--text",attrs:{"color":"danger","small":""},on:{"click":function($event){_vm.showConfirmDelete = true;
          _vm.contactSelected = {
            contactId: item.id,
            contactName: item.firstName,
          };}}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete ")],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showConfirmDelete),callback:function ($$v) {_vm.showConfirmDelete=$$v},expression:"showConfirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.contactSelected.contactName)+"? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v(" No, Keep "+_vm._s(_vm.contactSelected.contactName)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"danger"},on:{"click":_vm.deleteContact}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")]),_vm._v(" Delete ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }