<template>
  <v-container fluid class="mt-0 mb-0 ml-0 mr-0 pl-0 pt-0 pb-0 pr-0">
    <v-row v-if="!isLoaded">
      <v-col>
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-for="gDevice in groupedDevices" :key="gDevice.name">
        <v-card class="ma-0">
          <v-card-text>
            <p class="ma-0 text--disabled capEachWord">
              {{
                getNodeNameNice(gDevice.name) +
                (gDevice.devices.length == 1 ? "" : "s")
              }}
            </p>
            <v-row>
              <v-col>
                <v-btn @click="showDevicesInGroup(gDevice, true)" :plain="gDevice.devices.filter(x => x.offline == false).length == 0" :class="gDevice.devices.filter(x => x.offline == false).length == 0 ? 'disabledButton' : ''">
                  <span
                    class="
                      mr-2
                      align-center
                      justify-center
                      rounded-circle
                      v-sheet
                      lighten-5
                    "
                    style="height: 24px; width: 24px"
                  >
                    <v-icon class="success--text"> mdi-circle </v-icon>
                  </span>
                  <span
                    >{{
                      gDevice.devices.filter((x) => x.offline == false).length
                    }}
                    Online</span
                  >
                </v-btn>
              </v-col>
              <v-col>
                <v-btn @click="showDevicesInGroup(gDevice, false)" :plain="gDevice.devices.filter(x => x.offline == true).length == 0" :class="{ disabledButton: gDevice.devices.filter(x => x.offline == true).length == 0}">
                  <span
                    class="
                      mr-2
                      align-center
                      justify-center
                      rounded-circle
                      v-sheet
                      lighten-5
                    "
                    style="height: 24px; width: 24px"
                  >
                    <v-icon class="danger--text"> mdi-circle-outline </v-icon>
                  </span>
                  <span
                    >{{
                      gDevice.devices.filter((x) => x.offline).length
                    }}
                    Offline</span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog max-width="60vh" v-model="showingDetails">
      <v-card v-if="selectedDeviceGroup.name">
        <v-card-title>
          All
          <span class="success--text ml-2" v-if="selectedOnlineStatus">
            Online:
          </span>
          <span class="danger--text ml-2" v-else> Offline: </span>
          <span class="info--text ml-2 capEachWord">
            {{ getNodeNameNice(selectedDeviceGroup.name) + "s" }}
          </span>
        </v-card-title>
        <v-list>
          <v-list-item
            v-for="device in selectedDeviceGroup.devices"
            :key="device.id"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ device.systemName }}
                <v-btn icon class="ml-2" target="_blank" :href="'https://eu.ninjarmm.com/#/deviceDashboard/'+ device.id +'/overview'">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ device.dnsName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    groupedDevices() {
      var groupedDevices = [];

      this.devices.forEach((device) => {
        var foundSubGroup = groupedDevices.find(
          (x) => x.name == device.nodeClass
        );

        if (foundSubGroup) {
          foundSubGroup.devices.push(device);
        } else {
          groupedDevices.push({
            name: device.nodeClass,
            devices: [device],
          });
        }
      });

      return groupedDevices;
    },
  },
  mounted() {
    this.getCompanyLinks().then(() => {
      this.getCompanyDevices();
    });
  },
  methods: {
    showDevicesInGroup(deviceGroup, onlineStatus) {
      this.selectedDeviceGroup = deviceGroup;
      this.selectedDeviceGroup.devices = deviceGroup.devices.filter(
        (x) => x.offline != onlineStatus
      );
      this.showingDetails = true;
      this.selectedOnlineStatus = onlineStatus;
    },
    async getCompanyLinks() {
      return this.$courier.Companies.withId(this.companyId)
        .NinjaLinks.getData(1, 100, "")
        .then((links) => {
          this.ninjaLinks = links.payload;
        });
    },
    async getCompanyDevices() {
      this.ninjaLinks.forEach((ninjaLink) => {
        this.$courier.RMM.Organisations.withId(ninjaLink.ninjaId)
          .Devices.getData(1, 1000)
          .then((devices) => {
            if (devices) {
              devices.forEach((d) => {
                this.devices.push(d);
              });

              this.isLoaded = true;
            }
          });
      });
    },
    getNodeNameNice(nodeName) {
      if (!nodeName) {
        return "";
      }

      var name = nodeName.replaceAll("_", " ");
      name = name.toString().toLowerCase();
      var splitNames = name.split(" ");

      splitNames.forEach((x) => {
        if (x == splitNames[0]) {
          name = x;
        } else {
          name += " " + x;
        }
      });

      return name;
    },
  },
  data() {
    return {
      ninjaLinks: [],
      devices: [],
      isLoaded: false,
      showingDetails: false,
      selectedDeviceGroup: {},
      selectedOnlineStatus: true,
    };
  },
};
</script>

<style lang="css" scoped>
.capEachWord {
  text-transform: capitalize;
}
.disabledButton{
    cursor: no-drop !important;
    pointer-events: none;
}
</style>