<template>
  <v-autocomplete
    :search-input.sync="search"
    :loading="isLoading"
    v-model="selectedPaxCompany"
    hide-details
    :items="companies"
    hide-no-data
    item-text="name"
    item-value="id"
    placeholder="Search Pax8"
    prepend-icon="mdi-database-search"
    label="Pax8"
    return-object
    class="mb-4"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    selectedPaxCompany() {
      this.$emit("input", this.selectedPaxCompany);
    },
    search() {
      if (this.isLoading) {
        return;
      } else {
        this.searchForCompanies();
      }
    },
  },
  methods: {
    searchForCompanies() {
      this.isLoading = true;
      this.$courier.PaxExternal.getData(1, 20, this.search).then((data) => {
        this.companies = data.payload;
        this.isLoading = false;
      });
    },
  },
  data() {
    return {
      selectedPaxCompany: {},
      search: "",
      isLoading: false,
      companies: [],
    };
  },
};
</script>

<style>
</style>