<template>
  <div>
    <v-switch
      label="Has Break Clause"
      v-model="innerHasBreakClause"
      @click="$emit('update:hasBreakClause', innerHasBreakClause)"
    >
    </v-switch>
    <v-expansion-panels v-model="showBreakClause">
      <v-expansion-panel class="transparent">
        <v-expansion-panel-content>
            <span class="text-h6">
                 Break Clause in Days from {{effectiveDate}}
            </span>
          <v-slider
            v-model="innerBreakClauseInDays"
            track-color="grey"
            always-dirty
            min="0"
            max="120"
            class="mt-8"
            @input="$emit('update:breakClauseInDays', innerBreakClauseInDays)"
          >
            <template v-slot:prepend>
              <v-text-field
                :label="'Break Clause In Days'"
                v-model="innerBreakClauseInDays"
                class="mt-0 pt-0"
                type="number"
                style="width: 200px"
                @keyup="$emit('update:breakClauseInDays', innerBreakClauseInDays)"
              >
              </v-text-field>
            </template>
          </v-slider>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    hasBreakClause: {
      type: Boolean,
      default: false,
    },
    breakClauseInDays: {
      type: Number,
      default: 0,
    },
    effectiveDate: {
        type: String,
        default: ''
    }
  },
  watch: {
    hasBreakClause(){
      this.innerHasBreakClause = this.hasBreakClause;
    },
    breakClauseInDays(){
      this.innerBreakClauseInDays = this.breakClauseInDays;
    }
  },
  computed: {
    showBreakClause() {
      return this.innerHasBreakClause ? 0 : null;
    },
  },
  data() {
    return {
      innerHasBreakClause: this.hasBreakClause,
      innerBreakClauseInDays: this.breakClauseInDays,
    };
  },
};
</script>

<style>
</style>