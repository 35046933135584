<template>
  <base-card>
    <v-card-title v-if="!hideTitle"> Add Contact </v-card-title>
    <v-card-text>
      <trev-contact-add-raw
        ref="contactAdd"
        :companyId="companyId"
        :contactId="contactId"
      ></trev-contact-add-raw>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" class="mr-4" @click="saveContact">
        {{ contactId ? "Update" : "Add" }} Contact
      </v-btn>
    </v-card-actions>
  </base-card>
</template>

<script>
import trevContactAddRaw from "./trev-contact-add-raw.vue";
export default {
  components: { trevContactAddRaw },
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: "",
    },
    contactId: {
      type: String,
      default: "",
    },
  },
  methods: {
    saveContact() {
      this.$refs.contactAdd.saveContact();
      this.$emit('added-contact');
    },
  },
};
</script>

<style></style>
