<template>
  <v-expansion-panels inset v-model="openSettings">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-icon class="mr-2"> mdi-credit-card-edit-outline </v-icon>
            Direct Debit Options
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-checkbox
          label="Direct Debit Signed"
          v-model="isDirectDebitSetUp"
        ></v-checkbox>
        <v-btn type="success" @click="saveDirectDebitDetails">
          <v-icon class="mr-2"> mdi-save </v-icon>
          Save
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-icon class="mr-2"> mdi-message-draw </v-icon>
            {{ company.internalMessage ? "Change" : "Add" }} Message
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field
          placeholder="e.g Our POC is on holiday, contact Colin instead"
          v-model="internalMessage"
        ></v-text-field>
        <v-btn
          type="success"
          @click="saveInternalMessage"
          :disabled="internalMessage == ''"
        >
          <v-icon class="mr-2"> mdi-save </v-icon>
          Save Message
        </v-btn>
        <v-btn color="red" class="ml-2" @click="deleteInternalMessage">
          <v-icon class="mr-2"> mdi-delete </v-icon>
          Remove Message
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span>
              <v-icon class="mr-2">
                mdi-ray-start-arrow
              </v-icon>
              Flow Buttons
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-flow-settings :companyId="company.id">
        </trev-company-flow-settings>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel @change="changeZohoDesk = false">
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span
              :class="company.zohoDeskId > 0 ? 'success--text' : 'red--text'"
            >
              <v-icon class="mr-2"> mdi-remote-desktop </v-icon>
              {{ company.zohoDeskId ? "Change" : "Add" }} Zoho Desk Link
            </span>
          </v-col>
          <v-col v-if="company.zohoDeskId" class="pt-0 pb-0">
            <v-btn
              small
              :href="
                'https://www.coffeecupsupport.com/support/coffeecupsolutions/ShowHomePage.do#Accounts/dv/' +
                company.ZohoDeskId
              "
              target="_blank"
              color="info"
            >
              <v-icon class="mr-2"> mdi-open-in-new </v-icon>
              Open In Zoho Desk
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-zoho-desk-setup
          @update-company="updatedCompany"
          :company="company"
        >
        </trev-company-zoho-desk-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel @change="changeZohoCRM = false">
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span
              :class="company.zohoCRMId > 0 ? 'success--text' : 'red--text'"
            >
              <v-icon class="mr-2"> mdi-view-dashboard-outline </v-icon>
              {{ company.zohoCRMId ? "Change" : "Add" }} Zoho CRM Link
            </span>
          </v-col>
          <v-col v-if="company.zohoCRMId" class="pt-0 pb-0">
            <v-btn
              small
              :href="
                'https://crm.zoho.eu/crm/org20068100338/tab/Accounts/' +
                company.zohoCRMId
              "
              target="_blank"
              color="info"
            >
              <v-icon class="mr-2"> mdi-open-in-new </v-icon>
              Open In Zoho CRM
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-zoho-crm-setup
          @update-company="updatedCompany"
          :company="company"
        >
        </trev-company-zoho-crm-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel @change="changeHudu = false">
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span :class="company.huduId > 0 ? 'success--text' : 'red--text'">
              <v-icon class="mr-2"> mdi-key-variant </v-icon>
              {{ company.huduId ? "Change" : "Add" }} Hudu Link
            </span>
          </v-col>
          <v-col v-if="company.huduURL" class="pt-0 pb-0">
            <v-btn small :href="company.huduURL" target="_blank" color="info">
              <v-icon class="mr-2"> mdi-open-in-new </v-icon>
              Open In Hudu
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-hudu-setup
          @update-company="updatedCompany"
          :company="company"
        >
        </trev-company-hudu-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span
              :class="
                ninjaLinks && ninjaLinks.length > 0
                  ? 'success--text'
                  : 'red--text'
              "
            >
              <v-icon class="mr-2"> mdi-remote </v-icon> Ninja RMM Links</span
            >
          </v-col>
          <v-col class="pt-0 pb-0">
            <v-chip label color="info" small>
              {{ ninjaLinks.length }} Link{{
                ninjaLinks.length > 1 || !ninjaLinks || ninjaLinks.length == 0
                  ? "s"
                  : ""
              }}
            </v-chip>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-ninja-setup
          @updated-ninja="getCompanyNinjaLinks()"
          :companyId="company.id"
        >
        </trev-company-ninja-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span
              :class="
                paxLinks && paxLinks.length > 0 ? 'success--text' : 'red--text'
              "
            >
              <v-icon class="mr-2"> mdi-remote-desktop </v-icon>
              {{ company.huduId ? "Change" : "Add" }} Pax8 Links
            </span>
          </v-col>
          <v-col class="pt-0 pb-0">
            <v-chip label color="info" small>
              {{ paxLinks.length }} Link{{
                paxLinks.length > 1 || !paxLinks || paxLinks.length == 0
                  ? "s"
                  : ""
              }}
            </v-chip>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-pax-setup @updated-pax="addedPax" :company="company">
        </trev-company-pax-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-icon class="mr-2">mdi-cash-register</v-icon>
            <span :class="xeroLinks && xeroLinks.length > 0 ? 'success--text' : 'danger--text'">
              Xero Links
            </span>
          </v-col>
           <v-col class="pt-0 pb-0">
            <v-chip label color="info" small>
              {{ xeroLinks.length }} Link{{
                xeroLinks.length > 1 || !xeroLinks || xeroLinks.length == 0
                  ? "s"
                  : ""
              }}
            </v-chip>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-xero-setup :companyId="$route.params.companyId" @changed="getXeroLinks"></trev-company-xero-setup>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="company.zohoCRMId">
      <v-expansion-panel-header>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-icon class="mr-2"> mdi-map-marker-outline</v-icon>
            ReSync Address From Zoho CRM
          </v-col>
          <v-col>
            {{ company.firstLine ? company.firstLine : "" }}
            {{ company.postCode ? " - " + company.postCode : "" }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <trev-company-zoho-crm-address-sync
          :companyId="company.id"
          @update-company="updatedCompany"
        >
        </trev-company-zoho-crm-address-sync>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import trevHuduCompanySearch from "../../../../../components/forms/trev-hudu-company-search.vue";
import TrevPaxCompanySearch from "../../../../../components/forms/trev-pax-company-search.vue";
import TrevZohoCrmSearch from "../../../../../components/forms/trev-zoho-crm-search.vue";
import TrevZohoDeskSearch from "../../../../../components/forms/trev-zoho-desk-search.vue";
import TrevCompanyFlowSettings from './trev-company-flow-settings.vue';
import TrevCompanyHuduSetup from "./trev-company-hudu-setup.vue";
import TrevCompanyNinjaSetup from "./trev-company-ninja-setup.vue";
import TrevCompanyPaxSetup from "./trev-company-pax-setup.vue";
import TrevCompanyXeroSetup from './trev-company-xero-setup.vue';
import TrevCompanyZohoCrmAddressSync from "./trev-company-zoho-crm-address-sync.vue";
import TrevCompanyZohoCrmSetup from "./trev-company-zoho-crm-setup.vue";
import TrevCompanyZohoDeskSetup from "./trev-company-zoho-desk-setup.vue";
export default {
  components: {
    trevHuduCompanySearch,
    TrevZohoDeskSearch,
    TrevZohoCrmSearch,
    TrevPaxCompanySearch,
    TrevCompanyZohoDeskSetup,
    TrevCompanyZohoCrmSetup,
    TrevCompanyHuduSetup,
    TrevCompanyPaxSetup,
    TrevCompanyZohoCrmAddressSync,
    TrevCompanyNinjaSetup,
    TrevCompanyFlowSettings,
    TrevCompanyXeroSetup,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getCompany();
    this.getCompanyPaxLinks();
    this.getCompanyNinjaLinks();
    this.getXeroLinks();
  },
  methods: {
    async getCompany() {
      this.$courier.Companies.getById(this.companyId).then((company) => {
        this.company = company;
        this.internalMessage = this.company.internalMessage;
        this.isDirectDebitSetUp = this.company.isDirectDebitSetUp;
      });
    },
    async saveDirectDebitDetails() {
      //get the company - modify the prop and re save

      var comp = await this.$courier.Companies.getById(this.companyId);

      try {
        comp.isDirectDebitSetUp = this.isDirectDebitSetUp;
        await this.$courier.Companies.updateById(this.companyId, comp);
         this.$emit("update-company", this.company);
      } catch (exception) {
        //idk do something with the exception
        console.log(exception);
      }
    },
    addedPax() {
      this.getCompanyPaxLinks();
      this.$emit("updated-pax");
    },
    deleteInternalMessage() {
      this.company.internalMessage = "";
      this.internalMessage = "";

      this.$courier.Companies.updateById(this.companyId, this.company).then(
        () => {
          //not sure what to do here
          this.openSettings = [];
          this.$emit("update-company", this.company);
        }
      );
    },
    saveInternalMessage() {
      this.company.internalMessage = this.internalMessage;
      this.$courier.Companies.updateById(
        this.$route.params.companyId,
        this.company
      ).then(() => {
        //do nothing?
        //show success
        this.openSettings = [];
        this.$emit("update-company", this.company);
      });
    },
    async getXeroLinks(){
      return this.$courier.Companies.withId(this.$route.params.companyId).XeroLinks.getData(1, 999, '').then(xeroLinks => {
        this.xeroLinks = xeroLinks.payload;
      });
    },
    async getCompanyNinjaLinks() {
      return this.$courier.Companies.withId(this.$route.params.companyId)
        .NinjaLinks.getData(1, 100, "")
        .then((ninjaLinks) => {
          this.ninjaLinks = ninjaLinks.payload;
          this.updatedCompany();
        });
    },
    async getCompanyPaxLinks() {
      return this.$courier.Companies.withId(this.$route.params.companyId)
        .PaxLinks.getData(1, 100, "")
        .then((paxLinks) => {
          this.paxLinks = paxLinks.payload;
          this.updatedCompany();
        });
    },
    updatedCompany() {
      this.getCompany();
      this.$emit("update-company");
    },
  },
  data() {
    return {
      company: {},
      openSettings: [],
      internalMessage: "",
      paxLinks: [],
      ninjaLinks: [],
      xeroLinks: [],
      isDirectDebitSetUp: false,
    };
  },
};
</script>

<style>
</style>