<template>
  <div>
    <trev-cool-table
      :headers="headers"
      :courier="$courier.Companies.withId(companyId).Sites"
      title="Sites"
      :tableId="uniqueTableId"
      :showAddLinkAsButton="true"
      @add-clicked="$emit('add-site')"
    >
    <template v-slot:item.actions="{item}">
      <v-btn small color="danger" class="white--text" v-if="$store.getters.hasScope('Site.Delete')" @click="showConfirmDelete = true; selectedSite = item;">
        <v-icon>
          mdi-delete-outline
        </v-icon>
        Delete
      </v-btn>
    </template>
    </trev-cool-table>
    <v-dialog v-model="showConfirmDelete" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete {{selectedSite.name}}?
        </v-card-title>       
        <v-card-actions>
          <v-btn color="default" @click="showConfirmDelete = false;">
            No, Keep {{selectedSite.name}}
          </v-btn>

          <v-btn color="danger" class="white--text" @click="deleteSite">
            <v-icon>
              mdi-delete-outline
            </v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../tables/trev-cool-table.vue";

export default {
  components: { trevCoolTable },
  computed:{
    uniqueTableId(){
      return this.tableId + "-sites-list";
    }
  },
  props: {
    companyId: {
      type: String,
      default: ""
    },
    tableId: {
      type:String,
      default: ""
    }
  },
  created () {
    if(!this.$store.getters.hasScope('Site.Delete')){
      this.headers = this.headers.filter(x => x.value != 'actions');
    }
  },
  mounted(){
    this.$eventBus.$on(this.tableId + "-refresh", pagingObject => {
      this.$eventBus.$emit(this.uniqueTableId + "-refresh", pagingObject);
    });
  },
  methods: {
    deleteSite(){
      this.$courier.Sites.removeById(this.selectedSite.id).then(() => {
        this.$eventBus.$emit(this.uniqueTableId + '-refresh');
        this.showConfirmDelete = false;
        this.selectedSite = {};
      });
    }
  },
  beforeDestroy(){
    this.$eventBus.$off(this.tableId + '-refresh');
  },
  data: () => ({
    showConfirmDelete: false,
    selectedSite: {},
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name"
      },{
        text: "Actions",
        align: "start",
        value: 'actions'
      }
    ]
  })
};
</script>

<style></style>
