<template>
  <v-row>
    <v-col cols="8">
      <base-card>
        <v-card-text>
          <trev-simple-table
            :pageSize="productTablePageSize"
            title="Products"
            :totalRecordCount="products.length"
            tableId="contract-pricing-settings"
            :isLoading="isLoading"
            :items="getProducts"
            :headers="headers"
          >
            <template v-slot:item.type="{ item }">
              <v-chip v-if="item.type == 'custom'" color="info">
                Custom
              </v-chip>
              <v-chip v-else-if="item.type == 'Existing'" color="primary">
                Existing
              </v-chip>
              <v-chip v-else-if="item.type == 'In Contract'" color="purple">
                In Contract
              </v-chip>
              <v-chip v-else color="success"> Global </v-chip>
            </template>
            <template v-slot:item.price="{ item }">
              £{{ item.price }}
            </template>
            <template v-slot:item.skuNumber="{ item }">
              <v-chip label>
                {{ item.skuNumber }}
              </v-chip>
            </template>
            <template v-slot:item.purchaseDate="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.purchaseDate"
                    v-on="on"
                    single-line
                  ></v-text-field>
                </template>
                <span>
                  <v-date-picker
                    v-model="item.purchaseDate"
                    @change="$emit('update:products', productsList)"
                  >
                  </v-date-picker>
                </span>
              </v-menu>
            </template>
            <template v-slot:item.quantity="{ item }">
              <div class="d-flex mt-4">
                <v-btn
                  text
                  @click="
                    item.quantity--;
                    $emit('update:products', productsList);
                    compareExistingProductQuantities(item);
                  "
                >
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
                <v-text-field
                  single-line
                  v-model="item.quantity"
                  dense
                  class="inline"
                  @keyup="
                    $emit('update:products', productsList);
                    $emit('update:products', productsList);
                    compareExistingProductQuantities(item);
                  "
                ></v-text-field>
                <v-btn
                  text
                  @click="
                    item.quantity++;
                    $emit('update:products', productsList);
                    compareExistingProductQuantities(item);
                  "
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.errors="{ item }">
              <div
                v-if="
                  existingProducts.find(
                    (x) => x.id === item.id
                  ) &&
                  existingProducts.find((x) => x.skuNumber === item.skuNumber)
                    .isLessThanOriginal
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="danger--text" v-on="on" v-bind="attrs">
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ getProductQuantityDifference(item) }} Products will be
                    excluded from this contract
                  </span>
                </v-tooltip>
              </div>
            </template>
          </trev-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="4">
      <base-card>
        <v-card-title>
          <span class="text-h6"> Suggested Price </span>
        </v-card-title>
        <v-card-text>
          <span class="text-h4">
            <span class="success--text"> £{{ suggestedPrice }} </span>
          </span>
        </v-card-text>
      </base-card>
      <base-card class="mt-4">
        <v-card-title>
          <span class="text-h3"> Final Price </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="mdi-currency-gbp"
            v-model="innerPrice"
            @keyup="$emit('update:price', Number(innerPrice))"
            outlined
            color="success"
            type="number"
          >
          </v-text-field>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import TrevSimpleTable from "../../tables/trev-simple-table.vue";
export default {
  components: {
    TrevSimpleTable,
  },
  computed: {
    suggestedPrice() {
      if (this.productsList.length == 0) {
        return 0;
      }

      var arrayOfTotalCosts = [];

      this.productsList.forEach((product) => {
        if (product.quantity > 0) {
          var cost =
            this.$courier.ProductSkeletons.Functions.calculatePriceFromRules(
              product.quantity,
              product.price,
              product.priceRules
            );

          if (cost) {
            arrayOfTotalCosts.push(cost);
          }
        }
      });

      if (arrayOfTotalCosts.length === 0) {
        return 0;
      }

      return arrayOfTotalCosts.reduce((a, b) => a + b);
    },
    getProducts() {
      return this.productsList;
    },
  },
  methods: {},
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    products: {
      handler(newValue, oldValue) {
        this.isLoading = false;
        this.productsList = newValue;
        this.timesLoaded++;

        if (!this.existingProducts || this.existingProducts.length === 0) {
          this.getExistingProducts();
        }
      },
      deep: true,
      immediate: true,
    },
    price() {
      this.innerPrice = this.price;
    },
  },
  methods: {
    compareExistingProductQuantities(inputProduct) {
      if (inputProduct.type !== "Existing") {
        return false;
      }

      var foundProduct = this.existingProducts.filter(
        (x) => x.id == inputProduct.id
      )[0];

      if (foundProduct) {
        if (foundProduct.quantity > inputProduct.quantity) {
          foundProduct.isLessThanOriginal = true;
        } else {
          foundProduct.isLessThanOriginal = false;
        }
      }
    },
    getProductQuantityDifference(inputProduct) {
      if (inputProduct.type !== "Existing") {
        return 0;
      }

      var foundProduct = this.existingProducts.filter(
        (x) => x.id == inputProduct.id
      )[0];

      if (!foundProduct) {
        return 0;
      }

      var diff = inputProduct.quantity - foundProduct.quantity;

      if (diff < 0) {
        return diff * -1;
      } else {
        return diff;
      }
    },
    async getExistingProducts() {
      if (this.productsList.some((s) => s.type === "Existing")) {
        //there are some existing products, get from
        this.existingProducts = [];
        this.productsList
          .filter((x) => x.type === "Existing")
          .forEach((existingProduct) => {
            this.$courier.Products.getById(existingProduct.id).then(
              (fetchedProduct) => {
                fetchedProduct.isLessThanOriginal = false;
                this.existingProducts.push(fetchedProduct);
              }
            );
          });
      }
    },
  },
  data() {
    return {
      timesLoaded: 0,
      existingProducts: [],
      productTablePageSize: 5,
      productTablePageNumber: 1,
      isLoading: true,
      innerPrice: 0,
      productsList: [],
      headers: [
        { text: "Type", value: "type" },
        { text: "SKU", value: "skuNumber" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Purchase Date", value: "purchaseDate" },
        { text: "Quantity", value: "quantity" },
        { text: "", value: "errors" },
      ],
    };
  },
};
</script>

<style>
</style>
TrevSimpleTable