<template>
  <div>
    <trev-cool-table
      :courier="$courier.Companies.withId(companyId).Approvals"
      :headers="headers"
      @add-clicked="$emit('add-clicked')"
      :showAddLinkAsButton="$store.getters.hasScope('Approval.Create')"
      :getDataOnDateChange="false"
      @loaded="loadedTable"
      ref="tblApprovals"
    >
      <template slot="title">
        <v-icon class="mr-2"> mdi-check-decagram-outline </v-icon>
        Approvals
      </template>
      <template v-slot:item.dateAdded="{ item }">
        {{ new Date(item.dateAdded).toLocaleString("en-GB") }}
      </template>
      <template v-slot:item.title="{ item }">
        {{
          item.title.length > 29
            ? truncateString(item.title, 30) + "..."
            : item.title
        }}
        <v-btn icon small @click="showApproval(item)">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.approvers="{ item }">
        <v-progress-circular
          indeterminate
          v-if="!isDoneLoadingApprovalContacts(item.id)"
        ></v-progress-circular>
        <div v-else>
          <v-chip
            color="purple"
            class="mr-2 white--text"
            label
            v-for="(contact, index) in getContactsFromApproval(item.id)"
            :key="index"
          >
            {{ contact.firstName + " " + contact.lastName }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip class="mr-2" color="primary" v-if="item.actionFeedBack" @click="showFeedBack(item)">
          <v-icon>
            mdi-message-alert-outline
          </v-icon>
        </v-chip>
        <v-chip
          :color="item.isApproved ? 'green' : item.isDenied ? 'danger' : ''"
          :class="item.isApproved || item.isDenied ? 'white--text' : 'grey--text'"
        >
          <v-icon class="mr-2">
            {{
              item.isApproved
                ? "mdi-account-check-outline"
                : item.isDenied
                ? "mdi-account-cancel-outline"
                : "mdi-account-clock-outline"
            }}
          </v-icon>
          {{
            item.isApproved
              ? "Approved"
              : item.isDenied
              ? "Denied"
              : "Pending Approval"
          }}
          {{
            item &&
            (item.isApproved || item.isDenied) &&
            item.actionedByContactId
              ? getActionedByContactName(item)
              : ""
          }}
          {{
            item && (item.isApproved || item.isDenied)
              ? " @ " + new Date(item.actionedAt).toLocaleString()
              : ""
          }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="warning"
          class="mr-2"
          @click="$emit('edit', item.id)"
          small
           :disabled="!item.isApproved && !item.isDenied"
        >
          <v-icon class="mr-2"> mdi-pencil </v-icon>
          Edit
        </v-btn>
        <v-btn
          color="danger"
          small
          @click="showDelete(item)"
          :loading="item.id === loadingDeleteButtonId"
        >
          <v-icon class="mr-2"> mdi-close </v-icon>
          Remove
        </v-btn>
      </template>
    </trev-cool-table>
    <v-dialog max-width="80vh" v-model="isShowDelete">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Delete Approval:
            <span class="success--text">{{ approvalToDelete.title }}</span
            >?
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle> Approvers </v-list-item-subtitle>
              <v-list-item-title>
                <v-chip
                  color="purple"
                  class="mr-2"
                  v-for="approver in approvalToDelete.approvers"
                  :key="approver.id"
                >
                  <v-icon left class="mr-2">
                    mdi-account-circle-outline
                  </v-icon>
                  {{ approver.firstName + " " + approver.lastName }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle> Content </v-list-item-subtitle>
              <v-list-item-title>
                <span v-html="approvalToDelete.content"></span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn color="danger" @click="deleteApproval">
            <v-icon class="mr-2"> mdi-delete-outline </v-icon>
            Delete
          </v-btn>
          <v-btn text @click="cancelDeleteApproval"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowingApproval" max-width="70vh">
      <v-card>
        <v-card-title>
          <div class="text-h5">
            <v-icon class="mr-2"> mdi-check-decagram-outline </v-icon>
            {{ showingApproval.title }}
          </div>
        </v-card-title>
        <v-card-title v-if="showingApproval" class="pt-0">
          <v-chip
            label
            :color="
              showingApproval.isApproved
                ? 'success'
                : showingApproval.isDenied
                ? 'danger'
                : ''
            "
            class="mt-2"
          >
            <v-icon class="mr-2">
              {{
                showingApproval.isApproved
                  ? "mdi-account-check-outline"
                  : showingApproval.isDenied
                  ? "mdi-account-cancel-outline"
                  : "mdi-account-clock-outline"
              }}
            </v-icon>
            <div
              v-if="
                showingApproval.actionedByContact &&
                showingApproval.actionedByContact.id
              "
            >
              {{ showingApproval.isApproved ? "Approved" : "Denied" }} By
              {{
                showingApproval.actionedByContact.firstName +
                " " +
                showingApproval.actionedByContact.lastName
              }}
            </div>
            <div v-else>
              Pending Approval
            </div>
          </v-chip>
        </v-card-title>
        <v-card-subtitle class="mt-0 pb-2">
          Added By {{ showingApproval.addedBy }} @
          {{ new Date(showingApproval.dateAdded).toLocaleDateString("en-GB") }}
        </v-card-subtitle>
        <v-divider class="pt-0"></v-divider>
        <v-card-text class="mt-4" v-if="showingApproval.actionFeedBack">
          <div class="text--h6 mb-2">
            Approval Feedback
          </div>
          <p>
            {{showingApproval.actionFeedBack}}
          </p>
        </v-card-text>
        <v-divider class="pt-0"></v-divider>
        <v-card-text class="mt-6">
          <div class="text--h6 mb-2">Approvers</div>
          <v-chip
            color="purple"
            v-for="contact in showingApproval.contacts"
            :key="contact.id"
            class="mr-2"
          >
            <v-icon class="mr-2"> mdi-account-circle-outline </v-icon>
            {{ contact.firstName + " " + contact.lastName }}
          </v-chip>
        </v-card-text>
        <v-card-text v-html="showingApproval.content"> </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowingFeedBack" max-width="50vh">
      <v-card>
        <v-card-title>
          Approval FeedBack
        </v-card-title>
        <v-card-text>
          {{feedBack}}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../../../../../components/tables/trev-cool-table.vue";
import * as GeneralUtilities from "@/utilities/GeneralUtilities";
export default {
  components: { trevCoolTable },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  methods: {
    showFeedBack(approval){
      this.isShowingFeedBack = true;
      this.feedBack = approval.actionFeedBack;
    },
    truncateString(string, length) {
      return GeneralUtilities.concatenateStringByLength(string, length);
    },
    getActionedByContactName(approval) {
      var fApp = this.loadedApprovals.find((x) => x.id == approval.id);

      if (fApp) {
        return (
          fApp.actionedByContact.firstName +
          " " +
          fApp.actionedByContact.lastName
        );
      } else {
        return "";
      }
    },
    showApproval(approval) {
      this.showingApproval = approval;
      this.showingApproval.contacts = this.loadedApprovals.find(
        (x) => x.id == approval.id
      ).contacts;
      this.showingApproval.actionedByContact = this.loadedApprovals.find(
        (x) => x.id == approval.id
      ).actionedByContact;
      this.isShowingApproval = true;
    },
    async deleteApproval() {
      await this.$courier.Approvals.removeById(this.approvalToDelete.id);
      this.cancelDeleteApproval();
      this.getData();
    },
    cancelDeleteApproval() {
      this.approvalToDelete = {};
      this.loadingDeleteButtonId = "";
      this.isShowDelete = false;
    },
    showDelete(approval) {
      this.approvalToDelete = approval;
      this.loadingDeleteButtonId = approval.id;
      //get the approvers for the approval

      this.$courier.Approvals.withId(approval.id)
        .Contacts.getData(1, 999, "")
        .then((resp) => {
          this.approvalToDelete.approvers = resp.payload;
          this.isShowDelete = true;
          this.loadingDeleteButtonId = "";
        });
    },
    getData() {
      this.$refs.tblApprovals.getData();
    },
    async loadedTable(approvals) {
      console.log("loading approvals");
      for (const approval of approvals) {
        this.getApprovalContacts(approval);
      }
    },
    getContactsFromApproval(approvalId) {
      var approval = this.loadedApprovals.find((x) => x.id == approvalId);

      return approval.contacts.filter((s) => s.active);
    },
    async getApprovalContacts(approval) {
      this.loadedApprovals = [];
      var cPayload = await this.$courier.Approvals.withId(
        approval.id
      ).Contacts.getData(1, 900, "");

      var insertObject = {};

      insertObject = {
        id: approval.id,
        contacts: [],
        actionedByContact: {},
      };

      if (cPayload && cPayload.payload) {
        var c = cPayload.payload;
        insertObject.contacts = c;
      }

      //get the actioned by contact

      if (approval.actionedByContactId) {
        var actionedByContact = await this.$courier.Contacts.getById(
          approval.actionedByContactId
        );

        insertObject.actionedByContact = actionedByContact;
      }

      this.loadedApprovals.push(insertObject);

      return insertObject.contacts;
    },
    isDoneLoadingApprovalContacts(approvalId) {
      var r = this.loadedApprovals.some((x) => x.id == approvalId);
      return r;
    },
  },
  data() {
    return {
      isShowingFeedBack: false,
      feedBack: '',
      showingApproval: {},
      isShowingApproval: false,
      loadingDeleteButtonId: "",
      isShowDelete: false,
      approvalToDelete: {},
      loadedApprovals: [
        {
          id: "",
          contacts: [],
        },
      ],
      headers: [
        {
          text: "Requested At",
          value: "dateAdded",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Approvers",
          value: "approvers",
        },
        {
          text: "Requested By",
          value: "addedBy",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>
</style>