<template>
  <div>
    <v-sheet class="px-3 py-3 pb-0 pt-0" rounded>
      <div class="container container--fluid pb-0 pt-0">
        <div class="d-flex flex-wrap justify-space-between">
          <div class="flex-1">
            <v-row>
              <v-col class="pb-0 mb-0">
                <div class="d-flex align-start">
                  <div class="">
                    <h3 class="mr-2 mb-0">
                      <v-icon class="mr-1" size="xl-large"> mdi-domain </v-icon>
                      {{ company.name }}

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="body-1" color="primary" v-bind="attrs" v-on="on">
                            mdi-check-decagram</v-icon>
                        </template>
                        <span>Thomas is well cool</span>
                      </v-tooltip>
                    </h3>
                    <p class="text--disabled mb-0">
                      <v-icon class="mr-2">
                        mdi-account
                      </v-icon>
                      Account Manager:
                      <span v-if="accountManager.id">
                        {{ accountManager.firstName + ' ' + accountManager.lastName }}
                      </span>
                      <span v-else>
                        None
                      </span>
                    </p>
                    <p class="text--disabled mb-0">
                      Added By: {{ company.addedBy }}
                    </p>
                  </div>
                  <!-- <h3
                    class="ma-0 mr-8"
                    :class="supportTextClass"
                    v-if="company.supportText"
                  >
                    {{ company.supportText }}
                  </h3>
                  <h3 v-else class="danger--text">No Support</h3> -->
                </div>
              </v-col>
              <v-col>
                <div class="flex-1 text-right">
                  <v-chip class="ma-2" color="green accent-3" outlined v-if="company.isDirectDebitSetUp">
                    <v-icon left> mdi-credit-card-check-outline </v-icon>
                    Direct Debit Signed
                  </v-chip>
                  <v-chip class="ma-2" color="green" outlined v-if="company.active">
                    <v-icon left> mdi-check </v-icon>
                    Active
                  </v-chip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip class="ma-2" color="info" outlined
                        v-if="$store.getters.hasScope('Domain.Read') || $store.getters.hasScope('Email.Read')"
                        @click="showDomainEmailDialog">
                        <span v-if="companyDomains.length > 0 && $store.getters.hasScope('Domain.Read')" class="mr-2">
                          <v-icon> mdi-domain </v-icon>
                          {{ companyDomains.length }}
                        </span>
                        <span v-if="companyEmails.length > 0 && $store.getters.hasScope('Email.Read')" class="ml-2">
                          <v-icon> mdi-email-multiple-outline </v-icon>
                          {{ companyEmails.length }}
                        </span>
                        <span v-else>
                          <v-icon> mdi-plus </v-icon>
                          Add
                        </span>
                      </v-chip>
                    </template>
                    <span>
                      {{ companyDomains.length }} Domains -
                      {{ companyEmails.length }} Emails
                    </span>
                  </v-tooltip>
                  <v-chip class="ma-2" color="info" v-if="$store.getters.hasScope('Ticket.Modify')" outlined
                    @click="showResync">ReSync</v-chip>
                  <v-chip class="ma-2" color="warning" v-if="$store.getters.hasScope('Company.Modify')" outlined
                    @click="showEditCompany" :disabled="!company.name">
                    Edit
                  </v-chip>
                  <v-dialog v-model="showCompanyDomains" max-width="600px">
                    <v-card>
                      <v-tabs-items v-model="currentDomainTab">
                        <v-tab-item value="table">
                          <trev-cool-table @add-clicked="currentDomainTab = 'add'" title="Domains" :courier="
                            $courier.Companies.withId($route.params.companyId)
                              .Domains
                          " :headers="[
  { text: 'Domain', value: 'domain' },
  { text: 'Action', value: 'action' },
]" tableId="company-domain-table" :showAddLinkAsButton="true">
                            <template v-slot:title>
                              Domains
                              <v-btn disabled small class="ml-2">
                                Domains
                              </v-btn>
                              <v-btn small @click="currentDomainTab = 'email'" class="ml-1">
                                Emails
                              </v-btn>
                            </template>
                            <template v-slot:item.action="{ item }">
                              <v-btn color="danger" small @click="deletedDomain(item.id)">
                                <v-icon> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                          </trev-cool-table>
                        </v-tab-item>
                        <v-tab-item value="email">
                          <trev-cool-table @add-clicked="currentDomainTab = 'addemail'" :courier="
                            $courier.Companies.withId($route.params.companyId)
                              .Emails
                          " :headers="[
  { text: 'Email', value: 'email' },
  { text: 'Action', value: 'action' },
]" tableId="company-email-table" :showAddLinkAsButton="true">
                            <template v-slot:title>
                              Emails
                              <v-btn small @click="currentDomainTab = 'table'" class="ml-2">
                                Domains
                              </v-btn>
                              <v-btn small disabled class="ml-1">
                                Emails
                              </v-btn>
                            </template>
                            <template v-slot:item.action="{ item }">
                              <v-btn color="danger" small @click="deleteEmail(item.id)">
                                <v-icon> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                          </trev-cool-table>
                        </v-tab-item>
                        <v-tab-item value="addemail">
                          <v-card>
                            <v-card-title>
                              <v-icon> mdi-plus </v-icon>
                              Add Email
                            </v-card-title>
                            <v-card-text>
                              <v-text-field label="Email" v-model="newEmail">
                              </v-text-field>
                              <div class="danger--text" v-if="newEmail && !isEmailAllowed">
                                Please enter a valid email.
                              </div>
                              <div v-if="isEmailAllowed && newEmail" class="success--text">
                                Looks good.
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="default"
                                @click="
                                  newEmail = '';
                                currentDomainTab = 'email';
                                                                                                                                                                                                  ">
                                Cancel
                              </v-btn>
                              <v-btn color="primary" @click="addNewEmail" :disabled="!isEmailAllowed || !newEmail">
                                Add Email
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item value="add">
                          <v-card>
                            <v-card-title>
                              <v-icon> mdi-plus </v-icon>
                              Add Domain
                            </v-card-title>
                            <v-card-text>
                              <v-text-field label="Domain Name" v-model="newDomain">
                              </v-text-field>
                              <div class="danger--text" v-if="!newDomain">
                                Please enter a valid domain.
                              </div>
                              <div class="danger--text" v-if="!isDomainAllowed">
                                Please only enter the domain without the
                                protocol specified.
                              </div>
                              <div v-if="isDomainAllowed && newDomain" class="success--text">
                                Looks good.
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="default"
                                @click="
                                  newDomain = '';
                                currentDomainTab = 'table';
                                                                                                                                                                                                  ">
                                Cancel
                              </v-btn>
                              <v-btn color="primary" @click="addNewDomain" :disabled="!isDomainAllowed || !newDomain">
                                Add Domain
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-dialog>
                  <p class="text--disabled mb-0">
                    Added On:
                    {{
                      new Date(company.dateAdded).toLocaleDateString("en-GB")
                    }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show="company.internalMessage">
                <v-col>
                  <v-alert type="info" class="mb-0">
                    {{ company.internalMessage }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-expand-transition>
            <!-- <p class="text--disabled">
              Actor, musician, songwriter #amanda_nash, mailbox:
              hello@amandanash.com
            </p> -->
          </div>
        </div>
      </div>
    </v-sheet>
    <base-card class="transparent">
      <v-tabs background-color="" v-model="bigTabs" grow>
        <v-tab @click="currentTab = 'dashboard'">
          <v-icon class="mr-2"> mdi-chart-box-outline </v-icon>
          Dashboard
        </v-tab>
        <v-menu open-on-hover offset-y close-delay="500">
          <template v-slot:activator="{ on, attr }">
            <v-tab v-on="on" v-bind="attr" @click="currentTab = 'company-tickets'">
              <v-icon class="mr-2">mdi-face-agent</v-icon>
              Support
            </v-tab>
          </template>
          <v-list dense>
            <v-list-item-group v-model="currentTab">
              <v-list-item value="company-tickets" @click="bigTabs = 1" v-if="$store.getters.hasScope('Ticket.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-lifebuoy </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Tickets</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="company-contacts" @click="bigTabs = 1" v-if="$store.getters.hasScope('Contact.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-view-list </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Contacts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="company-approvals" @click="bigTabs = 1" v-if="$store.getters.hasScope('Approval.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-check-decagram-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Approvals</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="company-sites" @click="bigTabs = 1" v-if="$store.getters.hasScope('Site.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-video-input-component </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Sites</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attr }">
            <v-tab v-on="on" v-bind="attr" @click="currentTab = 'company-products'">
              <v-icon class="mr-2"> mdi-package-variant </v-icon>
              Sales
            </v-tab>
          </template>
          <v-list dense>
            <v-list-item-group v-model="currentTab">
              <v-list-item value="company-jobs" @click="bigTabs = 2" v-if="$store.getters.hasScope('Job.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-view-list </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Jobs</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="company-products" @click="bigTabs = 2" v-if="$store.getters.hasScope('Product.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-view-list </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Products</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item value="company-contracts" @click="bigTabs = 2" v-if="$store.getters.hasScope('Contract.Read')">
                <v-list-item-icon>
                  <v-icon> mdi-file-document-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Contracts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item value="company-product-skeletons" @click="bigTabs = 2"
                v-if="$store.getters.hasScope('ProductSkeleton.Read')">
                <v-list-item-icon>
                  <v-icon>mdi-playlist-star</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Custom Products</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item value="company-charge-items" @click="bigTabs = 2"
                v-if="$store.getters.hasScope('ChargeItem.Read')">
                <v-list-item-icon>
                  <v-icon>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Charge Items</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-tab v-if="$store.getters.hasScope('Company.Modify')" @click="
          currentTab = 'settings';
        bigTabs = 3;
                                                          ">
          <v-badge class="ml-2" :color="
            numberOfLinkedServices == 0
              ? 'red'
              : numberOfLinkedServices > 0 && numberOfLinkedServices < 6
                ? 'warning'
                : 'success'
          " :content="6 - numberOfLinkedServices" :value="6 - numberOfLinkedServices > 0" offset-y="10" offset-x="1">
            <v-icon class="mr-2"> mdi-cog-outline </v-icon>
            Settings
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" class="transparent">
        <v-tab-item value="company-approvals">
          <v-row>
            <v-col>
              <trev-company-approvals :companyId="$route.params.companyId" v-if="$store.getters.hasScope('Approval.Read')"
                @add-clicked="currentTab = 'company-approvals-add'" @edit="editApproval" ref="tblApprovals">
              </trev-company-approvals>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="company-approvals-add">
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Approval
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <trev-approval-add ref="approvalAdd" v-if="$store.getters.hasScope('Approval.Create')"
                    :companyId="$route.params.companyId" :approvalId="editApprovalId" @cancelled="
                      currentTab = 'company-approvals';
                    editApprovalId = '';" @updated="
  $refs.tblApprovals.getData();
currentTab = 'company-approvals';"></trev-approval-add>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="settings" :transition="false">
          <v-row>
            <v-col>
              <trev-company-settings v-if="$store.getters.hasScope('Company.Modify')" :companyId="$route.params.companyId"
                @update-company="refreshCompany" @updated-pax="getCompanyPaxLinks"></trev-company-settings>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="dashboard" :transition="false">
          <trev-company-dashboard :startDate="startOfCurrentMonth" :endDate="endOfCurrentMonth"
            :companyId="$route.params.companyId">
          </trev-company-dashboard>
        </v-tab-item>
        <v-tab-item value="company-jobs" :transition="false">
          <trev-jobs-table v-if="$store.getters.hasScope('Job.Read')"
            :companyId="$route.params.companyId"></trev-jobs-table>
        </v-tab-item>
        <v-tab-item value="company-products" :transition="false">
          <trev-products-view v-if="$store.getters.hasScope('Product.Read')" class="mt-3" @click="bigTabs = 2"
            tableId="company-product-table" :companyId="$route.params.companyId"
            @add-product="currentTab = 'company-product-add'" :contractProductsTableId="'company-contract-products'"
            @edit-product="showEditProduct"></trev-products-view>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Product.Create')" value="company-product-add" :transition="false">
          <trev-product-add @added-product="addedProduct" :companyId="$route.params.companyId"
            :companyName="company.name"></trev-product-add>
        </v-tab-item>
        <v-tab-item value="company-product-edit" v-if="$store.getters.hasScope('Product.Modify')" :transition="false">
          <v-sheet class="px-4 py-4 mt-3">
            <trev-product-customise v-if="editProduct.id" :returnWithId="true" :productSkeleton="editProduct"
              @product-updated="productEdited" :isProduct="true" :isProductSkeleton="false"
              :links.sync="editProductLinks">
              <template v-slot:top-right>
                <v-btn color="primary" @click="saveProductChanges">
                  Save Changes
                  <v-icon class="ml-2"> mdi-floppy </v-icon>
                </v-btn>
              </template>
            </trev-product-customise>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="company-contracts" v-if="$store.getters.hasScope('Contract.Read')" :transition="false">
          <trev-contract-list class="mt-3" :companyId="$route.params.companyId" tableId="company-contract-table"
            @add-contract="currentTab = 'company-contract-add'" @contract-removed="deletedContract"></trev-contract-list>
        </v-tab-item>
        <v-tab-item value="company-contract-add" v-if="$store.getters.hasScope('Contract.Create')" :transition="false">
          <trev-contract-add :companyId="$route.params.companyId" @added-contract="addedContract"></trev-contract-add>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('ProductSkeleton.Read')" value="company-product-skeletons"
          :transition="false">
          <trev-product-skeleton-list class="mt-3" tableId="company-product-skeleton-table"
            :companyId="$route.params.companyId"
            @add-product-skeleton="currentTab = 'company-product-skeleton-add'"></trev-product-skeleton-list>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('ProductSkeleton.Create') && $store.getters.hasScope('Company.Modify')"
          value="company-product-skeleton-add" :transition="false">
          <trev-product-skeleton-add @added-product-skeleton="addedProdSkel"
            :companyId="$route.params.companyId"></trev-product-skeleton-add>
        </v-tab-item>
        <v-tab-item value="company-charge-items" :transition="false" v-if="$store.getters.hasScope('ChargeItem.Read')">
          <v-container fluid class="mt-0 pt-0">
            <v-row>
              <v-col lg="10" class="ml-0 pl-0">
                <trev-charge-items-table @datechange="chargeItemDateChange" :companyId="$route.params.companyId">
                </trev-charge-items-table>
              </v-col>
              <v-col lg="2" class="mr-0 pr-0">
                <base-card>
                  <v-card-title> Quick Details </v-card-title>
                  <v-card-title class="green--text">
                    £{{ totalChargeItemsCost }}
                  </v-card-title>
                  <v-card-subtitle> Total Cost </v-card-subtitle>
                  <v-card-title class="green--text">
                    £{{ totalChargeItemsRetailCost }}
                  </v-card-title>
                  <v-card-subtitle> Total Retail Cost </v-card-subtitle>
                </base-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Ticket.Read')" class="transparent" value="company-tickets" :transition="false">
          <v-row>
            <v-col cols="12" lg="12">
              <trev-company-zoho-tickets-table :companyId="$route.params.companyId"></trev-company-zoho-tickets-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Ticket.Modify')" class="transparent" value="ticket-resync" :transition="false">
          <v-row>
            <v-col cols="12" lg="12">
              <trev-ticket-resync></trev-ticket-resync>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Contact.Read')" value="company-contacts" class="transparent" :transition="false">
          <contacts-list class="mt-3" :companyId="$route.params.companyId" tableId="company-contacts-list"
            @add-contact="currentTab = 'company-contacts-add'">
          </contacts-list>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Contact.Create')" value="company-contacts-add" class="transparent" :transition="false">
          <trev-contact-add :companyId="$route.params.companyId" @added-contact="addedNewContact"></trev-contact-add>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Site.Read')" value="company-sites" class="transparent" :transition="false">
          <trev-sites-list class="mt-3" :companyId="$route.params.companyId" tableId="company-sites-list"
            @add-site="currentTab = 'company-sites-add'">
          </trev-sites-list>
        </v-tab-item>
        <v-tab-item v-if="$store.getters.hasScope('Site.Create')" value="company-sites-add" class="transparent" :transition="false">
          <trev-site-add :companyId="$route.params.companyId" @added-site="addedSite">
          </trev-site-add>
        </v-tab-item>
      </v-tabs-items>
    </base-card>
    <v-dialog v-if="$store.getters.hasScope('Company.Modify')" v-model="showEditDialog" max-width="500px" @click:outside="cancelEditDetails">
      <v-card>
        <v-card-title> Editing Company </v-card-title>
        <v-card-text>
          <v-text-field v-model="companyBeingEdited.name" label="Name">
          </v-text-field>
          <v-select item-value="id" label="Account Manager" :items="users" v-model="companyBeingEdited.accountManagerId">
            <template slot="selection" slot-scope="data">
              {{ data.item.firstName }} - {{ data.item.lastName }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstName + ' ' + data.item.lastName }}
            </template>
          </v-select>
          <!-- <v-select v-if="$store.getters.hasScope('Xero.Read')" item-value="tenantId" item-text="organisationName" label="Default Xero Tenant" :items="$store.getters.getXeroProfiles" v-model="companyBeingEdited.defaultXeroTenantId">
          </v-select> -->
          <v-select label="Company Department" :items="['Coffee Cup Support', 'Coffee Cup Technical']" v-model="companyBeingEdited.department">

          </v-select>
          <v-text-field v-model="companyBeingEdited.invoicingPeriod" label="Payment Term">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.webSite" label="Website">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.firstLine" label="First Line">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.secondLine" label="Second Line">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.town" label="Town">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.county" label="County">
          </v-text-field>
          <v-text-field v-model="companyBeingEdited.postCode" label="Post Code">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveEditDetails"> Save Details </v-btn>
          <v-btn color="default" @click="cancelEditDetails"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TrevContactAdd from "../../../../components/contacts/trev-contact-add.vue";
import contactsList from "../../../../components/contacts/trev-contact-list.vue";
import TrevSitesList from "../../../../components/sites/trev-site-list.vue";
import TrevSiteAdd from "../../../../components/sites/trev-site-add.vue";
import TrevProductSkeletonAdd from "../../../../components/products/productskeletons/trev-product-skeleton-add.vue";
import TrevProductSkeletonList from "../../../../components/products/productskeletons/trev-product-skeleton-list.vue";
import TrevProductAdd from "../../../../components/products/trev-product-add.vue";
import TrevProductsView from "../../../../components/products/trev-products-list.vue";
import TrevContractList from "../../../../components/contracts/trev-contract-list.vue";
import TrevContractAdd from "../../../../components/contracts/trev-contract-add.vue";
import TrevChargeItemsTable from "../../../../components/ChargeItems/trev-charge-items-table.vue";
import * as utilities from "../../../../utilities/GeneralUtilities";
import TrevCompanyZohoTicketsTable from "../../../../components/ZohoTickets/trev-company-zoho-tickets-table.vue";
import TrevCoolTable from "../../../../components/tables/trev-cool-table.vue";
import TrevNumberTicker from "../../../../components/ui/trev-number-ticker.vue";
import TrevCoolList from "../../../../components/tables/trev-cool-list.vue";
import TrevZohoDeskSearch from "../../../../components/forms/trev-zoho-desk-search.vue";
import TrevZohoCrmSearch from "../../../../components/forms/trev-zoho-crm-search.vue";
import TrevHuduCompanySearch from "../../../../components/forms/trev-hudu-company-search.vue";
import TrevCompanySettings from "./components/trev-company-settings.vue";
import TrevCompanyDashboard from "./components/trev-company-dashboard.vue";
import TrevCompanyApprovals from "./components/trev-company-approvals.vue";
import TrevApprovalAdd from "../../../../components/approvals/trev-approval-add.vue";
import TrevProductCustomise from "../../../../components/products/trev-product-customise.vue";
import TrevTicketResync from '../../../../components/ZohoTickets/trev-ticket-resync.vue';
import TrevJobsTable from '../../../../components/jobs/trev-jobs-table.vue';
export default {
  components: {
    contactsList,
    TrevContactAdd,
    TrevSitesList,
    TrevSiteAdd,
    TrevProductSkeletonAdd,
    TrevProductSkeletonList,
    TrevProductAdd,
    TrevProductsView,
    TrevContractList,
    TrevContractAdd,
    TrevChargeItemsTable,
    TrevCompanyZohoTicketsTable,
    TrevCoolTable,
    TrevNumberTicker,
    TrevCoolList,
    TrevZohoDeskSearch,
    TrevZohoCrmSearch,
    TrevHuduCompanySearch,
    TrevCompanySettings,
    TrevCompanyDashboard,
    TrevCompanyApprovals,
    TrevApprovalAdd,
    TrevProductCustomise,
    TrevTicketResync,
    TrevJobsTable,
  },
  metaInfo() {
    return {
      title: this.company.name ?? "Company View",
    };
  },
  watch: {
    "$route.params.companyId"(val) {
      this.getCompany();
    },
    chargeItemDateStart: {
      immediate: true,
      handler() {
        this.calculateChargeItemCount();
      },
    },
    chargeItemDateEnd: {
      immediate: true,
      handler() {
        this.calculateChargeItemCount();
      },
    },
    currentTab(newVal, oldVal) {
      if (newVal == undefined) {
        this.currentTab = oldVal;
      }

      if (oldVal === "company-approvals-add") {
        this.$refs.approvalAdd.cancelApproval(false);
      }
      if (newVal === "company-approvals-add" && this.editApprovalId) {
        this.$refs.approvalAdd.loadEdit();
      }
    },
  },
  computed: {
    startOfCurrentMonth() {
      return utilities.getMinMaxDatesByDate(new Date()).startDate;
    },
    endOfCurrentMonth() {
      var end = utilities.getMinMaxDatesByDate(new Date()).endDate;

      end.setMinutes(59);
      end.setHours(23);
      end.setSeconds(59);

      return end;
    },
    startOfLastMonth() {
      let today = new Date();
      return utilities.getMinMaxDatesByDate(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDay())
      ).startDate;
    },
    endOfLastMonth() {
      let today = new Date();
      return utilities.getMinMaxDatesByDate(
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDay())
      ).endDate;
    },
    isEmailAllowed() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (this.newEmail.match(validRegex) && this.newEmail.includes(".")) {
        return true;
      } else {
        return false;
      }
    },
    isDomainAllowed() {
      if (this.newDomain.toString().startsWith("http://")) {
        return false;
      } else if (this.newDomain.toString().startsWith("https://")) {
        return false;
      } else if (!this.newDomain.toString().includes(".")) {
        return false;
      } else if (this.newDomain.toString().endsWith(".")) {
        return false;
      }

      return true;
    },
    supportTextClass() {
      if (this.company) {
        if (this.company.supportText == "Full Support") {
          return "success--text";
        } else if (this.company.supportText == "Time Bank Support") {
          return "warning--text";
        } else if (this.company.supportText) {
          return "info--text";
        } else {
          return "danger--text";
        }
      }

      return "";
    },
    numberOfLinkedServices() {
      var count = 0;

      if (this.company.zohoDeskId && this.company.zohoDeskId != "") {
        count++;
      }

      if (this.company.zohoCRMId && this.company.zohoCRMId != "") {
        count++;
      }

      if (
        this.paxLinks &&
        this.paxLinks.payload &&
        this.paxLinks.payload.length > 0
      ) {
        count++;
      }

      if (this.company.huduId && this.company.huduId != "") {
        count++;
      }

      if (this.ninjaLinkCount > 0) {
        count++;
      }

      if (this.xeroLinkCount > 0) {
        count++;
      }

      return count;
    },
  },
  created() {
    this.getCompany();
    this.chargeItemDateChange("this");
    this.getCompanyPaxLinks();

    this.$courier.SystemUsers.getData(1, 500, '').then(users => {
      if (users && users.payload) {
        this.users = users.payload;
      }
    })

    if (this.$route.params.isNew) {
      this.currentTab = "settings";
    }
  },
  methods: {
    showDomainEmailDialog() {
      if (this.$store.getters.hasScope('Domain.Modify') || this.$store.getters.hasScope('Email.Modify')) {
        this.showCompanyDomains = true;
      }
    },
    showResync() {
      this.currentTab = 'ticket-resync';
    },
    async saveProductChanges() {
      //do the link changes

      var allPromises = [];

      //remove all the existing links

      for (const linkId of this.initialEditProductLinks.map((x) => x.id)) {
        var delLinkPromise = new Promise(async (resolve, reject) => {
          var prod = await this.$courier.Products.getById(linkId);
          console.log(prod);

          prod.parentProductId = null;

          await this.$courier.Products.updateById(prod.id, prod);
          resolve();
        });

        allPromises.push(delLinkPromise);
      }

      //settle promises

      if (allPromises && allPromises.length > 0) {
        console.log("about to save promises");
        await Promise.allSettled(allPromises);
        console.log("saved promises");

        allPromises = [];
      }

      //now add the links

      for (const linkId of this.editProductLinks) {
        var addLinkPromise = new Promise(async (resolve, reject) => {
          var product = await this.$courier.Products.getById(linkId);

          product.parentProductId = this.editProduct.id;

          await this.$courier.Products.updateById(product.id, product);

          resolve();
        });

        allPromises.push(addLinkPromise);
      }

      //settle promises

      if (allPromises && allPromises.length > 0) {
        await Promise.allSettled(allPromises);
      }

      // if (this.finalisedEditProduct.defaultXeroAccountCodeId && this.finalisedEditProduct.defaultXeroTenantId && this.finalisedEditProduct.defaultXeroAccountCodeId != '00000000-0000-0000-0000-000000000000') {
      //   this.finalisedEditProduct.defaultXeroAccountCode = await this.$courier.XeroAccountExternal.getById(this.finalisedEditProduct.defaultXeroAccountCodeId, [{
      //     key: 'tenantId',
      //     value: this.finalisedEditProduct.defaultXeroTenantId
      //   }]);
      // }

      await this.$courier.Products.updateById(
        this.finalisedEditProduct.id,
        this.finalisedEditProduct
      );

      this.editProduct = {};
      this.finalisedEditProduct = {};
      this.currentTab = "company-products";
      this.$eventBus.$emit("company-product-table-refresh");
    },
    productEdited(editedProduct) {
      this.finalisedEditProduct = editedProduct;
    },
    showEditProduct(product) {
      this.$courier.Products.withId(product.id)
        .LinkedProducts.getData(1, 9999, "")
        .then((linkedProductResp) => {
          this.initialEditProductLinks = linkedProductResp.payload;
          this.editProductLinks = JSON.parse(
            JSON.stringify(this.initialEditProductLinks)
          );

          this.editProduct = product;
          this.currentTab = "company-product-edit";
        });
    },
    editApproval(approvalId) {
      this.editApprovalId = approvalId;
      this.currentTab = "company-approvals-add";
    },
    deletedContract() {
      this.$eventBus.$emit("company-contract-products-refresh");
    },
    refreshCompany() {
      this.getCompany();
      this.getCompanyPaxLinks();
      this.getNinjaLinks();
      this.getXeroLinks();
    },
    async getXeroLinks() {
      var p = await this.$courier.Companies.withId(
        this.$route.params.companyId
      ).XeroLinks.getData(1, 0, "");

      this.xeroLinkCount = p.totalCount;
    },
    async getNinjaLinks() {
      var p = await this.$courier.Companies.withId(
        this.$route.params.companyId
      ).NinjaLinks.getData(1, 0, "");

      this.ninjaLinkCount = p.totalCount;
    },
    async getCompanyPaxLinks() {
      return this.$courier.Companies.withId(this.$route.params.companyId)
        .PaxLinks.getData(1, 100, "")
        .then((paxLinks) => {
          this.paxLinks = paxLinks;
        });
    },
    async getCompanyPaxLinks() {
      return this.$courier.Companies.withId(this.$route.params.companyId)
        .PaxLinks.getData(1, 100, "")
        .then((paxLinks) => {
          this.paxLinks = paxLinks;
        });
    },
    async getCompanyTicketStatuses() {
      return this.$courier.Companies.withId(this.$route.params.companyId)
        .ZohoTickets.GetTicketStatuses()
        .then((statuses) => {
          this.ticketStatuses = statuses;
        });
    },
    saveEditDetails() {
      //save the department stuff

      if(this.companyBeingEdited.department.includes('Technical')){
        this.companyBeingEdited.defaultXeroTenantId = this.$store.getters.getXeroProfiles.filter(x => x.organisationName.includes('Technical'))[0].tenantId;
        this.companyBeingEdited.zohoDepartmentId = '37793000000171852';
      }else{
        this.companyBeingEdited.defaultXeroTenantId = this.$store.getters.getXeroProfiles.filter(x => x.organisationName.includes('Solutions'))[0].tenantId;
        this.companyBeingEdited.zohoDepartmentId = '37793000000007061';
      }

      this.$courier.Companies.updateById(
        this.$route.params.companyId,
        this.companyBeingEdited
      ).then(() => {
        this.showEditDialog = false;
        this.companyBeingEdited = {};
        this.getCompany();
      });
    },
    cancelEditDetails() {
      this.companyBeingEdited = {};
      this.showEditDialog = false;
    },
    showEditCompany() {
      this.companyBeingEdited = JSON.parse(JSON.stringify(this.company));
      this.showEditDialog = true;
    },
    deletedDomain(id) {
      this.$courier.Domains.removeById(id).then(() => {
        this.getCompanyDomains().then(() => {
          this.$eventBus.$emit("company-domain-table-refresh");
          this.currentDomainTab = "table";
        });
      });
    },
    deleteEmail(id) {
      this.$courier.Emails.removeById(id).then(() => {
        this.getCompanyEmails().then(() => {
          this.$eventBus.$emit("company-email-table-refresh");
          this.currentDomainTab = "email";
        });
      });
    },
    addNewEmail() {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .Emails.add({
          email: this.newEmail,
        })
        .then(() => {
          this.getCompanyDomains().then(() => {
            this.$eventBus.$emit("company-email-table-refresh");
            this.newEmail = "";
            this.currentDomainTab = "email";
          });
        });
    },
    addNewDomain() {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .Domains.add({
          domain: this.newDomain,
        })
        .then(() => {
          this.getCompanyDomains().then(() => {
            this.$eventBus.$emit("company-domain-table-refresh");
            this.newDomain = "";
            this.currentDomainTab = "table";
          });
        });
    },
    async getCompanyDomains() {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .Domains.getData(1, 99, "")
        .then((x) => {
          this.companyDomains = x.payload;
        });
    },
    async getCompanyEmails() {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .Emails.getData(1, 99, "")
        .then((x) => {
          this.companyEmails = x.payload;
        });
    },
    chargeItemDateChange($event) {
      var today = new Date();

      if ($event == "this") {
        this.chargeItemDateStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        this.chargeItemDateEnd = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
      } else if ($event == "last") {
        this.chargeItemDateStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        this.chargeItemDateEnd = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
      } else {
        this.chargeItemDateStart = utilities.getMinDateFromArray($event);
        this.chargeItemDateEnd = utilities.getMaxDateFromArray($event);
      }
    },
    calculateChargeItemCount() {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .ChargeItems.getData(
          1,
          9999,
          "",
          this.chargeItemDateStart,
          this.chargeItemDateEnd
        )
        .then((x) => {
          //calc sum

          if (x.payload && x.payload.length > 0) {
            this.totalChargeItemsCost = x.payload
              .map((s) => s.costPrice)
              .reduce((a, b) => a + b);
            this.totalChargeItemsRetailCost = x.payload
              .map((s) => s.retailPrice)
              .reduce((a, b) => a + b);
          } else {
            this.totalChargeItemsCost = 0;
            this.totalChargeItemsRetailCost = 0;
          }

          if (this.totalChargeItemsCost) {
            this.totalChargeItemsCost =
              Math.round(this.totalChargeItemsCost * 100) / 100;
          }

          if (this.totalChargeItemsRetailCost) {
            this.totalChargeItemsRetailCost =
              Math.round(this.totalChargeItemsRetailCost * 100) / 100;
          }
        });
    },
    addedProduct() {
      this.currentTab = "company-products";
      this.$eventBus.$emit("company-product-table-refresh");
    },
    addedNewContact() {
      this.currentTab = "company-contacts";
      this.$eventBus.$emit("company-contacts-list-refresh");
    },
    addedSite() {
      this.currentTab = "company-sites";
      this.$eventBus.$emit("company-sites-list-refresh");
    },
    addedProdSkel() {
      this.currentTab = "company-product-skeletons";
      this.$eventBus.$emit("company-product-skeleton-table-refresh");
    },
    addedContract() {
      this.currentTab = "company-contracts";
      this.$eventBus.$emit("company-contract-table-refresh");
    },
    async getCompanyTicketCountByStatus(status) {
      this.$courier.Companies.withId(this.$route.params.companyId)
        .ZohoTickets.getData(
          1,
          1,
          "",
          this.startOfCurrentMonth,
          this.endOfCurrentMonth,
          [{ key: "status", value: status }]
        )
        .then((data) => {
          this.ticketStatusData.push({
            status: status,
            count: data.totalCount,
          });
        });
    },
    async getCompany() {
      this.$courier.Companies.getById(this.$route.params.companyId).then(
        (company) => {
          this.company = company;

          if (this.company && this.company.accountManagerId) {
            this.$courier.SystemUsers.getById(this.company.accountManagerId).then(user => {
              this.accountManager = user;
            })
          }
        }
      );

      this.$courier.Companies.withId(this.$route.params.companyId)
        .Domains.getData(1, 99, "")
        .then((x) => {
          this.companyDomains = x.payload;
        });

      this.getCompanyDomains();
      this.getCompanyEmails();
    },
  },
  data() {
    return {
      users: [],
      accountManager: {},
      resyncDates: [],
      isShowResync: false,
      doneResync: false,
      resyncTickets: [],
      initialEditProductLinks: [],
      editProductLinks: [],
      editProduct: {},
      finalisedEditProduct: {},
      editApprovalId: "",
      paxLinks: [],
      ninjaLinkCount: 0,
      xeroLinkCount: 0,
      showEditDialog: false,
      companyDomains: [],
      companyEmails: [],
      chargeItemDateStart: "",
      chargeItemDateEnd: "",
      totalChargeItemsCost: 0,
      totalChargeItemsRetailCost: 0,
      currentTab: "dashboard",
      company: {},
      companyBeingEdited: {},
      showCompanyDomains: false,
      currentDomainTab: "table",
      newDomain: "",
      newEmail: "",
      bigTabs: "",
    };
  },
};
</script>

<style></style>
