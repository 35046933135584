<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="success--text">
                Currently Set Address
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="company.firstLine">
            <v-list-item-content>
              <v-list-item-title>
                {{ company.firstLine }}
              </v-list-item-title>
              <v-list-item-subtitle> First Line </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="company.secondLine">
            <v-list-item-content>
              <v-list-item-title>
                {{ company.secondLine }}
              </v-list-item-title>
              <v-list-item-subtitle> Second Line </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="company.town">
            <v-list-item-content>
              <v-list-item-title>
                {{ company.town }}
              </v-list-item-title>
              <v-list-item-subtitle> Town </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="company.county">
            <v-list-item-content>
              <v-list-item-title>
                {{ company.county }}
              </v-list-item-title>
              <v-list-item-subtitle> County </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="company.postCode">
            <v-list-item-content>
              <v-list-item-title>
                {{ company.postCode }}
              </v-list-item-title>
              <v-list-item-subtitle> Post Code </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text">
                Address In Zoho CRM
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="zohoAddress.firstLine">
            <v-list-item-content>
              <v-list-item-title>
                {{ zohoAddress.firstLine }}
              </v-list-item-title>
              <v-list-item-subtitle> First Line </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="zohoAddress.secondLine">
            <v-list-item-content>
              <v-list-item-title>
                {{ zohoAddress.secondLine }}
              </v-list-item-title>
              <v-list-item-subtitle> Second Line </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="zohoAddress.town">
            <v-list-item-content>
              <v-list-item-title>
                {{ zohoAddress.town }}
              </v-list-item-title>
              <v-list-item-subtitle> Town </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="zohoAddress.county">
            <v-list-item-content>
              <v-list-item-title>
                {{ zohoAddress.county }}
              </v-list-item-title>
              <v-list-item-subtitle> County </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="zohoAddress.postCode">
            <v-list-item-content>
              <v-list-item-title>
                {{ zohoAddress.postCode }}
              </v-list-item-title>
              <v-list-item-subtitle> Post Code </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="syncAddress" color="warning">
          <v-icon class="mr-2"> mdi-sync </v-icon>
          Resync Address
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getCompany();
  },
  methods: {
    syncAddress() {
      this.company.firstLine = this.zohoAddress.firstLine;
      this.company.secondLine = this.zohoAddress.secondLine;
      this.company.town = this.zohoAddress.town;
      this.company.county = this.zohoAddress.county;
      this.company.postCode = this.zohoAddress.postCode;

      this.$courier.Companies.withId(this.companyId)
        .updateById(this.companyId, this.company)
        .then(() => {
          this.$emit("update-company");
          //do nothing else? not sure
        });
    },
    async getCompany() {
      this.$courier.Companies.withId(this.companyId)
        .getById(this.companyId)
        .then((company) => {
          this.company = company;

          this.getZohoCompany();
        });
    },
    async getZohoCompany() {
      this.$courier.ZohoCRMExternal.getById(this.company.zohoCRMId).then(
        (zohoCompany) => {
          if (zohoCompany) {
            console.log("got zoho company");
            this.zohoCompany = zohoCompany;

            var address = this.getAddressFromZoho(zohoCompany);

            if (address) {
              this.zohoCompany.firstLine = address.firstLine;
              this.zohoCompany.secondLine = address.secondLine;
              this.zohoCompany.town = address.town;
              this.zohoCompany.county = address.county;
              this.zohoCompany.postCode = address.postCode;

              this.zohoAddress = address;
            }
          }
        }
      );
    },
    getAddressFromZoho(zohoCompany) {
      if (zohoCompany) {
        return {
          firstLine: this.getVal(
            zohoCompany.billing_Street,
            zohoCompany.shipping_Street
          ),
          secondLine: "",
          town: this.getVal(
            zohoCompany.billing_City,
            zohoCompany.shipping_City
          ),
          county: this.getVal(
            zohoCompany.billing_State,
            zohoCompany.shipping_State
          ),
          postCode: this.getVal(
            zohoCompany.billing_Code,
            zohoCompany.shipping_Code
          ),
        };
      } else {
        return null;
      }
    },
    getVal(value, otherVal) {
      return value ? value : otherVal ? otherVal : "";
    },
  },
  data() {
    return {
      company: {},
      zohoCompany: {},
      zohoAddress: {},
    };
  },
};
</script>

<style>
</style>