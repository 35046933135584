<template>
  <div>
    <trev-cool-table-base
      title="Contractual Products"
      @search-updated="searchUpdated"
      @pagination-updated="paginationUpdated"
      :headers="headers"
      :items="computedData"
      :totalRecordCount="data.length"
      :isLoading="isLoading"
      @get-data="getData()"
    >
      <template v-slot:item.contractName="{ item }">
        <v-chip label>
          {{ item.contract.name }}
        </v-chip>
      </template>
      <template v-slot:item.division="{ item }">
        {{ item.product.division }}
      </template>
      <template v-slot:item.effectiveDate="{ item }">
        {{
          new Date(item.contract.effectiveDate).getFullYear() != 1
            ? new Date(item.contract.effectiveDate).toLocaleDateString("en-GB")
            : ""
        }}
      </template>
      <template v-slot:item.expiryDate="{ item }">
        {{
          new Date(item.contract.expiryDate).getFullYear() != 1
            ? new Date(item.contract.expiryDate).toLocaleDateString("en-GB")
            : ""
        }}
      </template>
      <template v-slot:item.productName="{ item }">
        {{ item.product.name }}
      </template>
      <template v-slot:item.productQuantity="{ item }">
        <v-chip>
          {{ item.product.quantity }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="info"
          small
          class="mr-2"
          @click="duplicateContractProductToNon(item)" v-if="$store.getters.hasScope('Contract.Modify')"
        >
          Change Quantity <v-icon class="ml-2"> mdi-counter </v-icon>
        </v-btn>
        <v-btn color="primary" small :to="{
          name: 'Contract View',
          params: {
            contractId: item.contract.id
          }
        }">
          View Contract
          <v-icon class="ml-2">
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>
    </trev-cool-table-base>
    <v-dialog v-model="showChangeQuantity" width="50vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Change Quantities of {{ selectedProd.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  type="number"
                  :label="
                    'Change Quantity (' +
                    selectedProdOriginalQuantity +
                    ' in contract)'
                  "
                  @change="keepMin"
                  v-model="selectedProd.quantity"
                ></v-text-field>
                <v-text-field label="Cost" v-model="selectedProd.totalCost">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6"
                  >Suggested Cost
                  <span class="success--text text-center"
                    >£{{ totalCost }}</span
                  ></span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              showChangeQuantity = false;
              selectedProd = {};
            "
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveQuantityChanges">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTableBase from "../tables/trev-cool-table-base.vue";
import { paginate as PageData } from "../../utilities/GeneralUtilities";
import * as prodUtils from "../../utilities/Object Utilities/Products";
import TrevProductViewList from "./trev-product-view-list.vue";
import DialogDatePicker from "../base/dialogDatePicker.vue";
export default {
  components: { trevCoolTableBase, TrevProductViewList, DialogDatePicker },
  props: {
    companyId: {
      type: String,
      default: "",
    },
    tableId: {
      type: String,
      default: "",
    },
  },
  computed: {
    totalCost() {
      return prodUtils.calculatePriceFromRules(
        this.selectedProd.quantity,
        this.selectedProd.price,
        this.selectedProd.priceRules
      );
    },
    computedData() {
      this.isLoading = true;
      var innerData = this.data.filter(
        (x) =>
          (x.contract.name &&
            x.contract.name.toLowerCase().includes(this.search)) ||
          (x.product.skuNumber &&
            x.product.skuNumber.toLowerCase().includes(this.search)) ||
          (x.product.name && x.product.name.toLowerCase().includes(this.search))
      );
      var rData = PageData(innerData, this.pageSize, this.pageNumber);
      this.isLoading = false;
      return rData;
    },
  },
  created() {
    this.getData();

    this.$eventBus.$on(this.tableId + "-refresh", () => {
      this.getData();
    });
  },
  methods: {
    keepMin(){
      if(this.selectedProd.quantity < this.selectedProdOriginalQuantity)
      {
        this.selectedProd.quantity = this.selectedProdOriginalQuantity;
      }
    },
    saveQuantityChanges() {
      //remove all the meta data which has pax product links to it
      if (this.selectedProd.metaData && this.selectedProd.metaData.length > 0) {
        this.selectedProd.metaData = prodUtils.removePaxMetaData(
          this.selectedProd.metaData
        );
      }

      //remove the id's from meta data, priceRules and main obj and add into db

      delete this.selectedProd.id;

      if (this.selectedProd.metaData && this.selectedProd.metaData.length > 0) {
        for (const metaData of this.selectedProd.metaData) {
          delete metaData.id;
        }
      }

      if (
        this.selectedProd.priceRules &&
        this.selectedProd.priceRules.length > 0
      ) {
        for (const priceRule of this.selectedProd.priceRules) {
          delete priceRule.id;
        }
      }

      delete this.selectedProd.parentContractId;

      this.$courier.Products.add(this.selectedProd).then(() => {
        this.selectedProd = {};
        this.selectedProdOriginalQuantity = 0;
        this.showChangeQuantity = false;
        this.$emit("added-product");
      });
    },
    async duplicateContractProductToNon(prod) {
      this.selectedProd = JSON.parse(JSON.stringify(prod.product));
      this.selectedProdOriginalQuantity = this.selectedProd.quantity;

      try {
        var priceResponse = await this.$courier.Products.withId(
          prod.product.id
        ).PriceRules.getData(1, 9999, "");

        this.selectedProd.priceRules = priceResponse.payload;

        var metaResponse = await this.$courier.Products.withId(
          prod.product.id
        ).MetaData.getData(1, 9999, "");

        this.selectedProd.metaData = metaResponse.payload;

        this.showChangeQuantity = true;
      } catch (exception) {
        //do something with the error?

        console.log(exception);
      }
    },
    searchUpdated(pageData) {
      this.isLoading = true;
      this.pageSize = pageData.pageSize;
      this.pageNumber = pageData.pageNumber;
      this.search = pageData.search;
      this.isLoading = false;
    },
    paginationUpdated(pageData) {
      this.isLoading = true;
      this.pageSize = pageData.pageSize;
      this.pageNumber = pageData.pageNumber;
      this.search = pageData.search;
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      this.data = [];
      var c = await this.$courier.Companies.withId(
        this.companyId
      ).Contracts.getData(1, 9999, "");
      this.allContracts = c.payload;

      var listOfProductTasks = [];

      this.allContracts.forEach((contract) => {
        var task = this.$courier.Contracts.withId(contract.id)
          .Products.getData(1, 9999, "")
          .then((x) => {
            if (x && x.payload) {
              x.payload.forEach((prod) => {
                this.data.push({ contract: contract, product: prod });
              });
            }
          });
        listOfProductTasks.push(task);
      });

      await Promise.all(listOfProductTasks);
      this.isLoading = false;
    },
  },
  data() {
    return {
      allContracts: [],
      headers: [
        {
          text: "Contract",
          value: "contractName",
        },
        {
          text: "Division",
          value: "division",
        },
        {
          text: "Product",
          value: "productName",
        },
        {
          text: "Quantity",
          value: "productQuantity",
        },
        {
          text: "Effective Date",
          value: "effectiveDate",
        },
        {
          text: "Expiry Date",
          value: "expiryDate",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      data: [],
      pageNumber: 1,
      pageSize: 10,
      search: "",
      isLoading: false,
      selectedProd: {},
      selectedProdOriginalQuantity: 0,
      showChangeQuantity: false,
    };
  },
};
</script>

<style>
</style>