<template>
  <div>
    <v-row>
      <v-col>
        <h4>
          Customise:
          <span class="info--text">{{ productSkeleton.name }}</span>
        </h4>
        <p class="muted--text">
          Description:
          <span class="info--text">{{ productSkeleton.description }}</span>
        </p>
      </v-col>
      <v-col>
        <div class="float-right">
          <slot name="top-right"> </slot>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <base-card>
          <trev-product-skeleton-view-card
            title="Selected Product"
            :productSkeleton="productSkeleton"
          ></trev-product-skeleton-view-card>
        </base-card>
      </v-col>
      <v-col cols="6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Basic Details </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-product-basic-details
                :description.sync="newProdSkel.description"
                :division.sync="newProdSkel.division"
                :name.sync="newProdSkel.name"
                :skuNumber.sync="newProdSkel.skuNumber"
                :supplier.sync="newProdSkel.supplier"
                :resourceType.sync="newProdSkel.resourceType"
                :xeroAccountCodeId.sync="newProdSkel.defaultXeroAccountCodeId"
                :xeroTenantId.sync="newProdSkel.defaultXeroTenantId"
              >
              </trev-product-basic-details>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Linked Products
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-linked-product-skeleton-manage
                v-if="isProductSkeleton"
                :companyId="newProdSkel.parentCompanyId"
                :ProductSkeletonId="newProdSkel.id"
                :defaultSelectedProductSkeletons.sync="internalLinks"
              ></trev-linked-product-skeleton-manage>
              <trev-linked-products-manage
                v-if="isProduct"
                :companyId="newProdSkel.parentCompanyId"
                :productId="newProdSkel.id"
                :defaultSelectedProducts.sync="internalLinks"
              ></trev-linked-products-manage>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Charge Settings
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-product-price-settings
                :price.sync="newProdSkel.price"
                :chargeFrequency.sync="newProdSkel.chargeFrequency"
                :unitCharged.sync="newProdSkel.unitCharged"
              >
              </trev-product-price-settings>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Alert Settings
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-product-alert-settings
                :alertGroup.sync="newProdSkel.alertGroup"
                :alertOffSetInDays.sync="newProdSkel.alertOffsetInDays"
                :hasAlert.sync="newProdSkel.hasAlerts"
              >
              </trev-product-alert-settings>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Price Rules </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-product-price-rules
                :hasPriceRules.sync="newProdSkel.hasRules"
                :unitCharged="newProdSkel.unitCharged"
                :priceRules.sync="newProdSkel.priceRules"
              >
              </trev-product-price-rules>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Meta Data </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trev-product-metadata
                :tags.sync="newProdSkel.metaData"
              ></trev-product-metadata>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="3">
        <base-card>
          <trev-product-skeleton-view-card
            :productSkeleton="newProdSkel"
            textColor="success"
            title="Customised Product"
          ></trev-product-skeleton-view-card>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrevProductAlertSettings from "./forms/trev-product-alert-settings.vue";
import trevProductBasicDetails from "./forms/trev-product-basic-details.vue";
import TrevProductMetadata from "./forms/trev-product-metadata.vue";
import TrevProductPriceRules from "./forms/trev-product-price-rules.vue";
import TrevProductPriceSettings from "./forms/trev-product-price-settings.vue";
import TrevLinkedProductSkeletonManage from "./productskeletons/trev-linked-product-skeleton-manage.vue";
import TrevProductSkeletonViewCard from "./productskeletons/trev-product-skeleton-view-card.vue";
import TrevLinkedProductsManage from "./trev-linked-products-manage.vue";
export default {
  components: {
    trevProductBasicDetails,
    TrevProductAlertSettings,
    TrevProductPriceSettings,
    TrevProductPriceRules,
    TrevProductSkeletonViewCard,
    TrevProductMetadata,
    TrevLinkedProductsManage,
    TrevLinkedProductSkeletonManage,
  },
  props: {
    isProductSkeleton: {
      type: Boolean,
      default: true,
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    productSkeleton: {
      type: Object,
      default: () => {},
    },
    links: {
      type: Array,
      default: () => [],
    },
    returnWithId: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    internalLinks(newVal){
      this.$emit('update:links', newVal);
    },   
    "newProdSkel.hasRules"() {
      if (!this.newProdSkel.hasRules) {
        this.newProdSkel.priceRules = [];
      } else {
        var selected = JSON.parse(JSON.stringify(this.productSkeleton));

        this.newProdSkel.priceRules = selected.priceRules;

        if (
          this.newProdSkel.priceRule &&
          this.newProdSkel.priceRules.length > 0
        ) {
          this.newProdSkel.priceRules.forEach((rule) => (rule.id = ""));
        }
      }
    },
    newProdSkel: {
      handler(n, o) {
        var selected = JSON.parse(JSON.stringify(this.productSkeleton));
        var newOne = JSON.parse(JSON.stringify(this.newProdSkel));

        selected = this.stripProductSkeleton(selected);
        newOne = this.stripProductSkeleton(newOne);
        if (JSON.stringify(selected) === JSON.stringify(newOne)) {
          this.$emit("products-are-equal");
        } else {
          this.$emit("products-are-not-equal");
        }

        this.$emit("product-updated", newOne);
      },
      deep: true,
      immediate: true,
    },
    productSkeleton: {
      handler(n, o) {
        if(!this.hasLoadedOnce && n){
          this.getLinks();
          this.hasLoadedOnce = true;
        }
        var prod = JSON.parse(JSON.stringify(this.productSkeleton));

        this.newProdSkel = this.stripProductSkeleton(prod);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // async deleteLink(id) {
    //   if (this.isProduct) {
    //     var prod = await this.$courier.Products.getById(id);
    //     prod.parentProductId = null;
    //     await this.$courier.Products.updateById(prod.id, prod);
    //   }

    //   if (this.isProductSkeleton) {
    //     await this.$courier.ProductSkeletons.withId(
    //       this.productSkeleton.id
    //     ).LinkedProductSkeletons.removeById(id);
    //   }
    // },
    // async addLink(childLinkId) {
    //   if(this.isProduct){
    //     var prod = await this.$courier.Products.getById(id);
    //     prod.parentProductId = childLinkId;

    //     await this.$courier.Products.updateById(prod.id, prod);
    //   }

    //   if(this.isProductSkeleton){
    //     var childProductSkeleton = await this.$courier.ProductSkeletons.getById(childLinkId);
    //     await this.$courier.ProductSkeletons.withId(this.productSkeleton.id).LinkedProductSkeletons.add(childProductSkeleton);
    //   }
    // },
    async getLinks() {
      if (this.isProduct) {
        this.$courier.Products.withId(this.productSkeleton.id)
          .LinkedProducts.getData(1, 9999, "")
          .then((linkedProductresponse) => {
            this.internalLinks = linkedProductresponse.payload;
            this.initialLinks = JSON.parse(JSON.stringify(this.internalLinks));
          });
      }

      if (this.isProductSkeleton) {
        this.$courier.ProductSkeletons.withId(this.productSkeleton.id)
          .LinkedProductSkeletons.getData(1, 9999, "")
          .then((linkedProductSkeletonResponse) => {
            this.internalLinks = linkedProductSkeletonResponse.payload;
            this.initialLinks = JSON.parse(JSON.stringify(this.internalLinks));
          });
      }
    },
    stripProductSkeleton(prodSkel) {
      if (!this.returnWithId) {
        prodSkel.id = "";
      }

      if (prodSkel.priceRules && prodSkel.priceRules.length > 0) {
        prodSkel.priceRules.forEach((priceRule) => {
          priceRule.id = "";
        });
      }

      return prodSkel;
    },
  },
  data: () => ({
    newProdSkel: {},
    initialLinks: [],
    internalLinks: [],
    hasLoadedOnce: false,
  }),
};
</script>

<style></style>
