import * as dateUtils from 'date-fns';

export const getStatusColour = (status) => {
    if (status) {
        if (status == 'Open') {
            return 'danger';
        } else if (status == 'In Progress') {
            return 'purple';
        } else if (status == 'On Hold') {
            return 'blue-gray';
        } else if (status == 'Complete') {
            return 'success'
        }
    }

    return '';
}

export const convertJobToInvoice = async (courier, job) => {
    var invoice = {};

    if (job.poNumber) {
        invoice.reference = job.poNumber;
    } else {
        invoice.reference = 'Not Set';
    }

    invoice.contactId = '';

    
    invoice.date = new Date();
    invoice.dueDate = dateUtils.addWeeks(invoice.date, 2);

    //get the line items

    var jobLineItems = [];

    var response = await courier.Jobs.withId(job.id).LineItems.getData(1, 200, '');    

    if (response && response.payload) {
        jobLineItems = response.payload;
    }

    if (jobLineItems) {
        invoice.lineItems = [];

        for (const jobLineItem of jobLineItems) {
            invoice.lineItems.push({
                accountCodeId: 'dd74964a-53b7-42f5-99ee-73f08541bd3f',
                description: jobLineItem.description,
                name: jobLineItem.description,
                quantity: jobLineItem.quantity,
                unitCost: jobLineItem.price,
                accountCode: '200',
                accountCodeName: 'Sales',
                taxRate: 20
            });
        }
    }

    return invoice;
}