<template>
  <div>
    <trev-cool-table
      :headers="headers"
      :courier="$courier.Companies.withId(companyId).Contracts"
      title="Contracts"
      :tableId="uniqueTableId"
      :showAddLinkAsButton="true"
      @add-clicked="$emit('add-contract')"
    >
      <template v-slot:item.name="{ item }">
        <v-chip color="info" label>
          {{ item.name }}
        </v-chip>
      </template>
      <template v-slot:item.effectiveDate="{ item }">
        {{ new Date(item.effectiveDate).toISOString().split("T")[0] }}
      </template>
      <template v-slot:item.expiryDate="{ item }">
        {{ new Date(item.expiryDate).toISOString().split("T")[0] }}
      </template>
      <template v-slot:item.hasBreakClause="{ item }">
        <span v-if="item.hasBreakClause">
          Break Clause at {{ item.breakClauseInDaysFromEffectiveDate }} from
          {{ new Date(item.effectiveDate).toISOString().split("T")[0] }}
        </span>
        <span v-else> No Break Clause </span>
      </template>
      <template v-slot:item.hasAlerts="{ item }">
        <span v-if="item.hasAlerts">
          Will Alert at {{ item.alertOffsetInDays }} to {{ item.alertGroup }}
        </span>
        <span v-else> No Alerting </span>
      </template>
      <template v-slot:item.price="{ item }"> £{{ item.price }} </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          small
          class="white--text mr-2"
          :to="{ name: 'Contract View', params: { contractId: item.id } }"
        >
          View
          <v-icon class="ml-2"> mdi-magnify </v-icon>
        </v-btn>
        <v-btn
          color="danger"
          class="white--text"
          small
          @click="
            contractSelected = item;
            showConfirmDelete = true;
          "
          v-if="$store.getters.hasScope('Contact.Delete')"
        >
          <v-icon> mdi-delete-outline </v-icon>
          Delete
        </v-btn>
      </template>
    </trev-cool-table>
    <v-dialog v-model="showConfirmDelete" max-width="80vh">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete {{ contractSelected.name }}?
        </v-card-title>
        <v-card-actions>
          <v-btn color="default" @click="showConfirmDelete = false">
            No, Keep {{ contractSelected.name }}
          </v-btn>

          <v-btn
            color="warning"
            class="white--text"
            @click="deleteContractWithoutProducts"
          >
            <v-icon> mdi-delete-outline </v-icon>
            Delete, Keep Products
          </v-btn>
          <v-btn color="danger" class="white--text" @click="deleteContract">
            <v-icon> mdi-delete-outline </v-icon>
            Delete, with Products
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../tables/trev-cool-table.vue";

export default {
  components: { trevCoolTable },
  computed: {
    uniqueTableId() {
      if (this.tableId) {
        return this.tableId;
      } else {
        return "sometable-contracts-list";
      }
    },
  },
  props: {
    tableId: {
      type: String,
      default: "",
    },
    companyId: {
      type: String,
      default: "",
    },
    contractProductsTableId: {
      type: String,
      default: "",
    },
  },
  methods: {
    async deleteContractWithoutProducts() {
      //get the products, remove the link to the contract and update to db - then remove the contract

      var productsResp = await this.$courier.Contracts.withId(
        this.contractSelected.id
      ).Products.getData(1, 9999, "");

      if (
        productsResp &&
        productsResp.payload &&
        productsResp.payload.length > 0
      ) {
        var allPromises = [];

        for (const prod of productsResp.payload) {
          if (prod.parentContractId) {
            delete prod.parentContractId;

            //return and update?
            var innerPromise = this.$courier.Products.updateById(prod.id, prod).then(() => {
              //idk do something here?
            });

            allPromises.push(innerPromise);
          }
        }

        await Promise.all(allPromises);
      }

      //now delete the contract
      await this.$courier.Contracts.removeById(this.contractSelected.id);

      this.$eventBus.$emit(this.uniqueTableId + "-refresh");
      this.$emit("contract-removed", this.contractSelected);
      this.showConfirmDelete = false;
      this.contractSelected = {};
      this.$eventBus.$emit(this.contractProductsTableId + "-refresh");
    },
    deleteContract() {
      //get the contract and delete all it's products

      this.$courier.Contracts.withId(this.contractSelected.id)
        .Products.getData(1, 9999, "")
        .then((x) => {
          x.payload.forEach((product) => {
            this.$courier.Products.removeById(product.id);
          });

          this.$courier.Contracts.removeById(this.contractSelected.id).then(
            () => {
              this.$eventBus.$emit(this.uniqueTableId + "-refresh");
              this.$emit("contract-removed", this.contractSelected);
              this.showConfirmDelete = false;
              this.contractSelected = {};
              this.$eventBus.$emit(this.contractProductsTableId + "-refresh");
            }
          );
        });
    },
  },
  data: function () {
    return {
      showConfirmDelete: false,
      contractSelected: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Effective Date",
          align: "start",
          value: "effectiveDate",
        },
        {
          text: "Expiry Date",
          align: "start",
          value: "expiryDate",
        },
        {
          text: "Break Clause",
          align: "start",
          value: "hasBreakClause",
        },
        {
          text: "Alerting",
          align: "start",
          value: "hasAlerts",
        },
        {
          text: "Price",
          align: "start",
          value: "price",
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>
