<template>
  <div>
    <div v-if="isUpdatingZohoCRM">
      <trev-zoho-crm-search v-model="foundZohoCRMCompany">
      </trev-zoho-crm-search>

      <v-btn
        @click="saveZohoCRMLink"
        type="success"
        :disabled="Object.keys(foundZohoCRMCompany).length === 0"
      >
        <v-icon class="mr-2"> mdi-save </v-icon>
        Save Zoho CRM Link
      </v-btn>
      <v-btn color="info" class="ml-2" @click="showAddInZoho = true">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create in Zoho
      </v-btn>
      <v-btn
        @click="changeZohoCRM = false"
        color="danger"
        text
        class="ml-2"
        v-if="company.zohoCRMId"
      >
        <v-icon class="mr-2"> mdi-close </v-icon>
        Cancel
      </v-btn>
      <v-dialog v-model="showAddInZoho" max-width="400px">
        <v-card>
          <v-card-title>
            <v-icon class="mr-2"> mdi-asterisk </v-icon>
            New Company In Zoho
          </v-card-title>
          <v-card-text>
            <v-text-field label="Name" v-model="newZohoCompanyName">
            </v-text-field>
            <v-btn color="info" @click="createCompanyInZoho">
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Create Company
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h4>
        {{ company.name }}
      </h4>
      <v-btn @click="changeZohoCRM = true" color="warning" small>
        <v-icon class="mr-2">mdi-pencil</v-icon>Change
      </v-btn>
    </div>
  </div>
</template>

<script>
import trevZohoCrmSearch from "../../../../../components/forms/trev-zoho-crm-search.vue";
export default {
  components: { trevZohoCrmSearch },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUpdatingZohoCRM() {
      return this.changeZohoCRM || !this.company.zohoCRMId;
    },
  },
  methods: {
    createCompanyInZoho() {
      this.$courier.ZohoCRMExternal.add({
        Account_Name: this.newZohoCompanyName,
      }).then((newZohoCompany) => {
        var address = this.getAddressFromZoho(newZohoCompany);

        if (address) {
          this.company.firstLine = address.firstLine;
          this.company.secondLine = address.secondLine;
          this.company.town = address.town;
          this.company.county = address.county;
          this.company.postCode = address.postCode;
        }

        this.company.zohoCRMId = newZohoCompany.id;

        this.$courier.Companies.updateById(this.company.id, this.company).then(
          () => {
            this.changeZohoDesk = false;
            this.$emit("update-company", this.company);
          }
        );
      });
    },
    saveZohoCRMLink() {
      this.company.zohoCRMId = this.foundZohoCRMCompany.id;

      //add the company address

      var address = this.getAddressFromZoho(this.foundZohoCRMCompany);

      if (address) {
        this.company.firstLine = address.firstLine;
        this.company.secondLine = address.secondLine;
        this.company.town = address.town;
        this.company.county = address.county;
        this.company.postCode = address.postCode;
      }

      this.$courier.Companies.updateById(this.company.id, this.company).then(
        () => {
          this.foundZohoCRMCompany = {};
          this.isShowZohoCRMLinkDialog = false;
          this.changeZohoCRM = false;
          //show success?
          //update linked services count?
          this.$emit("update-company", this.company);
        }
      );
    },
    getAddressFromZoho(zohoCompany) {
      if (zohoCompany) {
        return {
          firstLine: this.getVal(
            zohoCompany.billing_Street,
            zohoCompany.shipping_Street
          ),
          secondLine: "",
          town: this.getVal(
            zohoCompany.billing_City,
            zohoCompany.shipping_City
          ),
          county: this.getVal(
            zohoCompany.billing_State,
            zohoCompany.shipping_State
          ),
          postCode: this.getVal(
            zohoCompany.billing_Code,
            zohoCompany.shipping_Code
          ),
        };
      } else {
        return null;
      }
    },
    getVal(value, otherVal) {
      return value ? value : otherVal ? otherVal : "";
    },
  },
  data() {
    return {
      changeZohoCRM: false,
      foundZohoCRMCompany: {},
      showAddInZoho: false,
      newZohoCompanyName: this.company.name,
    };
  },
};
</script>

<style>
</style>