<template>
  <v-autocomplete
    :search-input.sync="huduSearch"
    :loading="huduIsLoading"
    v-model="selectedhuduCompany"
    hide-details
    :items="allFoundhuduCompanies"
    hide-no-data
    item-text="name"
    item-value="id"
    placeholder="Search Hudu (Exact Name)"
    prepend-icon="mdi-database-search"
    label="Search Hudu (Exact Name)"
    return-object
    class="mb-4"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    selectedhuduCompany() {
      this.$emit("input", this.selectedhuduCompany);
    },
    huduSearch() {
      if (this.huduIsLoading) {          
        return;
      } else {
        this.searchForhuduCompanies();
      }
    },
  },
  methods: {
    searchForhuduCompanies() {
      this.huduIsLoading = true;

      this.$courier.Hudu.getData(1, 20, this.huduSearch).then(
        (x) => {
          this.allFoundhuduCompanies = x;
          this.huduIsLoading = false;
        }
      );
    },
  },
  data: () => {
      return {
        huduSearch: "",
        huduIsLoading: false,
        selectedhuduCompany: {},
        allFoundhuduCompanies: [],
      };
    },
};
</script>

<style>
</style>