<template>
  <div>
    <trev-cool-table title="Product Skeletons" :courier="courier" :headers="tableHeaders" :tableId="uniqueTableId"
      :addNewLink="companyId ? null : { name: 'Add Product Skeleton' }" :showAddLinkAsButton="(companyId ? true : false) && $store.getters.hasScope('ProductSkeleton.Create')"
      @add-clicked="$emit('add-product-skeleton')" @loaded="getLinked" :additionalQueryStrings="getQueryStrings"
      :isExpandable="true" tableKey="id">
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <td v-if="hasChildProducts(item)" class="text-start">
          <v-tooltip top>
            <span>
              View Linked Products
            </span>
            <template #activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" icon @click="expand(!isExpanded)" class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container class="pt-0 pb-0">
            <v-row>
              <v-col class="pt-0">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>
                          SKU
                        </th>
                        <th>
                          Division
                        </th>
                        <th>
                          Name
                        </th>
                        <th>
                          Unit Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="linkedProduct in linkedSkelly.filter(x => x.parentProductSkeletonId == item.id)"
                        :key="linkedProduct.id">
                        <td>
                          <v-chip label small>
                            SKU:{{ linkedProduct.skuNumber }}
                          </v-chip>
                        </td>
                        <td>
                          {{ linkedProduct.division }}
                        </td>
                        <td>
                          {{ linkedProduct.name }}
                        </td>
                        <td>
                          £{{ linkedProduct.price }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      <template v-slot:item.price="{ item }">
        £{{ item.price }}
        <v-icon v-if="item.hasRules" class="pb-1 warning--text">
          mdi-exclamation
        </v-icon>
      </template>
      <template v-slot:item.alertGroup="{ item }">
        Alert <span class="primary--text">{{ item.alertGroup }} </span>
        <span class="primary--text">{{ item.alertOffsetInDays }}</span> Days
        Before expiry
      </template>
      <template v-slot:item.links="{ item }">
        <v-btn icon :disabled="(getLinkedCount(item) === 0)" @click="showLinks(item)">
          {{ getLinkedCount(item) }}
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ attrs, on }">
            <v-btn color="primary" small v-bind="attrs" v-on="on">
              Options <v-icon class="ml-2"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :to="{
              name: 'Product Skeleton View',
              params: { productSkeletonId: item.id },
            }">
              <v-list-item-title class="primary--text">
                <v-icon class="mr-2 primary--text"> mdi-magnify </v-icon>
                View Product Skeleton
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$store.getters.hasScope('ProductSkeleton.Modify')" :to="{
              name: 'Product Skeleton Edit',
              params: {
                productSkeletonId: item.id
              }
            }">
              <v-list-item-title>
                <v-icon class="mr-2">
                  mdi-pencil
                </v-icon>
                Edit Product Skeleton
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$store.getters.hasScope('ProductSkeleton.Modify')" :to="{
              name: 'Edit Global Product Skeleton',
              params: {
                productSkeletonId: item.id
              }
            }">
              <v-list-item-title class="warning--text">
                <v-icon class="mr-2 warning--text">mdi-earth-arrow-right</v-icon>
                Edit Across Products
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$store.getters.hasScope('ProductSkeleton.Delete')" @click="
              showConfirmDelete = true;
            selectedProd = item;
                          ">
              <v-list-item-title class="danger--text">
                <v-icon class="mr-2 danger--text"> mdi-delete </v-icon>
                Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-if="!companyId" v-slot:item.company="{ item }">
        <v-chip v-if="item.parentCompanyId"> Custom to Company </v-chip>
        <v-chip v-else> Global </v-chip>
      </template>
      <template v-slot:item.skuNumber="{ item }">
        <v-chip label color="info"> SKU:{{ item.skuNumber }} </v-chip>
      </template>
    </trev-cool-table>
    <v-dialog v-model="showConfirmDelete" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete {{ selectedProd.name }}?
        </v-card-title>
        <v-card-actions>
          <v-btn color="default" @click="showConfirmDelete = false">
            No, Keep {{ selectedProd.name }}
          </v-btn>

          <v-btn color="danger" class="white--text" @click="deleteProductSkeleton">
            <v-icon> mdi-delete-outline </v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showingLinked" max-width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Linked with {{ selectedSkeleton.skuNumber }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item v-for="(skelly) in getLinkedProductSkeletons(selectedSkeleton)" :key="skelly.id">
            <v-list-item-title>
              <v-chip label>
                {{ skelly.skuNumber }}
              </v-chip>
              - {{ skelly.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../../tables/trev-cool-table.vue";
export default {
  props: {
    companyId: {
      type: String,
      default: "",
    },
    tableId: {
      type: String,
      default: "",
    },
  },
  components: {
    trevCoolTable,
  },
  computed: {
    getQueryStrings() {
      if (this.companyId) {
        return [];
      } else {
        return [{ key: 'OnlyShowGlobal', value: 'true' }];
      }
    },
    tableHeaders() {
      if (!this.companyId) {
        return this.headers;
      } else {
        return this.headers.filter((x) => x.value != "company");
      }
    },
  },
  watch: {
    companyId: {
      handler(newValue, oldValue) {
        if (this.companyId) {
          this.courier = this.$courier.Companies.withId(
            this.companyId
          ).ProductSkeletons;
        } else {
          this.courier = this.$courier.ProductSkeletons;
        }

        this.uniqueTableId = this.tableId + "-product-skeleton-table";

        this.$eventBus.$on(this.tableId + "-refresh", () => {
          this.$eventBus.$emit(this.uniqueTableId + "-refresh");
        });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(this.tableId + "-product-skeleton-table-refresh");
  },
  methods: {
    hasChildProducts(parentProductSkeleton) {
      if (this.linkedSkelly.some(x => x.parentProductSkeletonId == parentProductSkeleton.id)) {
        return true;
      } else {
        return false;
      }
    },
    showLinks(skelly) {
      this.selectedSkeleton = skelly;
      this.showingLinked = true;
    },
    getLinkedCount(parentProduct) {
      return this.linkedSkelly.filter(x => x.parentProductSkeletonId == parentProduct.id).length;
    },
    getLinkedProductSkeletons(parentProduct) {
      return this.linkedSkelly.filter(x => x.parentProductSkeletonId == parentProduct.id);
    },
    getLinked(items) {
      for (const prod of items) {
        this.$courier.ProductSkeletons.withId(prod.id).LinkedProductSkeletons.getData(1, 50, '').then(resp => {
          if (resp && resp.payload) {
            for (const resProd of resp.payload) {
              if (!this.linkedSkelly.some(x => x.id == resProd.id)) {
                resProd.parentProductSkeletonId = prod.id;
                this.linkedSkelly.push(resProd);
              }
            }
          }
        })
      }
    },
    deleteProductSkeleton() {
      this.$courier.ProductSkeletons.removeById(this.selectedProd.id).then(
        (x) => {
          this.showConfirmDelete = false;
          this.selectedProd = {};
          this.$eventBus.$emit(this.uniqueTableId + "-refresh");
        }
      );
    },
  },
  data() {
    return {
      showingLinked: false,
      selectedSkeleton: {},
      linkedSkelly: [],
      uniqueTableId: "",
      courier: {},
      selectedProd: {},
      showConfirmDelete: false,
      headers: [
        {
          text: "SKU",
          align: "start",
          value: "skuNumber",
        },
        {
          text: "Division",
          align: "start",
          value: "division",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Price",
          align: "start",
          value: "price",
        },
        {
          text: "Charged When",
          align: "start",
          value: "chargeFrequency",
        },
        {
          text: "Alert",
          align: "start",
          value: "alertGroup",
        },

        {
          text: 'Links',
          align: 'start',
          value: 'links'
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>