<template>
  <div>
    <base-card>
      <v-card-title>
        Add Site
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field label="Site Name" v-model="site.name">
          </v-text-field>
          <v-btn color="primary" class="mr-4" @click="addSite">
            Add Site
          </v-btn>
        </v-form>
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
export default {
    props: {
        companyId: {
            type: String,
            default: ""
        }
    },
    methods: {
        addSite(){
            this.$courier.Companies.withId(this.companyId).Sites.add(this.site).then(newSite => {
                this.$emit('added-site', newSite);

                this.site = { name: '' };
            })
        }
    },
    data: () => ({
        site: {
            name: ''
        }
    })
};
</script>

<style></style>
