<template>
  <div>
    <trev-cool-table
      :headers="headers"
      :courier="$courier.Companies.withId(companyId).Contacts"
      title="Contacts"
      :tableId="uniqueTableId"
      @add-clicked="$emit('add-contact')"
      :showAddLinkAsButton="true"
    >
      <template v-slot:item.tags="{ item }">
        <span v-for="tag in item.tags.filter(x => x.active)" :key="tag.id" class="mr-1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
               <v-chip
                v-if="tag.tag === 'VIP'"
                color="amber lighten-1"
                v-on="on"
                outlined
                v-bind="attrs"
              >
                <v-icon> mdi-crown-circle-outline </v-icon>
              </v-chip>
              <v-chip
                v-if="tag.tag === 'Approver'"
                color="success"
                v-on="on"
                outlined
                v-bind="attrs"
              >
                <v-icon> mdi-shield-check-outline </v-icon>
              </v-chip>
              <v-chip
                outlined
                v-else-if="tag.tag === 'Service News'"
                color="blue-grey lighten-3"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon> mdi-newspaper-variant-outline</v-icon>
              </v-chip>
              <v-chip
                outlined
                v-else-if="tag.tag === 'Billing'"
                color="purple accent-3"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon> mdi-cash-multiple</v-icon>
              </v-chip>
              <v-chip
                outlined
                v-else-if="tag.tag === 'Support User'"
                color="orange accent-3"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon> mdi-face-agent</v-icon>
              </v-chip>
            </template>
            <span>
              {{ tag.tag }}
            </span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.name="{ item }">
        {{
          (item.firstName ? item.firstName : "") +
          " " +
          (item.lastName ? item.lastName : "")
        }}
      </template>
      <template v-slot:item.emailAddress="{ item }">
        <v-btn
          text
          color="lighten-5 info"
          class="info--text"
          :href="'mailto:' + item.emailAddress"
          v-if="item.emailAddress"
        >
          <v-icon class="mr-2" v-if="item.emailAddress">
            mdi-email-outline
          </v-icon>
          {{ shortenEmail(item.emailAddress) }}
        </v-btn>
      </template>
      <template v-slot:item.mobileNumber="{ item }">
        <v-btn
          text
          color="lighten-5 info"
          class="info--text"
          :href="'tel:' + item.mobileNumber"
          v-if="item.mobileNumber"
        >
          <v-icon class="mr-2" v-if="item.mobileNumber"> mdi-cellphone </v-icon>
          {{ item.mobileNumber }}
        </v-btn>
      </template>
      <template v-slot:item.landLine="{ item }">
        <v-btn
          text
          color="lighten-5 info"
          class="info--text"
          :href="'tel:' + item.landLine"
          v-if="item.landLine"
        >
          <v-icon class="mr-2" v-if="item.landLine"> mdi-phone </v-icon>
          {{ item.landLine }}
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          class="mr-2"
          small
          :to="{ name: 'Contact View', params: { contactId: item.id } }"
        >
          <v-icon> mdi-magnify </v-icon>
          View
        </v-btn>
        <v-btn
          color="danger"
          class="white--text"
          small
          v-if="$store.getters.hasScope('Contact.Delete')"
          @click="
            showConfirmDelete = true;
            contactSelected = {
              contactId: item.id,
              contactName: item.firstName,
            };
          "
        >
          <v-icon> mdi-delete-outline </v-icon>
          Delete
        </v-btn>
      </template>
    </trev-cool-table>
    <v-dialog v-model="showConfirmDelete" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete {{ contactSelected.contactName }}?
        </v-card-title>
        <v-card-actions>
          <v-btn color="default" @click="showConfirmDelete = false">
            No, Keep {{ contactSelected.contactName }}
          </v-btn>

          <v-btn color="danger" class="white--text" @click="deleteContact">
            <v-icon> mdi-delete-outline </v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trevCoolTable from "../tables/trev-cool-table.vue";

export default {
  components: { trevCoolTable },
  computed: {
    uniqueTableId() {
      if (this.tableId) {
        return this.tableId + "-contacts-list";
      } else {
        return "sometable-contacts-list";
      }
    },
  },
  props: {
    tableId: {
      type: String,
      default: "",
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$eventBus.$on(this.tableId + "-refresh", (pagingObject) => {
      this.$eventBus.$emit(this.uniqueTableId + "-refresh", pagingObject);
    });
  },
  methods: {
    shortenEmail(email) {
      return email.substring(0, 20) + "...";
    },
    deleteContact() {
      this.$courier.Contacts.removeById(this.contactSelected.contactId).then(
        () => {
          this.$eventBus.$emit(this.uniqueTableId + "-refresh");
          this.showConfirmDelete = false;
          this.contactSelected = {};
        }
      );
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(this.tableId + "-refresh");
  },
  data: function () {
    return {
      showConfirmDelete: false,
      contactSelected: "",
      headers: [
        {
          text: "Tags",
          value: "tags",
        },
        {
          text: "Job Title",
          align: "start",
          value: "jobTitle",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          value: "emailAddress",
        },
        {
          text: "Mobile",
          align: "start",
          value: "mobileNumber",
        },
        {
          text: "Landline",
          align: "start",
          value: "landLine",
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>
