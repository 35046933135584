<template>
  <div>
    <div>
      <trev-contract-products-table v-if="$store.getters.hasScope('Contract.Read')" :companyId="companyId" :tableId="contractProductsTableId"
        @added-product="$eventBus.$emit(uniqueTableId + '-refresh')">
      </trev-contract-products-table>
    </div>
    <div class="mt-3">
      <trev-cool-table title="Products" :courier="courier" :headers="tableHeaders" :getDataOnDateChange="false"
        :tableId="uniqueTableId" tableKey="id" :showAddLinkAsButton="true && $store.getters.hasScope('Product.Create')" @add-clicked="$emit('add-product')"
        @loaded="getAssProds" :isExpandable="true" :isSingleExpand="true">
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <td v-if="hasChildProducts(item)" class="text-start">
            <v-tooltip top>
              <span>
                View Linked Products
              </span>
              <template #activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="expand(!isExpanded)" class="v-data-table__expand-icon"
                  :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container class="pt-0 pb-0">
              <v-row>
                <v-col class="pt-0">
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th>
                            SKU
                          </th>
                          <th>
                            Division
                          </th>
                          <th>
                            Name
                          </th>
                          <th>
                            Cost Price
                          </th>
                          <th>
                            Our Cost (Per)
                          </th>
                          <th>
                            Total Cost
                          </th>
                          <th>
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="linkedProduct in tableLinkedProducts.filter(x => x.parentProductId == item.id)"
                          :key="linkedProduct.id">
                          <td>
                            <v-chip label small>
                              SKU:{{ linkedProduct.skuNumber }}
                            </v-chip>
                          </td>
                          <td>
                            {{ linkedProduct.division }}
                          </td>
                          <td>
                            {{ linkedProduct.name }}
                          </td>
                          <td>
                            £{{ linkedProduct.costPrice }}
                          </td>
                          <td>
                            £{{ linkedProduct.price }}
                          </td>
                          <td>
                            £{{ linkedProduct.totalCost }}
                          </td>
                          <td>
                            <v-chip small>
                              {{ linkedProduct.quantity }}
                            </v-chip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
        <template v-slot:item.skuNumber="{ item }">
          <v-chip label> SKU:{{ item.skuNumber }} </v-chip>
        </template>
        <template v-slot:item.costPrice="{item}">
          <span class="success--text">
            £{{ item.costPrice }}
          </span>
        </template>
        <template v-slot:item.totalCost="{ item }">
          <span class="success--text"> £{{ item.totalCost }} </span>
        </template>
        <template v-slot:item.price="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <span class="success--text" v-on="on" v-bind="attrs">
                £{{ item.price }} 
                <span v-show="item.recommendedPrice != item.price">
                  *
                </span>
              </span>
            </template>
            <span>
              Recommended Price (MSRP) £{{ item.recommendedPrice }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:item.quantity="{ item }">
          <v-chip>
            {{ item.quantity }}
          </v-chip>
        </template>
        <template v-slot:item.linked="{ item }">
          <v-icon color="info" v-if="tableLinkedProducts.some(x => x.parentProductId == item.id)">
            mdi-check-circle-outline
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" small>
                Options
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="showLinks(item)" v-if="$store.getters.hasScope('Product.Modify')">
                <v-list-item-title class="info--text">
                  <v-icon class="mr-2 info--text">mdi-link-plus</v-icon>
                  Change Links
                  <v-progress-circular indeterminate v-if="isLoadingLinks">
                  </v-progress-circular>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="updateProductQuantity(item)" v-if="$store.getters.hasScope('Product.Modify')">
                <v-list-item-title class="info--text">
                  <v-icon class="mr-2 info--text"> mdi-counter </v-icon>
                  Change Quantity
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="editProduct(item)" v-if="$store.getters.hasScope('Product.Modify')">
                <v-list-item-title class="warning--text">
                  <v-icon class="mr-2 warning--text"> mdi-pencil </v-icon>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="removeProduct(item)" v-if="$store.getters.hasScope('Product.Delete')">
                <v-list-item-title class="red--text">
                  <v-icon class="mr-2 red--text"> mdi-delete </v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </trev-cool-table>
      <v-dialog v-model="changeQuantityDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Change Quantity of {{ selectedProd.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-h6"> Old Quantity: {{ oldQuantity }} </span>
                </v-col>
                <v-col>
                  <span class="text-h6">
                    Old Cost: {{ selectedProd.totalCost }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="New Quantity" v-model="newQuantity"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h4">New Cost
                    <span class="success--text text-center">£{{ newCost }}</span></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="
  changeQuantityDialog = false;
selectedProd = {};
oldQuantity = 0;
newQuantity = 0;
            ">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="changeQuantity">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <trev-confirm-delete-dialog maxWidth="600px" :show.sync="showDelete" :itemToDelete="prodToDelete.name"
        @dialog-delete-deleted="deleteProduct" @dialog-delete-cancelled="cancelDeleteProduct">
      </trev-confirm-delete-dialog>
      <v-dialog v-model="showLinkedProds" width="70vh">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>
              Linked Products
              <!-- <span v-show="linkedProds &&linkedProds.length > 0">
: Selected <span class="primary--text">{{linkedProds && linkedProds.length}}</span> Products
              </span> -->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="confirmUpdateLinkedProducts">
              <v-icon class="mr-2"> mdi-floppy </v-icon>
              Save Linked Products
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <trev-linked-products-manage :companyId="companyId" :productId="selectedProd.id"
              :defaultSelectedProducts.sync="linkedProds"
              v-if="selectedProd.id && companyId"></trev-linked-products-manage>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TrevConfirmDeleteDialog from "../base/dialog/trev-confirm-delete-dialog.vue";
import trevCoolTable from "../tables/trev-cool-table.vue";
import TrevContractProductsTable from "./trev-contract-products-table.vue";
import TrevLinkedProductsManage from "./trev-linked-products-manage.vue";

export default {
  components: {
    trevCoolTable,
    TrevConfirmDeleteDialog,
    TrevContractProductsTable,
    TrevLinkedProductsManage,
  },
  props: {
    tableId: {
      type: String,
      default: "",
    },
    companyId: {
      type: String,
      default: "",
    },
    contractProductsTableId: {
      type: String,
      default: "",
    },
  },
  computed: {
    tableHeaders() {
      return this.headers;
    },
    newCost() {
      if (this.selectedProd && this.selectedProd.priceRules) {
        var totalCost =
          this.$courier.Products.Functions.calculatePriceFromRules(
            this.newQuantity,
            this.selectedProd.price,
            this.selectedProd.priceRules
          );

        return totalCost;
      } else {
        return 0;
      }
    },
  },
  watch: {
    companyId: {
      handler(newValue, oldValue) {
        if (this.companyId) {
          this.courier = this.$courier.Companies.withId(
            this.companyId
          ).Products;
        }

        this.uniqueTableId = this.tableId + "-product-table";

        this.$eventBus.$on(this.tableId + "-refresh", () => {
          this.$eventBus.$emit(this.uniqueTableId + "-refresh");
        });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(this.tableId + "-refresh");
  },
  methods: {
    hasChildProducts(parentProduct) {
      if (this.tableLinkedProducts.some(x => x.parentProductId == parentProduct.id)) {
        return true;
      } else {
        return false;
      }
    },
    getParentProduct(prod) {
      console.log('hello')
      this.$courier.Products.getById(prod.id).then(x => {
        this.hoverParentProductName = x.name;
      });
    },
    getAssProdForProd(parentProd) {
      var fProds = this.tableLinkedProducts.filter(
        (x) => x.active && x.parentProductId === parentProd.id
      );

      if (fProds && fProds.length > 0) {
        return fProds;
      } else {
        return [];
      }
    },
    getAssProds(prods) {
      this.tableLinkedProducts = [];
      if (prods && prods.length > 0) {
        for (const prod of prods) {
          this.$courier.Products.withId(prod.id)
            .LinkedProducts.getData(1, 9999, "")
            .then((prodResp) => {
              if (prodResp && prodResp.payload.length > 0) {
                for (const lProd of prodResp.payload) {
                  this.tableLinkedProducts.push(lProd);
                }
              }
            });
        }
      }
    },
    confirmUpdateLinkedProducts() {
      this.updateLinkedProducts().then(() => {
        this.showLinkedProds = false;
      });
    },
    async updateLinkedProducts() {
      var deletePromises = [];
      //remove all the existing links - then readd the ones we need
      for (const initProdId of this.initialLinkedProds.map((x) => x.id)) {
        var dPromise = new Promise(async (resolve, reject) => {
          var prod = await this.$courier.Products.getById(initProdId);

          prod.parentProductId = null;

          await this.$courier.Products.updateById(prod.id, prod);

          resolve();
        });

        deletePromises.push(dPromise);
      }

      if (deletePromises && deletePromises.length > 0) {
        await Promise.allSettled(deletePromises);
      }

      var promises = [];
      for (const linkedProdId of this.linkedProds) {
        var promise = new Promise(async (resolve, reject) => {
          //get prod
          var prod = await this.$courier.Products.getById(linkedProdId);
          prod.parentProductId = this.selectedProd.id;
          //save changes

          await this.$courier.Products.updateById(prod.id, prod);
          resolve();
        });

        promises.push(promise);
      }

      if (promises && promises.length > 0) {
        var results = await Promise.allSettled(promises);

        //update the product table?
        this.$eventBus.$emit(this.uniqueTableId + "-refresh");
      }

      this.initialLinkedProds = [];
      this.linkedProds = [];
      this.showLinkedProds = false;
    },
    showLinks(item) {
      this.isLoadingLinks = true;
      this.$courier.Products.withId(item.id)
        .LinkedProducts.getData(1, 999, "")
        .then((data) => {
          this.isLoadingLinks = false;
          this.selectedProd = item;
          if (data && data.payload) {
            this.linkedProds = data.payload;
            this.initialLinkedProds = JSON.parse(JSON.stringify(data.payload));
          }
          this.showLinkedProds = true;
        });
    },
    editProduct(item) {
      this.$emit("edit-product", item);
    },
    cancelDeleteProduct() {
      this.showDelete = false;
      this.prodToDelete = {};
    },
    removeProduct(prodToRemove) {
      this.prodToDelete = prodToRemove;
      this.showDelete = true;
    },
    updateProductQuantity(item) {
      this.$courier.Products.withId(item.id)
        .PriceRules.getData(1, 999, "")
        .then((x) => {
          this.changeQuantityDialog = true;
          this.selectedProd = item;
          this.selectedProd.priceRules = x.payload;
          this.oldQuantity = this.selectedProd.quantity;
        });
    },
    deleteProduct() {
      this.$courier.Products.removeById(this.prodToDelete.id).then((x) => {
        this.showDelete = false;
        this.prodToDelete = {};
        this.$eventBus.$emit(this.uniqueTableId + "-refresh");
      });
    },
    changeQuantity() {
      this.selectedProd.quantity = this.newQuantity;
      this.changeQuantityDialog = false;
      this.selectedProd.totalCost = this.newCost;
      this.$courier.Products.updateById(
        this.selectedProd.id,
        this.selectedProd
      ).then((updatedProd) => {
        this.$eventBus.$emit(this.uniqueTableId + "-refresh");
      });
    },
  },
  data() {
    return {
      tableLinkedProducts: [],
      linkedProds: [],
      initialLinkedProds: [],
      showLinkedProds: false,
      isLoadingLinks: false,
      showDelete: false,
      prodToDelete: {},
      debounceCostId: "",
      oldQuantity: 0,
      newQuantity: 0,
      changeQuantityDialog: false,
      uniqueTableId: "",
      hoverParentProductName: '',
      courier: {},
      selectedProd: {},
      showConfirmDelete: false,
      headers: [
        {
          text: "SKU",
          align: "start",
          value: "skuNumber",
        },
        {
          text: "Division",
          align: "start",
          value: "division",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: 'Cost Price',
          align: 'start',
          value: 'costPrice'
        },
        {
          text: "Unit Cost",
          align: "start",
          value: "price",
        },
        {
          text: "Cost",
          align: "start",
          value: "totalCost",
        },
        {
          text: "Quantity",
          align: "start",
          value: "quantity",
        },
        {
          text: "Has Linked Products",
          align: "start",
          value: "linked",
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>

</style>
