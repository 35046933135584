<template>
  <v-row>
    <v-col>
      <v-list>
        <v-list-item v-for="(link, index) in xeroLinks" :key="index">
          {{ link.xeroCustomerName }} <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            :href="'https://go.xero.com/Contacts/View/' + link.xeroCustomerId"
            target="_blank"
          >
            <v-icon>mdi-open-in-new</v-icon>
            Open In Xero
          </v-btn>
          <v-btn color="danger" @click="showDelete(link)">
            <v-icon> mdi-delete </v-icon>
            Remove Link
          </v-btn>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <trev-xero-company-search
        v-model="selectedXeroCompany"
      ></trev-xero-company-search>
      <v-btn class="mr-2" @click="saveLink"> Save Xero Company Link </v-btn>
      <v-btn color="primary" @click="showCreateXeroCompany">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create In Xero
      </v-btn>
    </v-col>
    <trev-confirm-delete-dialog
      maxWidth="60vh"
      :itemToDelete="deletingLink.xeroCustomerName"
      :show="isDeleting"
      @dialog-delete-deleted="deleteLink"
      @dialog-delete-cancelled="cancelDeleteLink"
    >
    </trev-confirm-delete-dialog>
    <v-dialog
      v-model="isCreating"
      max-width="60vh"
      @click:outside="cancelCreate"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Create New Xero Company </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-2">
          <trev-xero-company-add
            v-model="newXeroCompany"
          ></trev-xero-company-add>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelCreate"> Cancel </v-btn>
          <v-btn color="primary" @click="createInXero">
            <v-icon class="mr-2"> mdi-floppy </v-icon>
            Create in Xero
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TrevConfirmDeleteDialog from "../../../../../components/base/dialog/trev-confirm-delete-dialog.vue";
import TrevXeroCompanyAdd from "../../../../../components/forms/trev-xero-company-add.vue";
import TrevXeroCompanySearch from "../../../../../components/forms/trev-xero-company-search.vue";
export default {
  components: {
    TrevXeroCompanySearch,
    TrevConfirmDeleteDialog,
    TrevXeroCompanyAdd,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getXeroLinks();
  },
  methods: {
    async createInXero() {
      var tenantId = this.newXeroCompany.tenantId;

      delete this.newXeroCompany.tenantId;
      try {
        var newContact = await this.$courier.XeroContactExternal.add(
          this.newXeroCompany,
          null,
          [{ key: "tenantId", value: tenantId }]
        );

        //make the contact link
        await this.$courier.Companies.withId(this.companyId).XeroLinks.add({
          xeroCustomerId: newContact.ContactID,
          xeroTenantId: tenantId,
          xeroCustomerName: newContact.Name,
        });

        this.getXeroLinks();

        this.cancelCreate();
        this.$emit("changed");
      } catch (exception) {
        console.log(exception);
      }
    },
    showCreateXeroCompany() {
      this.$courier.Companies.getById(this.companyId).then((comp) => {
        this.newXeroCompany.name = comp.name;
        this.newXeroCompany.landLine = comp.phoneNumber;
        this.isCreating = true;
      });
    },
    cancelCreate() {
      this.newXeroCompany = {};
      this.isCreating = false;
    },
    async deleteLink() {
      await this.$courier.XeroLinks.removeById(this.deletingLink.id);
      await this.getXeroLinks();
      this.$emit("changed");
      this.cancelDeleteLink();
    },
    cancelDeleteLink() {
      this.isDeleting = false;
      this.deletingLink = {};
    },
    showDelete(link) {
      this.deletingLink = link;
      this.isDeleting = true;
    },
    async saveLink() {
      var name = "";

      await this.$courier.Companies.withId(this.companyId).XeroLinks.add({
        xeroCustomerId: this.selectedXeroCompany.ContactID,
        xeroCustomerName: this.selectedXeroCompany.Name,
        xeroTenantId: this.selectedXeroCompany.tenantId,
      });
      await this.getXeroLinks();
      this.$emit("changed");
    },
    async getXeroLinks() {
      var xeroPayload = await this.$courier.Companies.withId(
        this.companyId
      ).XeroLinks.getData(1, 999, "");

      this.xeroLinks = xeroPayload.payload;
    },
  },
  data() {
    return {
      xeroLinks: [],
      selectedXeroCompany: {},
      deletingLink: {},
      isDeleting: false,
      isCreating: false,
      newXeroCompany: {
        firstName: "",
        lastName: "",
        name: "",
        emailAddress: "",
        mobileNumber: "",
        landLine: "",
      },
    };
  },
};
</script>

<style>
</style>