var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.hasScope('Ticket.Read'))?_c('v-row',[_c('v-col',{staticClass:"pb-0 mb-0"},[_c('v-card',{staticClass:"ma-0 mr-4"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v("Tickets")]),_c('h4',[_c('trev-number-ticker',{attrs:{"number":_vm.ticketCountThisMonth}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (_vm.getPercentageIncreaseTicketCountThisMonth.includes('-')
                    ? ' danger '
                    : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
                    (_vm.getPercentageIncreaseTicketCountThisMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.getPercentageIncreaseTicketCountThisMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'},[_vm._v(_vm._s(_vm.getPercentageIncreaseTicketCountThisMonth)+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v("This Month")])])])])],1)],1),_c('v-col',[_c('v-card',{staticClass:"ma-0 mr-4"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v("Tickets")]),_c('h4',[_c('trev-number-ticker',{attrs:{"number":_vm.ticketCountLastMonth}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (_vm.getPercentageIncreaseTicketCountLastMonth.includes('-')
                    ? ' danger '
                    : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
                    (_vm.getPercentageIncreaseTicketCountLastMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.getPercentageIncreaseTicketCountLastMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'},[_vm._v(_vm._s(_vm.getPercentageIncreaseTicketCountLastMonth)+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v("Last Month")])])])])],1)],1),_c('v-col',[_c('v-card',{staticClass:"ma-0 mr-4"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v("Minutes Used")]),_c('h4',[_c('trev-number-ticker',{attrs:{"number":_vm.timeUsedThisMonth}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (_vm.getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                    ? ' danger '
                    : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
                    (_vm.getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.getPercentageIncreaseMinutesUsedThisMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'},[_vm._v(_vm._s(_vm.getPercentageIncreaseMinutesUsedThisMonth)+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v("This Month")])])])])],1)],1),_c('v-col',[_c('v-card',{staticClass:"ma-0"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v("Minutes Used")]),_c('h4',[_c('trev-number-ticker',{attrs:{"number":_vm.timeUsedLastMonth}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (_vm.getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                    ? ' danger '
                    : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
                    (_vm.getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.getPercentageIncreaseMinutesUsedLastMonth.includes('-')
                    ? 'red--text'
                    : 'success--text'},[_vm._v(_vm._s(_vm.getPercentageIncreaseMinutesUsedLastMonth)+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v("Last Month")])])])])],1)],1),_vm._l((_vm.productTimesThisMonth),function(productUsage,index){return _c('v-col',{key:index},[_c('v-card',{staticClass:"ma-0 ml-4"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v(" Minutes from "+_vm._s(productUsage.productSku)+" ")]),_c('h4',[_c('trev-number-ticker',{staticClass:"mr-1",attrs:{"number":productUsage.timeUsedInProduct}}),(productUsage.productTimeTotal < 2147483647)?_c('span',[_vm._v(" / "),_c('trev-number-ticker',{staticClass:"ml-1",attrs:{"number":productUsage.productTimeTotal}})],1):_c('span',[_vm._v(" / "),_c('v-icon',[_vm._v(" mdi-infinity ")])],1)],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
                  (_vm.getProductTimePercentageIncreaseThisMonth(
                    productUsage.productSku
                  ).includes('-')
                    ? ' danger '
                    : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
                    (_vm.getProductTimePercentageIncreaseThisMonth(
                      productUsage.productSku
                    ).includes('-')
                      ? ' rotate90 red--text '
                      : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.getProductTimePercentageIncreaseThisMonth(
                    productUsage.productSku
                  ).includes('-')
                    ? 'red--text'
                    : 'success--text'},[_vm._v(_vm._s(_vm.getProductTimePercentageIncreaseThisMonth( productUsage.productSku ))+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v("This Month")])])])])],1)],1)})],2):_vm._e(),(_vm.ninjaLinks.length > 0 && _vm.$store.getters.hasScope('Ninja.Read'))?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('trev-company-ninja-devices-dash',{attrs:{"companyId":_vm.$route.params.companyId}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(_vm.$store.getters.hasScope('Contact.Read'))?_c('trev-cool-list',{attrs:{"items":_vm.contacts,"pageSize":10,"hideTitle":true,"isPageBtnSmall":true,"isDense":true},scopedSlots:_vm._u([{key:"repeaterItem",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.showContactDetails(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" "+_vm._s(item.firstName + ' ' + item.lastName)+" ")],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isVIP),expression:"item.isVIP"}],staticClass:"my-0 mr-2",attrs:{"small":"","color":"amber lighten-1"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-crown-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" VIP ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isApprover),expression:"item.isApprover"}],staticClass:"ma-0 mr-2",attrs:{"small":"","color":"green accent-3"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-shield-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Approver ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isServiceNews),expression:"item.isServiceNews"}],staticClass:"ma-0 mr-2",attrs:{"small":"","color":"blue-grey lighten-3"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-newspaper-variant-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Service News ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isBilling),expression:"item.isBilling"}],staticClass:"ma-0 mr-2",attrs:{"small":"","color":"purple accent-3"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cash-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(" Billing ")])])],1)],1)]}}],null,false,3118521357)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.$store.getters.hasScope('Job.Read') && _vm.openJobCount > 0)?_c('trev-cool-table',{staticClass:"mb-4",attrs:{"hideTitle":true,"classes":'pt-0 pr-0 pl-0 pb-0',"cardTextClasses":'pt-0 pb-0 pr-0 pl-0',"courier":_vm.$courier.Companies.withId(_vm.companyId).Jobs,"additionalQueryStrings":[{ key: 'statusFilter', value: 'Open' }],"isDense":true,"headers":[
            { text: 'Po Number', value: 'poNumber' },
            { text: 'Title', value: 'title' },
            { text: 'Price', value: 'price' },
            { text: 'View', value: 'view' }
          ]},scopedSlots:_vm._u([{key:"item.poNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" #"+_vm._s(item.poNumber)+" ")]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" £"+_vm._s(item.price)+" ")]}},{key:"item.view",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","to":{
              name: 'Job View',
              params: {
                jobId: item.id
              }
            }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-magnify")]),_vm._v(" View ")],1)]}}],null,false,2096753508)}):_vm._e(),(_vm.$store.getters.hasScope('Product.Read'))?_c('trev-cool-table-base',{attrs:{"isDense":true,"classes":"mb-4","cardTextClasses":'pt-0 pr-0 pl-0 pb-0',"items":_vm.getProductsPaginated,"defaultPageSize":_vm.productPagination.pageSize,"isLoading":_vm.isLoadingProducts,"totalRecordCount":_vm.products.length,"headers":[
            {
              text: 'Sku',
              value: 'skuNumber',
            },
            {
              text: 'Name',
              value: 'name',
            },
            {
              text: 'Quantity',
              value: 'quantity',
            } ],"hideTitle":true},on:{"pagination-updated":_vm.productPaginationUpdated},scopedSlots:_vm._u([{key:"item.skuNumber",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.skuNumber)+" ")])]}},{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" "),(item.contractQuantity > 0)?_c('small',{staticClass:"grey--text lightne-2"},[_vm._v(" + "+_vm._s(item.contractQuantity)+" (In Contracts) ")]):_vm._e()]}}],null,false,4088301648)}):_vm._e(),(_vm.$store.getters.hasScope('Ticket.Read'))?_c('trev-cool-table',{ref:"ticketTable",attrs:{"courier":_vm.$courier.Companies.withId(_vm.$route.params.companyId).ZohoTickets,"isDense":true,"additionalQueryStrings":[{ key: 'status', value: 'Open,On Hold' }],"dateStart":_vm.startDate.toISOString(),"cardTextClasses":'pt-0 pr-0 pl-0 pb-0',"dateEnd":_vm.endDate.toISOString(),"headers":[
            { text: '#', value: 'ticketNumber' },
            { text: 'Status', value: 'status' },
            { text: 'Added At', value: 'dateAdded' },
            { text: 'Subject', value: 'subject' },
            { text: '', value: 'actions' } ],"defaultPageSize":10,"hideTitle":true},scopedSlots:_vm._u([{key:"item.dateAdded",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateAdded).toLocaleString())+" ")]}},{key:"item.subject",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":item.ticketLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}}],null,false,3929522183)}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"60vh"},model:{value:(_vm.isShowingContact),callback:function ($$v) {_vm.isShowingContact=$$v},expression:"isShowingContact"}},[_c('trev-contact-view-card',{attrs:{"contact":_vm.contactShowing}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }