var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trev-cool-table-base',{attrs:{"title":"Contractual Products","headers":_vm.headers,"items":_vm.computedData,"totalRecordCount":_vm.data.length,"isLoading":_vm.isLoading},on:{"search-updated":_vm.searchUpdated,"pagination-updated":_vm.paginationUpdated,"get-data":function($event){return _vm.getData()}},scopedSlots:_vm._u([{key:"item.contractName",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.contract.name)+" ")])]}},{key:"item.division",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.division)+" ")]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.contract.effectiveDate).getFullYear() != 1 ? new Date(item.contract.effectiveDate).toLocaleDateString("en-GB") : "")+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.contract.expiryDate).getFullYear() != 1 ? new Date(item.contract.expiryDate).toLocaleDateString("en-GB") : "")+" ")]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.productQuantity",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.product.quantity)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.hasScope('Contract.Modify'))?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.duplicateContractProductToNon(item)}}},[_vm._v(" Change Quantity "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-counter ")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","small":"","to":{
        name: 'Contract View',
        params: {
          contractId: item.contract.id
        }
      }}},[_vm._v(" View Contract "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-magnify ")])],1)]}}])}),_c('v-dialog',{attrs:{"width":"50vh"},model:{value:(_vm.showChangeQuantity),callback:function ($$v) {_vm.showChangeQuantity=$$v},expression:"showChangeQuantity"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(" Change Quantities of "+_vm._s(_vm.selectedProd.name)+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":'Change Quantity (' +
                  _vm.selectedProdOriginalQuantity +
                  ' in contract)'},on:{"change":_vm.keepMin},model:{value:(_vm.selectedProd.quantity),callback:function ($$v) {_vm.$set(_vm.selectedProd, "quantity", $$v)},expression:"selectedProd.quantity"}}),_c('v-text-field',{attrs:{"label":"Cost"},model:{value:(_vm.selectedProd.totalCost),callback:function ($$v) {_vm.$set(_vm.selectedProd, "totalCost", $$v)},expression:"selectedProd.totalCost"}})],1)],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v("Suggested Cost "),_c('span',{staticClass:"success--text text-center"},[_vm._v("£"+_vm._s(_vm.totalCost))])])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showChangeQuantity = false;
            _vm.selectedProd = {};}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveQuantityChanges}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }