<template>
  <v-autocomplete
    :search-input.sync="zohoDeskSearch"
    :loading="zohoDeskIsLoading"
    v-model="selectedZohoDeskCompany"
    hide-details
    :items="allFoundZohoDeskCompanies"
    hide-no-data
    item-text="accountName"
    item-value="id"
    placeholder="Search Zoho Desk"
    prepend-icon="mdi-database-search"
    label="Zoho Desk Account"
    return-object
    class="mb-4"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },    
  },
  watch: {
    selectedZohoDeskCompany() {
      this.$emit("input", this.selectedZohoDeskCompany);
    },
    zohoDeskSearch() {
      if (this.zohoDeskIsLoading) {          
        return;
      } else {
        this.searchForZohoDeskCompanies();
      }
    },
  },
  methods: {
    searchForZohoDeskCompanies() {
      this.zohoDeskIsLoading = true;

      this.$courier.Zoho.CompaniesDesk.getData(1, 20, this.zohoDeskSearch).then(
        (x) => {
          this.allFoundZohoDeskCompanies = x;
          this.zohoDeskIsLoading = false;
        }
      );
    },
  },
  data: function() {
      return {
        zohoDeskSearch: '',
        zohoDeskIsLoading: false,
        selectedZohoDeskCompany: {},
        allFoundZohoDeskCompanies: [],
      };
    },
};
</script>

<style>
</style>