<template>
  <v-card class="mt-4">
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon> mdi-account-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="contact.jobTitle">{{
              contact.jobTitle + " - "
            }}</span>
            {{
              (contact.firstName ? contact.firstName : "") +
              " " +
              (contact.lastName ? contact.lastName : "")
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="contact.mobileNumber">
        <v-list-item-icon>
          <v-icon> mdi-cellphone </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn :href="'tel:' + contact.mobileNumber">
              {{ contact.mobileNumber }}
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="contact.landLine">
        <v-list-item-icon>
          <v-icon> mdi-phone </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn :href="'tel:' + contact.landLine">
              {{ contact.landLine }}
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="contact.emailAddress">
        <v-list-item-icon>
          <v-icon> mdi-email </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn :href="'mailto:' + contact.emailAddress">
              {{ contact.emailAddress }}
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="contact.emailAddress">
        <v-list-item-icon>
          <v-icon> mdi-monitor-dashboard </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn :to="{ name: 'Contact View', params: {contactId: contact.id} }">
              <v-icon class="mr-2">
                mdi-magnify
              </v-icon>
              View Contact
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
    props: {
        contact: {
            type: Object,
            default: () => {}
        },
    }
    
};
</script>

<style>
</style>