<template>
  <v-form>
    <v-select
      multiple
      chips
      :disabled="isDisabled"
      label="Approvers"
      clearable
      :items="contactsForBox"
      item-value="value"
      item-text="text"
      return-object
      v-model="selectedContacts"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click:close="remove(item.value)"
        >
          <strong>{{ item.text }}</strong>
        </v-chip>
      </template>
    </v-select>
    <v-text-field
      :disabled="isDisabled"
      label="Title"
      v-model="approval.title"
    ></v-text-field>
    <trev-editor
      :isDisabled="isDisabled"
      :isFlat="false"
      class="mt-2 mb-4"
      :height="'25vh'"
      v-model="approval.content"
    ></trev-editor>
    <p class="text--grey content text-right">{{approval.content.length}} / 32000</p>
    <v-btn color="primary" class="mr-2" @click="saveApproval" v-if="showSave">
      <v-icon class="mr-2"> mdi-floppy </v-icon>
      {{ approvalId ? "Update" : "Send" }} Approval
    </v-btn>
    <v-btn text @click="cancelApproval" v-if="showCancel"> Cancel </v-btn>
  </v-form>
</template>

<script>
import TrevEditor from "../ui/trev-editor.vue";
export default {
  components: {
    TrevEditor,
  },
  props: {
    clearOnSave: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: "",
    },
    approvalId: {
      type: String,
      default: "",
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    approvalId() {
      if (this.approvalId) {
        this.loadEdit();
      }
    },
  },
  created() {
    this.getContacts().then(() => {
      if (this.approvalId) {
        //is in edit mode
        this.loadEdit();
      }
    });
  },
  methods: {
    loadEdit() {
      this.$courier.Approvals.getById(this.approvalId).then((resp) => {
        this.approval = resp;
        console.log(resp);
      });

      //get the contacts

      this.$courier.Approvals.withId(this.approvalId)
        .Contacts.getData(1, 900, "")
        .then((resp) => {
          var contacts = resp.payload;

          for (const contact of contacts) {
            //get the contact from internal list - them map

            var cToAdd = this.contactsForBox.find((x) => x.value == contact.id);

            if (cToAdd) {
              this.selectedContacts.push(cToAdd);
            }
          }
        });
    },
    cancelApproval(emit = true) {
      this.approval = {
        title: "",
        content: "",
        parentCompanyId: "",
      };
      this.selectedContacts = [];

      if (emit) {
        this.$emit("cancelled");
      }
    },
    async saveApproval() {
      var app = {};
      if (this.approvalId) {
        app = await this.updateApproval();
      } else {
        app = await this.addApproval();
      }

      if (this.clearOnSave) {
        this.approval = { title: "", content: "", parentCompanyId: "" };
        this.selectedContacts = [];
      }

      this.$emit("updated", app);
    },
    async updateApproval() {
      //remove the contacts from the approval

      var cResponse = await this.$courier.Approvals.withId(
        this.approvalId
      ).Contacts.getData(1, 999, "");
      var existingContacts = cResponse.payload;

      for (const eContact of existingContacts) {
        await this.$courier.Approvals.withId(
          this.approvalId
        ).Contacts.removeById(eContact.id);
      }

      //save the base approval

      var app = await this.$courier.Approvals.updateById(
        this.approvalId,
        this.approval
      );
      app.approvers = [];
      //now add the contacts back on

      for (const selectedContact of this.selectedContacts) {
        var contactObject = this.contacts.find(
          (x) => x.id == selectedContact.value
        );

        if (contactObject) {
          app.approvers.push(contactObject);
          await this.$courier.Approvals.withId(this.approvalId).Contacts.add(
            contactObject
          );
        }
      }

      await this.$courier.Approvals.SendEmails(app.id);

      return app;
    },
    async addApproval() {
      this.approval.parentCompanyId = this.companyId;

      var approval = await this.$courier.Approvals.add(this.approval);
      approval.approvers = [];
      var allContactPromises = [];

      for (const contactId of this.selectedContacts.map((x) => x.value)) {
        //get the contact
        var contact = this.contacts.find((x) => x.id == contactId);
        if (contact) {
          approval.approvers.push(contact);
          var f = this.$courier.Approvals.withId(approval.id).Contacts.add(
            contact
          );

          allContactPromises.push(f);
        }
      }

      await Promise.all(allContactPromises);

      //now send the emails

      await this.$courier.Approvals.SendEmails(approval.id);

      return approval;
    },
    remove(itemId) {
      let idx = this.selectedContacts.map((x) => x.value).indexOf(itemId);
      this.selectedContacts.splice(idx, 1);
      this.selectedContacts = [...this.selectedContacts];
    },
    async getContacts() {
      var contactPayload = await this.$courier.Companies.withId(
        this.companyId
      ).Contacts.getData(1, 900, "");

      if (contactPayload && contactPayload.payload) {
        this.contacts = contactPayload.payload.filter(
          (x) => x.tags != null && x.tags.length > 0
        );

        this.contactsForBox = this.contacts.filter(x => x.tags.some(s => s.active && (s.tag === 'Approver' || s.tag === 'VIP' || s.tag === 'Billing' || s.tag === 'Service News'))).map((x) => ({
          text:
            x.firstName +
            " " +
            x.lastName,
          value: x.id,
        }));
      }
    },
  },
  data() {
    return {
      selectedContacts: [],
      contactsForBox: [],
      contacts: [],
      approval: {
        title: "",
        content: "",
        parentCompanyId: "",
      },
    };
  },
};
</script>

<style>
</style>