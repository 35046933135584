<template>
  <div>
    <div v-if="isUpdatingHUdu">
      <trev-hudu-company-search v-model="foundHuduCompany">
      </trev-hudu-company-search>

      <v-btn
        type="success"
        @click="saveHuduLink"
        :disabled="Object.keys(foundHuduCompany).length === 0"
      >
        <v-icon class="mr-2"> mdi-save </v-icon>
        Save Hudu Link
      </v-btn>
       <v-btn color="info" class="ml-2" @click="showAddInHudu = true">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Create in Hudu
      </v-btn>
      <v-btn
        @click="changeHudu = false"
        color="danger"
        text
        class="ml-2"
        v-if="company.zohoCRMId"
      >
        <v-icon class="mr-2"> mdi-close </v-icon>
        Cancel
      </v-btn>
    </div>
    <div v-else>
      <h4>
        {{ company.name }}
      </h4>
      <v-btn @click="changeHudu = true" color="warning" small>
        <v-icon class="mr-2">mdi-pencil</v-icon>Change
      </v-btn>
    </div>
    <v-dialog v-model="showAddInHudu" max-width="400px">
        <v-card>
          <v-card-title>
            <v-icon class="mr-2"> mdi-asterisk </v-icon>
            New Company In Hudu
          </v-card-title>
          <v-card-text>
            <v-text-field label="Name" v-model="newHuduName"> </v-text-field>
            <v-btn color="info" @click="createCompanyInHudu">
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Create Company
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import trevHuduCompanySearch from "../../../../../components/forms/trev-hudu-company-search.vue";
export default {
  components: { trevHuduCompanySearch },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUpdatingHUdu() {
      return this.changeHudu || !this.company.huduId;
    },
  },
  methods: {
    createCompanyInHudu(){
      this.$courier.Hudu.add({
        name: this.newHuduName,
        nickName: this.newHuduName
      }).then(newHuduCompany => {
        this.foundHuduCompany = newHuduCompany;
        this.saveHuduLink();
      });
    },
    async saveHuduLink() {
      this.company.huduId = this.foundHuduCompany.id;
      this.company.huduSlug = this.foundHuduCompany.slug;
      this.company.huduUrl = this.foundHuduCompany.full_url;
      this.company.huduPasswordUrl = this.foundHuduCompany.passwords_url;
      this.company.huduKbUrl = this.foundHuduCompany.knowledge_base_url;

      this.$courier.Companies.updateById(this.company.id, this.company).then(
        () => {
          this.iShowHuduLinkDialog = false;          
          this.foundHuduCompany = {};

          this.changeHudu = false;
          this.showAddInHudu = false;
          //do nothing?

          //show success?
          this.$emit("update-company", this.company);
        }
      );
    },
  },
  data() {
    return {
      changeHudu: false,
      foundHuduCompany: {},
      showAddInHudu: false,
      newHuduName: this.company.name
    };
  },
};
</script>

<style>
</style>