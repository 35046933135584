<template>
  <div>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Select Product
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Customise Product
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Finalise </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <trev-product-selection
            :companyId="companyId"
            @selected-product="chooseProductSkeleton"
          ></trev-product-selection>
        </v-stepper-content>

        <v-stepper-content step="2">
          <trev-product-customise
          v-if="chosenProductSkeleton.id"
            :productSkeleton="chosenProductSkeleton"
            @products-are-equal="chosenProductIsEqualToNewOne = true"
            @products-are-not-equal="chosenProductIsEqualToNewOne = false"
            @product-updated="customisedProduct"
          ></trev-product-customise>
          <v-btn color="primary" @click="e1++" class="float-right">
            <span v-if="chosenProductIsEqualToNewOne">
              Continue without making changes
            </span>
            <span v-else> Continue with new changes </span>
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="e1--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col>
              <h4>
                Are you sure you want to
                <span class="primary--text">add</span> this product to
                <span class="primary--text">{{ companyName }}</span
                >?
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <base-card>
                <trev-product-skeleton-view-card
                  title="Product"
                  :productSkeleton="customisedProductSkeleton"
                  textColor="success"
                >
                </trev-product-skeleton-view-card
              ></base-card>
            </v-col>
            <v-col cols="9">
              <base-card>
                <v-card-title>
                  Quantity
                  <span class="success--text ml-4">{{ quantity }}</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field type="number" v-model="quantity"></v-text-field>
                </v-card-text>
              </base-card>
              <base-card class="mt-4">
                <v-card-title> Other </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-dialog
                        ref="dialogExpiry"
                        v-model="expiryDateModal"
                        :return-value.sync="expiryDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="expiryDate"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="expiryDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="expiryDateModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogExpiry.save(expiryDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                      <v-dialog
                        ref="dialogPurchase"
                        v-model="purchaseDateModal"
                        :return-value.sync="purchaseDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="purchaseDate"
                            label="Purchase Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="purchaseDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="purchaseDateModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogPurchase.save(purchaseDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-card>
              <base-card class="mt-4">
                <v-card-title>
                  Suggested Cost
                  <span class="success--text ml-4">£{{ totalCost }}</span>
                </v-card-title>
              </base-card>
              <!-- <trev-product-skeleton-price-test-card :productSkeleton="customisedProductSkeleton"></trev-product-skeleton-price-test-card> -->
            </v-col>
          </v-row>
          <v-btn color="primary" class="float-right" @click="addProduct">
            Add Product
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="e1--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import TrevProductSkeletonPriceTestCard from "./productskeletons/trev-product-skeleton-price-test-card.vue";
import TrevProductSkeletonViewCard from "./productskeletons/trev-product-skeleton-view-card.vue";
import TrevProductCustomise from "./trev-product-customise.vue";
import trevProductSelection from "./trev-product-selection.vue";
export default {
  components: {
    trevProductSelection,
    TrevProductCustomise,
    TrevProductSkeletonViewCard,
    TrevProductSkeletonPriceTestCard,
  },
  watch: {
    purchaseDate: {
      immediate: true,
      handler() {
        var d = new Date(this.purchaseDate);

        this.expiryDate = new Date(
          d.getFullYear() + 1,
          d.getMonth(),
          d.getDate()
        )
          .toISOString()
          .split("T")[0];
      },
    },
  },
  props: {
    companyName: {
      type: String,
      default: "Hogwarts",
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  computed: {
    totalCost() {
      if (
        this.customisedProductSkeleton &&
        this.customisedProductSkeleton.priceRules
      ) {
        var totalCost =
          this.$courier.Products.Functions.calculatePriceFromRules(
            this.quantity,
            this.customisedProductSkeleton.price,
            this.customisedProductSkeleton.priceRules
          );
        return totalCost;
      } else {
        return 0;
      }
    },
  },
  methods: {
    async addProduct() {
      //create the object
      var productToAdd = this.convertProductSkeletonToProduct(
        this.customisedProductSkeleton
      );

      var newProduct = await this.$courier.Companies.withId(
        this.companyId
      ).Products.add(productToAdd);

      //add the product links

      //get the child product skeleton links
      var productSkeletonChildren = await this.$courier.ProductSkeletons.withId(
        this.chosenProductSkeleton.id
      ).LinkedProductSkeletons.getData(1, 9999, "");

      //get all the meta data and price rules for the child product skeletons

      var allPromises = [];

      //generate one of each of these children and attach to the parent product - set the quantity as the same for all

      for (const childProductSkeleton of productSkeletonChildren.payload) {
        var innerGetPriceRulesPromise = new Promise(async (resolve, reject) => {
          var priceRules = await this.$courier.ProductSkeletons.withId(
            childProductSkeleton.id
          ).PriceRules.getData(1, 9999, "");

          if (
            priceRules &&
            priceRules.payload &&
            priceRules.payload.length > 0
          ) {
            childProductSkeleton.priceRules = priceRules.payload;
          }

          resolve();
        });

        var innerGetMetaDataPromise = new Promise(async (resolve, reject) => {
          var metaData = await this.$courier.ProductSkeletons.withId(
            childProductSkeleton.id
          ).MetaData.getData(1, 9999, "");

          if (metaData && metaData.payload && metaData.payload.length > 0) {
            childProductSkeleton.metaData = metaData.payload;
          }

          resolve();
        });

        allPromises.push(innerGetPriceRulesPromise);
        allPromises.push(innerGetMetaDataPromise);
      }

      await Promise.allSettled(allPromises);

      //strip these new product skeleton objects into products and add them in with links to the main product

      var allAddingPromises = [];

      for(const inputSkeleton of productSkeletonChildren.payload){
        var cleanSkeleton = JSON.parse(JSON.stringify(inputSkeleton));

        var convertedProduct = this.convertProductSkeletonToProduct(cleanSkeleton);

        convertedProduct.quantity = this.quantity;
        convertedProduct.parentProductId = newProduct.id;

        //now upload this object

        var addPromise = this.$courier.Products.add(convertedProduct);
        allAddingPromises.push(addPromise);
      }

      await Promise.allSettled(allAddingPromises);

      this.e1 = 1;
      this.purchaseDate = new Date().toISOString().split("T")[0];
      this.expiryDate = "";
      this.expiryDateModal = false;
      this.purchaseDateModal = false;
      this.quantity = 1;
      this.chosenProductSkeleton = {};
      this.customisedProductSkeleton = {};
      this.chosenProductIsEqualToNewOne = true;

      this.$emit("added-product", newProduct);
    },
    convertProductSkeletonToProduct(productSkeleton) {
      var productToAdd = {};

      productToAdd = JSON.parse(JSON.stringify(productSkeleton));

      delete productToAdd.id;

      if (productToAdd.metaData) {
        var arrayOfMetaData = [];
        productToAdd.metaData.forEach((metaData) => {
          arrayOfMetaData.push({
            value: metaData.value,
            active: true,
          });
        });

        delete productToAdd.metaData;

        productToAdd.metaData = arrayOfMetaData;
      }

      if (productToAdd.priceRules) {
        var arrayOfPriceRules = [];
        productToAdd.priceRules.forEach((priceRule) => {
          arrayOfPriceRules.push({
            sourceProperty: priceRule.sourceProperty,
            operator: priceRule.operator,
            targetVariableOrProperty: priceRule.targetVariableOrProperty,
            pricePerUnit: priceRule.pricePerUnit,
          });

          delete productToAdd.priceRules;

          productToAdd.priceRules = arrayOfPriceRules;
        });
      }

      productToAdd.expiryDate = this.expiryDate;
      productToAdd.purchaseDate = this.purchaseDate;
      productToAdd.quantity = this.quantity;
      productToAdd.totalCost = this.totalCost;
      productToAdd.parentCompanyId = this.companyId;

      if (productToAdd.priceRules && productToAdd.priceRules.length > 0) {
        productToAdd.priceRules.forEach((rule) => {
          delete rule.id;
        });
      }

      return productToAdd;
    },
    customisedProduct(newProductSkeleton) {
      this.customisedProductSkeleton = JSON.parse(
        JSON.stringify(newProductSkeleton)
      );
    },
    chooseProductSkeleton(product) {
      //get the price rules

      var productSkeletonPriceRulesPromise =
        this.$courier.ProductSkeletons.withId(product.id).PriceRules.getData(
          1,
          999,
          ""
        );

      var productSkeletonMetaDataPromise =
        this.$courier.ProductSkeletons.withId(product.id).MetaData.getData(
          1,
          999,
          ""
        );

      Promise.all([
        productSkeletonPriceRulesPromise,
        productSkeletonMetaDataPromise,
      ]).then((promiseData) => {
        var priceRulesResponse = promiseData[0];

        product.priceRules = priceRulesResponse.payload;

        var metaDataResponse = promiseData[1];
        product.metaData = metaDataResponse.payload;

        this.chosenProductSkeleton = product;
        this.e1++;
      });
    },
  },
  data: () => ({
    purchaseDate: new Date().toISOString().split("T")[0],
    purchaseDateModal: false,
    expiryDate: "",
    expiryDateModal: false,
    e1: 1,
    quantity: 1,
    chosenProductSkeleton: {},
    customisedProductSkeleton: {},
    chosenProductIsEqualToNewOne: true,
  }),
};
</script>

<style></style>
