<template>
  <v-form>
    <v-text-field
      label="Name"
      v-model="innerContractName"
      @keyup="$emit('update:contractName', innerContractName)"
    ></v-text-field>
    <v-text-field
      label="Invoice Number"
      v-model="innerInvoiceNumber"
      @keyup="$emit('update:invoiceNumber', innerInvoiceNumber)"
    >
    </v-text-field>
    <dialog-date-picker
      :uniqueId="'company-contract-basic-details-effective-date'"
      :dialogTitle="'Effective Date'"
      :date.sync="innerEffectiveDate"
    ></dialog-date-picker>
    <dialog-date-picker
      :uniqueId="'company-contract-basic-details-expiry-date'"
      :dialogTitle="'Expiry Date'"
      :date.sync="innerExpiryDate"
    ></dialog-date-picker>
  </v-form>
</template>

<script>
import DialogDatePicker from "../../base/dialogDatePicker.vue";
export default {
  components: {
    DialogDatePicker,
  },
  props: {
    contractName: {
      type: String,
      default: "",
    },
    expiryDate: {
      type: String,
      default: "",
    },
    effectiveDate: {
      type: String,
      default: "",
    },
    invoiceNumber: {
      type: String,
      default: "",
    },
  },
  watch: {
    contractName() {
      this.innerContractName = this.contractName;
    },
    expiryDate() {
      this.innerExpiryDate = this.expiryDate;
    },
    effectiveDate() {
      this.innerEffectiveDate = this.effectiveDate;
    },
    invoiceNumber() {
      this.innerInvoiceNumber = this.invoiceNumber;
    },
    innerExpiryDate(newValue, oldValue) {
      this.$emit("update:expiryDate", this.innerExpiryDate);
    },
    innerEffectiveDate(newValue, oldValue) {
      this.$emit("update:effectiveDate", this.innerEffectiveDate);

      if (this.innerEffectiveDate) {
        var eDate = new Date(this.innerEffectiveDate);

        this.innerExpiryDate = new Date(
          eDate.getFullYear() + 1,
          eDate.getMonth(),
          eDate.getDate()
        )
          .toISOString()
          .split("T")[0];
      }
    },
  },
  data() {
    return {
      innerContractName: this.contractName,
      innerExpiryDate: this.expiryDate,
      innerEffectiveDate: this.effectiveDate,
      innerInvoiceNumber: this.invoiceNumber,
    };
  },
};
</script>

<style>
</style>