var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('trev-simple-table',{attrs:{"title":"Select a Product","isLoading":_vm.isLoading,"headers":_vm.getHeaders,"totalRecordCount":_vm.totalCount,"items":_vm.allProducts,"itemKey":"subId","hasLoaded":!_vm.isLoading,"pageSize":10,"showSelect":_vm.selectMultiple,"singleSelect":!_vm.selectMultiple,"defaultSelectedRows":_vm.defaultSelectedProducts},on:{"searching":_vm.searchProducts,"pagination-changed":_vm.paginationChanged,"item-selected":_vm.selectedItem},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'custom')?_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" Custom ")]):(item.type == 'Existing')?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" Existing ")]):_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" Global ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.price)+" ")]}},{key:"item.skuNumber",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.skuNumber)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.selectProduct(item)}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Select ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }